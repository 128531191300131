import {
  Avatar,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { MouseEvent, useContext } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Loading from '../../Components/Loading';
import Password from '../../Components/Password';
import { SnackBarContext } from '../../lib/SnackBarContext';
import yupValidation from '../../lib/yupValidation';
import { User } from '../../models/user';
import api, { restApi } from '../../services/api';
import { useAbility } from '../users';
import { useStyles } from './styles';
import {
  hasLowerCaseLetter,
  hasMinimumLength,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCaseLetter,
  isValidPassword,
} from './utils';

const passwordSchema = yup.object().shape({
  password: yup.string().required('Obrigatório'),
});

type DataValues = yup.InferType<typeof passwordSchema>;

const ChangePassword = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const ability = useAbility();
  const setSnackbar = useContext(SnackBarContext);

  const path = history.location.pathname;
  const currentUser = JSON.parse(localStorage.getItem('user')!);
  const stateUser = history.location?.state?.user;

  const user: User | undefined =
    path === '/alterar-senha' ? stateUser || currentUser : stateUser;

  if (user?.id && !ability) return <Loading />;

  const cannotUpdate =
    user?.id &&
    ability.cannot('update', {
      ...user,
      __type: 'rusers',
    });

  if (cannotUpdate) history.push('/home');

  const handleSubmit = async (data: DataValues) => {
    const params = new URLSearchParams(history.location.search);
    let response;
    if (user?.id) {
      response = await restApi.patch(`/rusers/${user.id}`, {
        password: data.password,
      });
    } else {
      response = await api.resetPassword({
        action: params.get('action'),
        password: data.password,
        token: params.get('token'),
      });
    }
    if (response.ok) {
      setSnackbar({
        open: true,
        state: {
          message: 'Senha redefinida com sucesso!',
        },
      });
      user?.id ? history.push(`/usuarios/${user.id}`) : history.push('/login');
    } else {
      return setSnackbar({
        open: true,
        state: {
          severity: 'warning',
          message: 'Token inválido ou expirado',
        },
      });
    }
  };

  const handleClickUser = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    history.push(`/usuarios/${user?.id}`);
  };

  return (
    <div className={classes.main}>
      <Paper elevation={4} className={classes.paper}>
        {user?.id && (
          <div className={classes.user} onClick={handleClickUser}>
            <Avatar
              className={classes.avatar}
              src={user.thumbnail ?? user.photoUrl}
            />
            <Typography variant="body2">{user.name}</Typography>
          </div>
        )}
        <Form
          onSubmit={handleSubmit}
          validate={yupValidation(passwordSchema)}
          subscription={{ submitting: true, pristine: true }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Password
                    name="password"
                    label="Nova senha"
                    fullWidth
                    autoFocus
                    variant="standard"
                  />
                </Grid>

                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography>Sua senha deve:</Typography>
                  </Grid>
                  <Field name="password" subscription={{ value: true }}>
                    {({ input: { value: password } }) => (
                      <Grid item container spacing={1} direction="column">
                        <Grid
                          item
                          container
                          style={{
                            color: hasMinimumLength(password)
                              ? theme.palette.primary.main
                              : theme.palette.text.disabled,
                          }}
                        >
                          <CheckCircleIcon style={{ scale: '0.7' }} />
                          <Typography variant="body1">
                            Ter no mínimo 8 caracteres
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          style={{
                            color: hasNumber(password)
                              ? theme.palette.primary.main
                              : theme.palette.text.disabled,
                          }}
                        >
                          <CheckCircleIcon style={{ scale: '0.7' }} />
                          <Typography variant="body1">
                            Ter no mínimo 1 número
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          style={{
                            color: hasUpperCaseLetter(password)
                              ? theme.palette.primary.main
                              : theme.palette.text.disabled,
                          }}
                        >
                          <CheckCircleIcon style={{ scale: '0.7' }} />
                          <Typography variant="body1">
                            Ter no mínimo 1 letra maiúscula
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          style={{
                            color: hasLowerCaseLetter(password)
                              ? theme.palette.primary.main
                              : theme.palette.text.disabled,
                          }}
                        >
                          <CheckCircleIcon style={{ scale: '0.7' }} />
                          <Typography variant="body1">
                            Ter no mínimo 1 letra minúscula
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          style={{
                            color: hasSpecialCharacter(password)
                              ? theme.palette.primary.main
                              : theme.palette.text.disabled,
                          }}
                        >
                          <CheckCircleIcon style={{ scale: '0.7' }} />
                          <Typography variant="body1">
                            Ter no mínimo 1 caractere especial (ex: !$%&?)
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Field>
                </Grid>

                <Grid item>
                  <Field name="password" subscription={{ value: true }}>
                    {({ input: { value: password } }) => (
                      <Button
                        fullWidth
                        disabled={!isValidPassword(password) || submitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Redefinir senha
                      </Button>
                    )}
                  </Field>
                </Grid>
                {history.length > 1 && (
                  <Grid item>
                    <Button
                      type="button"
                      fullWidth
                      onClick={() => history.goBack()}
                    >
                      Voltar
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Form>
      </Paper>
    </div>
  );
};

export default ChangePassword;
