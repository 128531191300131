import { getConfig } from './get-config';

const config = {
  useFixture: getConfig('useFixture'),
  baseURL: getConfig('baseURL'),
  uploadBaseURL: getConfig('uploadBaseURL'),
  socketURL: getConfig('socketURL'),
  integracaoZapURL: getConfig('integracaoZapURL'),
  integracaoOlxURL: getConfig('integracaoOlxURL'),
  integracaoNaventURL: getConfig('integracaoNaventURL'),
  integracaoNaventAccessToken: getConfig('integracaoNaventAccessToken'),
  integracoesAccessToken: getConfig('integracoesAccessToken'),
  integracaoFlipURL: getConfig('integracaoFlipURL'),
  integracaoCliqueiMudeiURL: getConfig('integracaoCliqueiMudeiURL'),
  integracaoCliqueiMudeiLeadsURL: getConfig('integracaoCliqueiMudeiLeadsURL'),
  integracoesApiURL: getConfig('integracoesApiURL'),
  whatsappSyncURL: getConfig('whatsappSyncURL'),
  chat2DeskURL: getConfig('chat2DeskURL'),
  mercadoLivreBaseAuthURL: getConfig('mercadoLivreBaseAuthURL'),
  googleTokenApi: getConfig('googleTokenApi'),
  facebookAppId: getConfig('facebookAppId'),
  firebaseApiKey: getConfig('firebaseApiKey'),
  firebaseAuthDomain: getConfig('firebaseAuthDomain'),
  firebaseProjectId: getConfig('firebaseProjectId'),
  firebaseAppId: getConfig('firebaseAppId'),
  firebaseMessagingSenderId: getConfig('firebaseMessagingSenderId'),
  firebasePublicVapidKey: getConfig('firebasePublicVapidKey'),
  refreshTokenIntervalMinutes: getConfig('refreshTokenIntervalMinutes'),
  salesMachineKey: getConfig('salesMachineKey'),
  apmActive: getConfig('apmActive'),
  apmServiceName: getConfig('apmServiceName'),
  apmServerUrl: getConfig('apmServerUrl'),
  apmDistributedTracingOrigins: getConfig('apmDistributedTracingOrigins').split(','),
  apmEnvironment: getConfig('apmEnvironment'),
  apmBreakdownMetrics: getConfig('apmBreakdownMetrics'),
};

export default config;
