import { TextField } from 'elentari';
import React from 'react';
import Field from './Field';
import { formatOnlyNumbers, phoneUnmask } from '../utils/utils';

const Contact = ({ initialContact = '', ...props }) => {
  const format = (value) => {
    if (phoneUnmask(value)?.length <= 10) {
      return formatOnlyNumbers(value, '(99) 9999-9999');
    } else {
      return formatOnlyNumbers(value, '(99) 99999-9999');
    }
  };
  return (
    <Field
      label='Contato'
      name='cellphone'
      fullWidth
      parse={(value) => format(value)}
      format={(value) => format(value)}
      placeholder='(__) ______-____'
      component={TextField}
      variant='outlined'
      initialValue={initialContact}
      inputProps={{ inputMode: 'tel' }}
      {...props}
    />
  );
};
export default Contact;
