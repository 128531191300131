import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
    color: '#A58DEB',
    background: '#EFECFC',
    borderRadius: '4px',
    fontWeight: 'bold',
    '[data-status="success"]': {
      color: '#66BFA5',
      background: '#D9F2E8',
    },
    '[data-status="error"]': {
      color: '#E56173',
      background: '#FDEDEE',
    },
    '[data-status="warning"]': {
      color: '#BFA554',
      background: '#FCEEC3',
    },
  },
}));
