import Section from '@elentari/components-eve/components/Section';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { partition } from 'ramda';
import React, { useEffect } from 'react';
import { OnChange } from 'react-final-form-listeners';
import * as yup from 'yup';
import makeField from '../makeField';

const hasDependecyValue = (field, values) =>
  Object.entries(field.depends).every(([key, value]) =>
    Array.isArray(value) ? value.includes(values[key]) : values[key] === value
  );

const areEquals = (previous, next) => {
  if (!next.fields) {
    return false;
  }
  const dependenciesFields = next.fields
    .filter((f) => f.depends)
    .reduce((acc, f) => ({ ...acc, ...f.depends }), {});
  if (previous.fields !== next.fields) {
    return false;
  }
  if (
    previous.values &&
    next.values &&
    Object.keys(dependenciesFields).some((key) => previous.values[key] !== next.values[key])
  ) {
    return false;
  }
  return true;
};

const useStyles = makeStyles((theme) => ({
  group: {
    paddingBottom: 16,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.grey[500],
  },
}));

const makeGrid = (field) => (
  <Grid key={field.name} item sm={6} lg={field.size + 1 || 6} xs={12}>
    {makeField(field)}
  </Grid>
);

const makeNewRowGrid = (field) => (
  <>
    <Grid item xs={12} />
    {makeGrid(field)}
  </>
);

const makeSimpleGrid = (field) => (
  <Grid key={field.name} item>
    {makeField(field)}
  </Grid>
);

const capitalize = (string = '') =>
  string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
const normalize = (string) => {
  if (string === 'undefined' || R.isEmpty(string)) {
    return 'Outros';
  }
  return string;
};

const GroupContainer = ({ fields, values }) => {
  const [exclusiveColumnsFields, fieldsRest] = partition((field) => field.exclusiveColumns, fields);

  return (
    <Grid container spacing={2}>
      {exclusiveColumnsFields.map((field) => makeSimpleGrid(field))}
      <Grid item xs={12} lg>
        <Grid container spacing={2}>
          {fieldsRest.map((field) => {
            if (!field.depends) {
              return field.startAtNewRow ? makeNewRowGrid(field) : makeGrid(field);
            }
            if (hasDependecyValue(field, values)) {
              return field.startAtNewRow ? makeNewRowGrid(field) : makeGrid(field);
            }
            return null;
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

const Group = ({ fields, values, group = '', isMobile }) => {
  const subgroups = R.groupBy(
    R.prop('subgroup'),
    fields.filter((field) => field.subgroup)
  );
  const hasSubgroup = !R.isEmpty(subgroups);
  const fieldsWithoutSubgroups = R.reject(R.has('subgroup'), fields);

  return (
    <Box py={2}>
      <Section label={capitalize(normalize(group))} disableGutters={isMobile}>
        <Grid container xs={12} direction='column' spacing={3}>
          <Grid item xs={12}>
            <GroupContainer
              fields={hasSubgroup ? fieldsWithoutSubgroups : fields}
              values={values}
            />
          </Grid>
          {hasSubgroup
            ? R.toPairs(subgroups).map(([subgroup, fields]) => {
                return (
                  <Grid item xs={12}>
                    <Section label={capitalize(normalize(subgroup))}>
                      <GroupContainer key={subgroup} fields={fields} values={values} />
                    </Section>
                  </Grid>
                );
              })
            : null}
        </Grid>
      </Section>
    </Box>
  );
};

const Details = React.memo(
  ({ fields = [], detailField = 'detalhes', empreendimento, isMobile }) => {
    const classes = useStyles();
    const [dependecyValues, setDependecyValues] = React.useState({});
    const [opacity, setOpacity] = React.useState(1);

    useEffect(() => {
      const fieldsWithDependecies = fields.filter((field) => !!field.depends);
      if (!R.isEmpty(fieldsWithDependecies)) {
        const dependencyFields = fieldsWithDependecies.map((field) => field.depends);
        const dependencyObj = R.mergeAll(dependencyFields);
        Object.keys(dependencyObj).forEach((d) => (dependencyObj[d] = null));
        setDependecyValues(dependencyObj);
      }
    }, [fields]);

    const marcaDaguaField = fields.find((field) => field?.name === 'marcaDagua');
    if (marcaDaguaField) {
      marcaDaguaField.opacity = opacity;
    }

    fields = fields.filter((field) => field.group !== 'Endereço');

    fields = fields.map((field) =>
      field.group === 'Lazer'
        ? {
            ...field,
            group: empreendimento
              ? 'Área de lazer do condomínio'
              : 'Área de lazer do imóvel (privativa)',
          }
        : field
    );

    const groups = R.groupBy(
      R.prop('group'),
      fields.map((f) => ({ ...f, name: `${detailField}.${f.name}` }))
    );

    return (
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={12}>
          {R.toPairs(groups).map(([group = 'outros', fields]) => (
            <Group
              key={group}
              classes={classes}
              fields={fields}
              values={dependecyValues}
              group={group}
              isMobile={isMobile}
            />
          ))}
        </Grid>
        {Object.keys(dependecyValues).map((dependecyValue) => (
          <OnChange name={`${detailField}.${dependecyValue}`}>
            {(value) => {
              setDependecyValues((prev) => ({
                ...prev,
                [dependecyValue]: value,
              }));
            }}
          </OnChange>
        ))}
        <OnChange name='configuration.opacidadeMarcaDagua'>
          {(value) => {
            setOpacity(Number(value));
          }}
        </OnChange>
      </Grid>
    );
  },
  areEquals
);

export const yupShapeTemplate = (template) => {
  const shape = {};
  if (template == null) {
    return shape;
  }

  template.forEach((property) => {
    if ([true, 'true'].includes(property.required) && property.group !== 'Endereço') {
      shape[property.name] = yupFieldType(property.type).required('Obrigatório');
    }
  });

  return shape;
};

export const yupFieldType = (type) => {
  switch (type) {
    case 'NUMBER':
    case 'INTEGER':
      return yup.number().nullable();
    default:
      return yup.string().nullable();
  }
};

export default Details;
