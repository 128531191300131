import React, { ReactNode, createContext, useContext, useState } from 'react';

interface FormData {
  [key: string]: any;
}

interface FormDataContextType {
  formData: FormData;
  updateFormFieldData: (fieldName: string, value: any) => void;
}

const FormDataContext = createContext<FormDataContextType | undefined>(
  undefined,
);

export const FormDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [formData, setFormData] = useState<FormData>({});

  const updateFormFieldData = (fieldName: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  return (
    <FormDataContext.Provider value={{ formData, updateFormFieldData }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => useContext(FormDataContext);
