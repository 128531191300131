import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useStyles } from './style';

type Props = FieldRenderProps<any, any>;

export const CheckboxInputFinalForm: React.FC<Props> = ({
  input,
  disablePropagation,
}: Props) => {
  const styles = useStyles();

  return (
    <label onClick={(event) => disablePropagation && event.stopPropagation()}>
      <input className={styles.input} type="checkbox" {...input} />
      <span className={styles.label}>
        <span />
      </span>
    </label>
  );
};
