import {
  CrudRoute,
  CrudTabs,
  useTabsNavigation,
} from '@elentari/components-eve/components/CrudTabs';
import { useEntityManager } from 'elentari';
import * as R from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import Loading from '../../Components/Loading';
import UserForm from './UserForm';
import useRoles from './useRoles';
import usersRepository from './usersRepository';
import { withTransaction } from '@elastic/apm-rum-react';

const uniqueUsernameValidation = (error) =>
  error.type === 'unique violation' && error.path === 'username';
const uniqueNameValidation = (error) => error.type === 'unique violation' && error.path === 'name';

const handleError = (response) => {
  if (R.any(uniqueUsernameValidation, response.data?.errors || [])) {
    return 'E-mail já cadastrado';
  }
  if (R.any(uniqueNameValidation, response.data?.errors || [])) {
    return 'Nome já cadastrado';
  }
  return response.data?.message;
};
const UserTabs = (props) => {
  const roles = useRoles();
  const entityManager = useEntityManager({
    repository: usersRepository,
    handleError,
    initialData: {
      createdAt: new Date(),
      entryDate: new Date(),
    },
  });
  const [tabsNavigation, setTabsNavigation] = useState([{ value: '', label: 'Usuário' }]);

  const getTabsNavigation = useCallback(() => {
    if (R.isNil(entityManager.item.roleId) || roles.length === 0) {
      return;
    }
    setTabsNavigation([{ value: '', label: 'Usuário' }]);
    return;
  }, [entityManager.item.roleId, roles]);

  useEffect(() => {
    getTabsNavigation();
  }, [getTabsNavigation]);

  const tabsHook = useTabsNavigation({
    mainPath: 'usuarios',
    tabs: tabsNavigation,
  });

  if (!entityManager.isNew && !entityManager.item.id) {
    return <Loading />;
  }

  return (
    <CrudTabs {...tabsHook}>
      <CrudRoute
        render={() => <UserForm {...props} roles={roles} entityManager={entityManager} />}
      />
    </CrudTabs>
  );
};

export default withTransaction('UserTabs', 'component')(UserTabs);
