export const pagoParaItems = [
  { value: 'IMOBILIARIA', label: 'Imobiliária' },
  { value: 'CONSULTOR', label: 'Consultor' },
];

export const classificacaoItems = [
  { value: 'CAPTADOR', label: 'Captador' },
  { value: 'CORRETOR', label: 'Corretor' },
  { value: 'DIRETOR', label: 'Diretor' },
  { value: 'EXCLUSIVIDADE', label: 'Exclusividade' },
  { value: 'INDICACAO', label: 'Indicação' },
  { value: 'GERENTE', label: 'Gerente' },
  { value: 'JURIDICO', label: 'Jurídico' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'PARCERIA', label: 'Parceria' },
  { value: 'SECRETARIA', label: 'Secretária' },
  { value: 'SUPERVISOR', label: 'Supervisor' },
];

export const consultorClassificacaoItems = [
  { value: 'CAPTADOR', label: 'Captador' },
  { value: 'CORRETOR', label: 'Corretor' },
  { value: 'DIRETOR', label: 'Diretor' },
  { value: 'INDICACAO', label: 'Indicação' },
  { value: 'GERENTE', label: 'Gerente' },
  { value: 'JURIDICO', label: 'Jurídico' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'PARCERIA', label: 'Parceria' },
  { value: 'SECRETARIA', label: 'Secretária' },
  { value: 'SUPERVISOR', label: 'Supervisor' },
];

export const imobiliariaClassificacaoItems = [
  { value: 'EXCLUSIVIDADE', label: 'Exclusividade' },
  { value: 'JURIDICO', label: 'Jurídico' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'PARCERIA', label: 'Parceria' },
];

export const fixadoPorItems = [
  { value: 'PORCENTAGEM', label: 'Porcentagem' },
  { value: 'VALOR', label: 'Valor' },
];

export const getTipos = (tipos) => {
  let result = '';
  if (tipos[0].match(/VERTICAL/)) {
    result = 'Vertical: ';
  } else if (tipos[0].match(/HORIZONTAL/)) {
    result = 'Horizontal: ';
  } else {
    result = 'Loteamento';
  }
  result += tipos
    .map((tipo) => {
      switch (tipo) {
        case 'HORIZONTAL_CASAS':
          return 'Casas';
        case 'HORIZONTAL_CHACARAS':
          return 'Chácaras';
        case 'HORIZONTAL_GALPOES':
          return 'Galpões';
        case 'HORIZONTAL_INDUSTRIAL':
          return 'Industrial';
        case 'HORIZONTAL_LOTES_RESIDENCIAL':
          return 'Lotes Residenciais';
        case 'HORIZONTAL_LOTES_COMERCIAL':
          return 'Lotes Comerciais';
        case 'HORIZONTAL_HOTEL':
          return 'Hotel';
        case 'VERTICAL_COMERCIAL':
          return 'Comercial';
        case 'VERTICAL_RESIDENCIAL':
          return 'Residencial';
        case 'LOTEAMENTO':
          return '';
        default:
          return 'Não definido';
      }
    })
    .join(', ');
  return result;
};

export const formatItems = (items, value, notFound = 'Não informado') =>
  items.find((item) => item.value === value)?.label ?? notFound;

export const CurrencyFormat = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const formatFaseConstrucao = (fase) => {
  switch (fase) {
    case 'PRONTO':
      return 'Pronto';
    case 'EM_CONSTRUCAO':
      return 'Em Construção';
    case 'NAO_INICIADO':
      return 'Não Iniciado';
    case 'VINTE_E_CINCO_PORCENTO':
      return '25%';
    case 'CINQUENTA_PORCENTO':
      return '50%';
    case 'SETENTA_E_CINCO_PORCENTO':
      return '75%';
    case 'LANCAMENTO':
      return 'Lançamento';
    case 'ULTIMAS_UNIDADES':
      return 'Últimas Unidades';
    case 'PRONTO_PARA_MORAR':
      return 'Pronto para morar';
    default:
      return 'Condomínio';
  }
};

export const PercentFormat = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
});

export const formatCurrency = (value) => {
  return value != null ? CurrencyFormat.format(value) : '-';
};

export const formatPercent = (value) => {
  return value != null ? PercentFormat.format(value / 100) : '-';
};

export const applyPercent = (percent, total) => {
  return +Number((total * percent) / 100).toFixed(2);
};

export const getPercent = (value, total, fractionDigits = 2) => {
  return +Number((value / total) * 100).toFixed(fractionDigits);
};

export const dateFormat = (date) => {
  return date ? new Date(date).toLocaleDateString('pt-BR') : '';
};

export const formatAddress = (address) => {
  return address.neighborhood ? `${address.neighborhood}/${address.city}` : address.city;
};
