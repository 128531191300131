import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const ConfirmModal = ({
  open,
  title,
  content,
  children,
  denyLabel,
  acceptLabel,
  onDeny,
  onAccept,
  onClose,
  submitting = false,
  extraAction,
  extraActionLabel,
  maxWidth,
  disableAccept = false,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth={maxWidth} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {extraAction && (
          <Button onClick={extraAction} color='primary'>
            {extraActionLabel}
          </Button>
        )}
        {onDeny && (
          <Button onClick={onDeny} color='secondary' variant='outlined' autoFocus>
            {denyLabel}
          </Button>
        )}
        <div className={classes.wrapper}>
          <Button
            onClick={onAccept}
            color='primary'
            variant='contained'
            autoFocus
            disabled={submitting || disableAccept}
          >
            {acceptLabel}
          </Button>
          {submitting && !disableAccept && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  open: false,
  title: 'Tem certeza que deseja continuar?',
  content: 'Clique em sim para confirmar',
  denyLabel: 'Não',
  acceptLabel: 'Sim',
  children: null,
  onDeny: () => {},
  onAccept: () => {},
  onClose: () => {},
  extraAction: null,
  extraActionLabel: null,
  maxWidth: null,
};

export default ConfirmModal;
