import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'stretch',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100%',
    borderRadius: '8px',
    background: '#fff',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '56px',
    height: '56px',
    color: '#fff',
    background: '#a58deb',
    borderRadius: '8px',

    '& + $content': {
      width: 'calc(100% - 30px)',
      marginLeft: '30px',
    },
  },
}));
