import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const MessageSnackbar = ({
  open,
  onClose,
  severity,
  message,
  action,
  duration = 5000,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={duration || null} onClose={onClose}>
      <Alert
        onClose={onClose}
        severity={severity}
        style={{ fontFamily: 'Roboto' }}
        action={action}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
export default MessageSnackbar;
