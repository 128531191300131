export const productionConfig = {
  useFixture: false,
  baseURL: process.env.REACT_APP_API_URL,
  socketURL: process.env.REACT_APP_SOCKET_URL,
  uploadBaseURL: process.env.REACT_APP_UPLOAD_API_URL,
  integracaoZapURL: process.env.REACT_APP_INTEGRACAO_ZAP_URL,
  integracaoOlxURL: process.env.REACT_APP_INTEGRACAO_OLX_URL,
  integracaoNaventURL: process.env.REACT_APP_INTEGRACAO_NAVENT_URL,
  integracaoNaventAccessToken: process.env.REACT_APP_INTEGRACAO_NAVENT_ACCESS_TOKEN,
  integracaoFlipURL: process.env.REACT_APP_INTEGRACAO_FLIP_URL,
  integracaoCliqueiMudeiURL: process.env.REACT_APP_INTEGRACAO_CLIQUEI_MUDEI_URL,
  integracaoCliqueiMudeiLeadsURL: process.env.REACT_APP_INTEGRACAO_CLIQUEI_MUDEI_LEADS_URL,
  integracoesApiURL: process.env.REACT_APP_INTEGRACAO_API_URL,
  integracoesAccessToken: process.env.REACT_APP_INTEGRACOES_ACCESS_TOKEN,
  whatsappSyncURL: process.env.REACT_APP_WHATSAPP_SYNC_URL,
};

export type ProductionConfig = typeof productionConfig;
