import { create } from 'apisauce';
import config from '../config';
import addFullReferer from './transforms/add-full-referer';
import { PeriodicalAccessTokenRefresher } from './transforms/refresh-access-token-periodically';
import memoize from 'lodash/memoize';

const fixtureApi = {
  setToken: (token) => token,
  refreshToken: () => ({ ok: true, data: { refreshToken: '1234', accessToken: '1234' } }),
  login: async ({ username }) => {
    if (username === 'admin@eurekalabs.com.br') {
      return { ok: true, data: { accessToken: 1234, user: { roles: ['SUPER_ADMIN'] } } };
    }
    return { ok: true, data: { accessToken: 1234, user: { roles: ['DIRETOR'] } } };
  },
  logout: async () => console.log({ ok: true }),
  recoverPassword: async () => ({ ok: true }),
  resetPassword: async () => ({ ok: true }),
};

export const restApi = create({
  baseURL: config.baseURL,
});

restApi.addRequestTransform(addFullReferer());

export const uploadRestApi = create({
  baseURL: config.uploadBaseURL,
});

uploadRestApi.addRequestTransform(addFullReferer());

const api = {
  setToken: (token) => {
    restApi.setHeader('Authorization', `Bearer ${token}`);
    uploadRestApi.setHeader('Authorization', `Bearer ${token}`);
  },
  refreshToken: memoize((refreshToken) =>
    restApi.post('/authentication', {
      strategy: 'jwt',
      refreshToken,
    })
  ),
  ping: () => restApi.get('/rusers?$limit=1'),
  login: (credentials) =>
    restApi.post('/authentication', {
      strategy: 'local',
      ...credentials,
    }),
  resetPassword: (data) => restApi.post('/account', data),
  setFcmToken: (token) => restApi.post(`users-tokens`, { token }),
  logout: () => {
    const tokenFcm = sessionStorage.tokenFcm;
    if (tokenFcm) {
      restApi.delete(`users-tokens/${tokenFcm}`);
    }
  },
};

const accessTokenRefresher = new PeriodicalAccessTokenRefresher(
  api,
  config.refreshTokenIntervalMinutes
);

restApi.addMonitor(accessTokenRefresher.monitorServerDate);
restApi.addAsyncRequestTransform(accessTokenRefresher.transformAccessToken);

export default config.useFixture ? fixtureApi : api;
