interface WhenProps {
  value?: any;
  is: any;
  children: any;
}

const When: React.FC<WhenProps> = ({ value, is, children }) => {
  if (value === undefined && is === true) {
    return children;
  }
  if (value === is) {
    return children;
  }
  return null;
};

export default When;
