// @flow
import MaskedField from '@elentari/components-eve/final-form/MaskedField';
import TextField from '@elentari/components-eve/final-form/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { indexBy, mapObjIndexed, prop } from 'ramda';
import React, { memo } from 'react';
import { useForm } from 'react-final-form';
import { OnBlur, OnChange } from 'react-final-form-listeners';
import * as yup from 'yup';
import Tooltip from '../Components/Tooltip';
import { useAddress } from '../modules/addresses/useAddress';
import { yupFieldType } from '../modules/properties/formGroups/Details';
import makeField from '../modules/properties/makeField';
import AddressFormatter from '../utils/address-formatter';
import { EnderecoFetcher } from '../utils/address-utils';
import { yupValidateZipCode } from '../utils/utils';
import AsyncPropBasedAutoComplete from './AsyncPropBasedAutocomplete';
import Field from './Field';
import When from './When';
import { defaultAddressTemplate } from './addressUtils';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    color: theme.palette.primary[100],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  autoAddress: {
    position: 'relative',
  },
}));

const zipCodeSchema = yup
  .string()
  .required('Obrigatório')
  .test('test-zipcode', 'Cep Inválido', yupValidateZipCode)
  .nullable();

const countrySchema = yup
  .string()
  .required('Obrigatório')
  .test(
    'is-valid-paisId',
    'Obrigatório',
    (value) => !value || !isNaN(Number(value)),
  )
  .nullable();

const stateSchema = yup
  .string()
  .required('Obrigatório')
  .test(
    'is-valid-estadoId',
    'Obrigatório',
    (value) => !value || !isNaN(Number(value)),
  )
  .nullable();

const citySchema = yup
  .string()
  .required('Obrigatório')
  .test(
    'is-valid-cidadeId',
    'Obrigatório',
    (value) => !value || !isNaN(Number(value)),
  )
  .nullable();

const neighborhoodSchema = yup
  .string()
  .test('is-valid-bairroId', 'Obrigatório', (value, { parent }) => {
    const neighborhood = parent.neighborhood;
    return !neighborhood ? !!value || !!Number(value) : true;
  })
  .nullable();

const requiredSchema = yup.object().shape({
  zipCode: zipCodeSchema,
  cidadeId: citySchema,
  streetDescription: yup
    .string()
    .trim()
    .required('Obrigatório')
    .max(200, 'O logradouro deve ter no máximo 200 caracteres'),
  estadoId: stateSchema,
  bairroId: neighborhoodSchema,
  paisId: countrySchema,
  complement: yup
    .string()
    .max(200, 'O complemento deve ter no máximo 200 caracteres')
    .nullable(),
  streetNumber: yup
    .string()
    .trim()
    .required('Obrigatório')
    .max(20, 'O número deve ter no máximo 20 caracteres')
    .nullable(),
});

const formatTemplate = (template, name) => {
  if (!template?.fields) return false;
  const indexedData = indexBy(prop('name'), template.fields);
  return {
    ...mapObjIndexed(
      (value, key) => ({ ...value, name: `${name}.${key}` }),
      indexedData,
    ),
  };
};

const yupDynamicAddressTemplate = (template) => {
  const shape = {};
  if (!template?.fields) {
    return shape;
  }

  template.fields.forEach((field) => {
    if (field.group === 'Endereço') {
      let validationSchema;

      switch (field.name) {
        case 'zipCode':
          validationSchema = zipCodeSchema;
          break;

        case 'country':
          validationSchema = countrySchema;
          shape.paisId = validationSchema;
          return;

        case 'state':
          validationSchema = stateSchema;
          shape.estadoId = validationSchema;
          return;

        case 'city':
          validationSchema = citySchema;
          shape.cidadeId = validationSchema;
          return;

        case 'neighborhood':
          validationSchema = neighborhoodSchema;
          shape.bairroId = validationSchema;
          return;

        default:
          validationSchema = yupFieldType(field.type).nullable();
          if ([true, 'true'].includes(field.required)) {
            validationSchema = validationSchema.required('Obrigatório');
          }
          break;
      }

      shape[field.name] = validationSchema;
    }
  });

  return shape;
};

const optionalSchema = yup
  .object()
  .shape({
    zipCode: yup
      .string()
      .trim()
      .nullable()
      .test('test-zipcode', 'Cep Inválido', yupValidateZipCode),
    cidadeId: yup.number().nullable(),
    streetDescription: yup.string().trim().nullable(),
    estadoId: yup.number().nullable(),
    neighborhood: yup
      .string()
      .trim()
      .nullable()
      .max(200, 'O bairro deve ter no máximo 200 caracteres'),
    bairroId: yup.number().nullable(),
    paisId: yup.number().nullable(),
    complement: yup
      .string()
      .nullable()
      .max(200, 'O complemento deve ter no máximo 200 caracteres'),
    streetNumber: yup
      .string()
      .trim()
      .nullable()
      .max(20, 'O número deve ter no máximo 20 caracteres'),
  })
  .nullable();

export const validator = (required = true, template = []) => {
  return required
    ? template.fields?.length
      ? yup.object().shape(yupDynamicAddressTemplate(template))
      : requiredSchema
    : optionalSchema;
};

export const format = (endereco, condominio, torre) => {
  return AddressFormatter.format(endereco, condominio, torre);
};

const Address = ({
  hasTorreId = false,
  name = 'endereco',
  required = true,
  disabled = false,
  complementDisabled,
  complementRequired,
  hideComplement = false,
  isAddressTemplateDefault = true,
  addressTemplate = defaultAddressTemplate,
  handleEnderecoExistente = () => undefined,
  handleComplementChange = () => undefined,
  alternativo = false,
}) => {
  const classes = useStyles();
  const template = formatTemplate(addressTemplate, name);
  const form = useForm();
  const hasOldComplement = !!form.getState().initialValues?.[name]?.complement;
  const hasComplement = !!(
    (template.complement || hasOldComplement) &&
    !hideComplement
  );
  const formatLabel = (label) => `${label} ${required ? '*' : ''}`;
  const enderecoFetcher = new EnderecoFetcher();
  const {
    initialOptions,
    loadAddress,
    loadingAddress,
    streetNumberRef,
    disabledCEPFields,
    getInitialOptions,
  } = useAddress(enderecoFetcher, name);

  const onChangeNeighborhood = (value) => {
    if (!value) {
      form.change(`${name}.neighborhood`, null);
    }

    if (value?.new) {
      form.change(`${name}.bairroId`, null);
    }
    form.change(
      `${name}.neighborhood`,
      value?.selectedLabel || value?.nomeBairro || null,
    );
  };

  const getTooltipText = () =>
    form.getFieldState(`${name}.zipCode`)?.value
      ? ''
      : 'Campo automático de acordo com o CEP.';

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={2} xs={12}>
        {template.zipCode && (
          <Grid item sm={6} lg={2} xs={12}>
            <MaskedField
              endAdornment="validation"
              fullWidth
              name={`${name}.zipCode`}
              label={formatLabel(template.zipCode.label)}
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              disabled={disabled}
            />
            <OnChange name={`${name}.zipCode`}>
              {(cep) => {
                if (form.getFieldState(`${name}.zipCode`).modified) {
                  form.resetFieldState(`${name}.zipCode`);
                  loadAddress(cep);
                }
              }}
            </OnChange>
          </Grid>
        )}
        {template.streetDescription && (
          <Grid item sm={6} lg={5} xs={12} className={classes.autoAddress}>
            {loadingAddress && (
              <CircularProgress
                size={24}
                className={classes.circularProgress}
              />
            )}
            <TextField
              fullWidth
              endAdornment="validation"
              name={`${name}.streetDescription`}
              label={formatLabel(template.streetDescription.label)}
              inputProps={{ maxLength: 200 }}
              disabled={disabled}
              onBlur={(e) => {
                form.change(`${name}.streetDescription`, e.target.value.trim());
              }}
            />
            <OnBlur name={`${name}.streetDescription`}>
              {() => {
                handleEnderecoExistente(form);
              }}
            </OnBlur>
          </Grid>
        )}
        {template.streetNumber && (
          <Grid item sm={4} lg={2} xs={12}>
            <TextField
              fullWidth
              endAdornment="validation"
              name={`${name}.streetNumber`}
              label={formatLabel(template.streetNumber.label)}
              inputProps={{ maxLength: 20, ref: streetNumberRef }}
              disabled={disabled}
            />
            <OnBlur name={`${name}.streetNumber`}>
              {() => {
                handleEnderecoExistente(form);
              }}
            </OnBlur>
          </Grid>
        )}
        {template.neighborhood && (
          <Grid item sm={8} lg={3} xs={12} className={classes.autoAddress}>
            {loadingAddress && (
              <CircularProgress
                size={24}
                className={classes.circularProgress}
              />
            )}
            <Field
              name={`${name}.cidadeId`}
              render={({ input: { value: cidadeId } }) => (
                <>
                  <Field
                    fullWidth
                    size="large"
                    name={`${name}.bairroId`}
                    allowNew
                    label={formatLabel(template.neighborhood.label)}
                    component={AsyncPropBasedAutoComplete}
                    loadOptions={(input) =>
                      enderecoFetcher.getBairroListByCidadeId(cidadeId, input)
                    }
                    onChange={onChangeNeighborhood}
                    onBlur={() => {
                      handleEnderecoExistente(form);
                    }}
                    propLabel="nomeBairro"
                    disabled={!cidadeId}
                    initialOptions={initialOptions.bairro}
                  />
                </>
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={2} xs={12}>
        <Grid item container spacing={2} xs={12} lg={hideComplement ? 12 : 7}>
          {template.country && (
            <Grid item xs className={classes.autoAddress}>
              {loadingAddress && (
                <CircularProgress
                  size={24}
                  className={classes.circularProgress}
                />
              )}
              <Field
                fullWidth
                size="large"
                name={`${name}.paisId`}
                label={formatLabel(template.country.label)}
                component={AsyncPropBasedAutoComplete}
                loadOptions={(input) =>
                  enderecoFetcher.getPaisListByTerm(input)
                }
                propLabel="nomePais"
                initialOptions={initialOptions.pais}
                disabled={disabledCEPFields}
              />
              <OnChange name={`${name}.paisId`}>
                {(paisId) => {
                  const fixedNeighborhood =
                    form.getState().values?.endereco?.fixedNeighborhood;

                  !paisId && form.change(`${name}.country`, null);
                  form.change(`${name}.estadoId`, null);
                  form.change(`${name}.cidadeId`, null);
                  form.change(`${name}.bairroId`, null);
                  !fixedNeighborhood &&
                    form.change(`${name}.neighborhood`, null);
                }}
              </OnChange>
            </Grid>
          )}
          {template.state && (
            <Grid item xs className={classes.autoAddress}>
              {loadingAddress && (
                <CircularProgress
                  size={24}
                  className={classes.circularProgress}
                />
              )}
              <Field
                name={`${name}.paisId`}
                render={({ input: { value: paisId } }) => (
                  <>
                    <Field
                      fullWidth
                      size="large"
                      name={`${name}.estadoId`}
                      label={formatLabel(template.state.label)}
                      component={AsyncPropBasedAutoComplete}
                      ignoreFilterOptions
                      loadOptions={(input) =>
                        enderecoFetcher.getEstadoListByPaisId(paisId, input)
                      }
                      propLabel="nomeEstado"
                      disabled={!paisId || disabledCEPFields}
                      initialOptions={initialOptions.estado}
                    />
                    <OnChange name={`${name}.estadoId`}>
                      {(estadoId) => {
                        const fixedNeighborhood =
                          form.getState().values?.endereco?.fixedNeighborhood;

                        !estadoId && form.change(`${name}.state`, null);
                        form.change(`${name}.cidadeId`, null);
                        form.change(`${name}.bairroId`, null);
                        !fixedNeighborhood &&
                          form.change(`${name}.neighborhood`, null);
                      }}
                    </OnChange>
                  </>
                )}
              />
            </Grid>
          )}
          {template.city && (
            <Grid item xs className={classes.autoAddress}>
              {loadingAddress && (
                <CircularProgress
                  size={24}
                  className={classes.circularProgress}
                />
              )}
              <Field
                name={`${name}.estadoId`}
                render={({ input: { value: estadoId } }) => (
                  <>
                    <Field
                      fullWidth
                      size="large"
                      name={`${name}.cidadeId`}
                      label={formatLabel(template.city.label)}
                      component={AsyncPropBasedAutoComplete}
                      loadOptions={(input) =>
                        enderecoFetcher.getCidadeListByEstadoId(estadoId, input)
                      }
                      propLabel="nomeCidade"
                      disabled={!estadoId || disabledCEPFields}
                      initialOptions={initialOptions.cidade}
                    />
                    <OnChange name={`${name}.cidadeId`}>
                      {(cidadeId) => {
                        const fixedNeighborhood =
                          form.getState().values?.endereco?.fixedNeighborhood;

                        !cidadeId && form.change(`${name}.city`, null);
                        form.change(`${name}.bairroId`, null);
                        !fixedNeighborhood &&
                          form.change(`${name}.neighborhood`, null);
                      }}
                    </OnChange>
                  </>
                )}
              />
            </Grid>
          )}
        </Grid>

        {hasComplement && (
          <Grid item lg={5} xs={12}>
            <Tooltip
              arrow
              placement="bottom"
              interactive
              title="Essa informação não é exibida no site e nos portais"
            >
              <TextField
                fullWidth
                name={`${name}.complement`}
                label={
                  template.complement?.required &&
                  (disabled || complementRequired)
                    ? `${template.complement?.label || 'Complemento'} *`
                    : `${template.complement?.label || 'Complemento'}`
                }
                endAdornment="validation"
                inputProps={{ maxLength: 200 }}
                disabled={complementDisabled}
                onBlur={() => {
                  handleComplementChange(form);
                  handleEnderecoExistente(form);
                }}
              />
            </Tooltip>
          </Grid>
        )}
        {!hasComplement && template.torreBloco && template.apartamento && (
          <>
            <Grid item sm={3} xs={12}>
              <TextField
                endAdornment="validation"
                fullWidth
                name={`${name}.torreBloco`}
                label={template.torreBloco?.label}
                disabled={hasTorreId}
              />
              <OnBlur name={`${name}.torreBloco`}>
                {() => {
                  handleEnderecoExistente(form);
                }}
              </OnBlur>
            </Grid>
            <Grid item sm={2} xs={12}>
              {makeField(template.apartamento)}
              <OnBlur name={`${name}.apartamento`}>
                {() => {
                  handleEnderecoExistente(form);
                }}
              </OnBlur>
            </Grid>
          </>
        )}
        {!hasComplement && template.lote && template.quadra && (
          <>
            <Grid item sm={2} xs={12}>
              {makeField(template.quadra)}
              <OnBlur name={`${name}.quadra`}>
                {() => {
                  handleEnderecoExistente(form);
                }}
              </OnBlur>
            </Grid>
            <Grid item sm={3} xs={12}>
              {makeField(template.lote)}
              <OnBlur name={`${name}.lote`}>
                {() => {
                  handleEnderecoExistente(form);
                }}
              </OnBlur>
            </Grid>
          </>
        )}
      </Grid>
      {!isAddressTemplateDefault && (
        <Grid item container spacing={2} xs={12}>
          {hasComplement && template.quadra && template.lote && (
            <>
              <Grid item sm={2} xs={12}>
                {makeField(template.quadra)}
                <OnBlur name={`${name}.quadra`}>
                  {() => {
                    handleEnderecoExistente(form);
                  }}
                </OnBlur>
              </Grid>
              <Grid item sm={3} xs={12}>
                {makeField(template.lote)}
                <OnBlur name={`${name}.lote`}>
                  {() => {
                    handleEnderecoExistente(form);
                  }}
                </OnBlur>
              </Grid>
            </>
          )}
          {hasComplement && template.torreBloco && (
            <Grid item sm={2} xs={12}>
              <TextField
                endAdornment="validation"
                fullWidth
                name={`${name}.torreBloco`}
                label={template.torreBloco?.label}
                disabled={hasTorreId}
              />
              <OnBlur name={`${name}.torreBloco`}>
                {() => {
                  handleEnderecoExistente(form);
                }}
              </OnBlur>
            </Grid>
          )}
          {hasComplement && template.apartamento && (
            <Grid item sm={2} xs={12}>
              {makeField(template.apartamento)}
              <OnBlur name={`${name}.apartamento`}>
                {() => {
                  handleEnderecoExistente(form);
                }}
              </OnBlur>
            </Grid>
          )}
          {template.andar && (
            <Grid item sm={2} xs={12}>
              {makeField(template.andar)}
            </Grid>
          )}
          {hasComplement && template.quadra && !template.lote && (
            <Grid item sm={2} xs={12}>
              {makeField(template.quadra)}
            </Grid>
          )}
          {!hasComplement && template.quadra && template.andar && (
            <Grid item sm={2} xs={12}>
              {makeField(template.quadra)}
            </Grid>
          )}
          {template.reference && (
            <Grid item xs={12} sm>
              {makeField(template.reference)}
            </Grid>
          )}
        </Grid>
      )}
      <Grid item container spacing={2} xs={12}>
        {template.reference && isAddressTemplateDefault && (
          <Grid item xs={12} sm={6}>
            {makeField(template.reference)}
          </Grid>
        )}
        <When is={isAddressTemplateDefault}>
          <Grid item sm={3} xs={12} className={classes.autoAddress}>
            {loadingAddress && (
              <CircularProgress
                size={24}
                className={classes.circularProgress}
              />
            )}
            <Tooltip arrow interactive title={getTooltipText()}>
              <span>
                <TextField
                  fullWidth
                  endAdornment="validation"
                  name={
                    alternativo ? 'latitudeEnderecoAlternativo' : 'latitude'
                  }
                  label={'Latitude'}
                  inputProps={{ maxLength: 200 }}
                  disabled
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item sm={3} xs={12} className={classes.autoAddress}>
            {loadingAddress && (
              <CircularProgress
                size={24}
                className={classes.circularProgress}
              />
            )}
            <Tooltip arrow interactive title={getTooltipText()}>
              <span>
                <TextField
                  fullWidth
                  endAdornment="validation"
                  name={
                    alternativo ? 'longitudeEnderecoAlternativo' : 'longitude'
                  }
                  label={'Longitude'}
                  inputProps={{ maxLength: 200 }}
                  disabled
                />
              </span>
            </Tooltip>
          </Grid>
        </When>
        <OnChange name="tipoId">
          {() => {
            if (form.getFieldState('tipoId').modified)
              form.batch(() => {
                form.change(`${name}.quadra`, null);
                form.change(`${name}.lote`, null);
                form.change(`${name}.andar`, null);
                form.change(`${name}.apartamento`, null);
                form.change(`${name}.torreBloco`, null);
              });
          }}
        </OnChange>
        <OnChange name="enderecoCondominio">
          {(enderecoCondominio) => {
            if (!alternativo && !form.getState().submitting)
              getInitialOptions(enderecoCondominio);
          }}
        </OnChange>
        <OnChange name="enderecoAlternativoCondominio">
          {(enderecoAlternativoCondominio) => {
            if (
              alternativo &&
              enderecoAlternativoCondominio &&
              form.getState().values?.utilizarEnderecoCondominio &&
              !form.getState().submitting
            )
              getInitialOptions(enderecoAlternativoCondominio);
          }}
        </OnChange>
      </Grid>
    </Grid>
  );
};

export default memo(Address);
