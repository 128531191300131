import React, { useEffect, useState } from 'react';
import Field from '../../Components/Field';
import IdBasedAutoComplete from '../../Components/IdBasedAutocomplete';
import { LabelRole } from '../../models/user';
import { ROLES } from '../../modules/users/useRoles';
import { usersSemPaginacaoRepository } from '../../modules/users/usersRepository';

interface UserAutocompleteProps {
  name: string;
  label: string;
  size?: 'small' | 'medium';
  diretoresOnly?: boolean;
  showRole?: boolean;
}

export default function UserAutocomplete(props: UserAutocompleteProps) {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    props.diretoresOnly ? loadDiretores().then(setUsers) : loadUsers().then(setUsers);
  }, []);

  return (
    <Field
      component={IdBasedAutoComplete}
      getOptionLabel={(user: User) =>
        user.name + (props.showRole ? ` - ${ROLES[user.role.label]}` : '')
      }
      options={users}
      {...props}
    />
  );
}

interface User {
  id: number;
  name: string;
  role: { label: LabelRole };
}

async function loadUsers(): Promise<User[]> {
  const response = await usersSemPaginacaoRepository.list({
    query: {
      '$select[0]': 'id',
      '$select[1]': 'name',
      '$include[role]': '1',
      '$select[2]': 'role.label',
      $limit: -1,
      active: true,
    },
  });
  return response.ok ? response.data : [];
}

async function loadDiretores(): Promise<User[]> {
  const response = await usersSemPaginacaoRepository.list({
    query: {
      '$include[role]': '1',
      '$role.label$': 'DIRETOR',
      '$select[0]': 'id',
      '$select[1]': 'name',
      limit: -1,
      active: true,
    },
  });
  return response.ok ? response.data : [];
}
