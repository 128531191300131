import { createBasicRepository as createRepository } from 'elentari';
import { restApi } from '../../services/api';

export const resourceAccessAuditRepository = createRepository({
  path: 'resources-access-document',
  restApi,
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['data', 'total'],
  },
});

export const getHistorico = async (params, onError) => {
  const errorMessage = 'Erro ao buscar dados do histórico';
  try {
    const auditResponse = await resourceAccessAuditRepository.list(params);
    if (!auditResponse.data && onError) onError(errorMessage);

    return auditResponse.data;
  } catch (error) {
    if (onError) onError(errorMessage);
  }
};
