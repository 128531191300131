import {
  Grid,
  GridSpacing,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { CSSProperties } from 'react';

const useStyles = makeStyles((theme) => ({
  statusChild: {
    height: '100%',
    width: '100%',
    borderRadius: '100%',
  },
  status: {
    width: theme.spacing(3 / 2),
    height: theme.spacing(3 / 2),
    borderRadius: '100%',
    display: 'inline-block',
  },
}));

interface Props {
  label?: string;
  labelProps?: CSSProperties;
  tooltipTitle?: string;
  color?: string;
  spacing?: GridSpacing;
  direction?: 'label-icon' | 'icon-label';
  children?: React.ReactNode;
}

const StatusIcon: React.FC<Props> = ({
  label,
  tooltipTitle,
  color,
  spacing,
  labelProps,
  direction = 'icon-label',
  children,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction={direction === 'label-icon' ? 'row-reverse' : 'row'}
      alignItems="center"
      spacing={spacing || 1}
      style={{ display: 'flex', flexWrap: 'nowrap' }}
    >
      <Tooltip disableHoverListener={!tooltipTitle} title={tooltipTitle || ''}>
        <Grid item xs="auto">
          <div className={classes.status}>
            <div
              style={{ backgroundColor: color }}
              className={classes.statusChild}
            ></div>
          </div>
        </Grid>
      </Tooltip>
      {label || children ? (
        <Grid item xs>
          {children || (
            <Typography variant="body1" style={labelProps || undefined}>
              {label}
            </Typography>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default StatusIcon;
