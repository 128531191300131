import { createBasicRepository as createRepository } from 'elentari';
import { paginate, softDeleteStrategy } from '../../../lib/feathers';
import { BaseModel } from '../../../models/baseModel';
import { restApi } from '../../../services/api';
import { OpcoesStatusComissaoFiancaDigital } from './comissoesRepository';

export interface StatusComissao extends BaseModel {
  id: number;
  comissaoFiancaDigitalId: number;
  tenantId: number;
  status: OpcoesStatusComissaoFiancaDigital;
  observacao?: string;
  dataInicio: Date;
  dataFim?: Date;
}

const props = {
  path: 'status-comissao-fianca-digital',
  restApi,
  softDeleteStrategy,
  queryTransform: {
    ...paginate,
  },
};

export default createRepository(props);
