import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  imageBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '250px',
    height: '250px',
    color: '#999',
    background: '#e9e9e9',
    borderRadius: '6px',
    overflow: 'hidden',
    userSelect: 'none',
    '&:hover': {
      transition: 'background 0.4s',
      background: '#f1f1f1',
    },
    '& label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
    '& input': {
      display: 'none',
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      // objectFit: 'cover',
    },
    '& .actions': {
      position: 'absolute',
      display: 'none',
      gap: '16px',
      bottom: '8px',
      pointerEvents: 'none',
    },
    '&[data-preview="true"]:hover .actions': {
      display: 'flex',
    },
    '&[data-preview="true"]:hover::before': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.4)',
      pointerEvents: 'none',
    },
    '& .actions button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      border: 0,
      color: '#eee',
      background: 'rgba(0, 0, 0, 0.8)',
      borderRadius: '100%',
      lineHeight: 1,
    },
    '& .actions button:last-child': {
      cursor: 'pointer',
      pointerEvents: 'auto',
    },
  },
  fullWidth: {
    width: '100%',
    height: 'auto',
  },
}));
