import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import io from 'socket.io-client';
import MessageSnackbar from '../Components/MessageSnackbar';
import config from '../config';

export const RealTimeContext = createContext();

const initialState = {
  roletas: {},
  notificacoes: [],
  message: null,
};

export const Actions = {
  ENTRAR_ROLETA: 'roleta/entrar_roleta',
  SAIR_ROLETA: 'roleta/sair_roleta',
  NOVO_TOKEN: 'roleta/novo_token',
  ATUALIZAR_LISTA_ROLETA: 'roleta/atualizar_lista_roleta',
  ATUALIZAR_USER_STATUS_ROLETA: 'roleta/atualizar_user_status_roleta',
  EDITAR_USER_STATUS_ROLETA: 'roleta/editar_user_status_roleta',

  ATUALIZAR_LISTA_NOTIFICACOES: 'notificacoes/atualizar_lista_notificacoes',
  MARCAR_COMO_LIDA_NOTIFICACOES: 'notificacoes/marcar_como_lida_notificacoes',

  SERVER_MESSAGES: 'server/messages',
  DISCONNECT: 'disconnect',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.ATUALIZAR_LISTA_ROLETA: {
      return {
        ...state,
        roletas: action.payload,
      };
    }
    case Actions.ATUALIZAR_USER_STATUS_ROLETA: {
      return {
        ...state,
        user: {
          ...state.user,
          statusRoleta: action.payload.userStatus,
        },
      };
    }
    case Actions.ATUALIZAR_LISTA_NOTIFICACOES: {
      return {
        ...state,
        notificacoes: action.payload.notificacoes,
      };
    }
    case Actions.SERVER_MESSAGES: {
      return {
        ...state,
        message: {
          ...action.payload,
          open: action.payload.open != null ? action.payload.open : true,
        },
      };
    }
    case Actions.DISCONNECT: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export const RealTimeProvider = ({ userToken, children }) => {
  const ENDPOINT = config.socketURL;
  const [state, reducerDispatch] = useReducer(reducer, { ...initialState });
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!userToken) {
      return;
    }
    const socket = io(`${ENDPOINT}?token=${userToken}`, {
      path: '/ws',
      transports: ['websocket'],
      forceNew: true,
    });
    setSocket(socket);

    socket.on(Actions.ATUALIZAR_LISTA_ROLETA, (payload) =>
      reducerDispatch({
        type: Actions.ATUALIZAR_LISTA_ROLETA,
        payload,
      })
    );
    socket.on(Actions.ATUALIZAR_USER_STATUS_ROLETA, (payload) =>
      reducerDispatch({
        type: Actions.ATUALIZAR_USER_STATUS_ROLETA,
        payload,
      })
    );
    socket.on(Actions.ATUALIZAR_LISTA_NOTIFICACOES, (payload) =>
      reducerDispatch({
        type: Actions.ATUALIZAR_LISTA_NOTIFICACOES,
        payload,
      })
    );
    socket.on(Actions.DISCONNECT, () => reducerDispatch({ type: Actions.DISCONNECT }));
    socket.on(Actions.SERVER_MESSAGES, (payload) =>
      reducerDispatch({ type: Actions.SERVER_MESSAGES, payload })
    );

    return () => socket.disconnect();
  }, [userToken, ENDPOINT]);

  const dispatch = ({ type, payload }) => {
    switch (type) {
      case Actions.EDITAR_USER_STATUS_ROLETA:
      case Actions.ENTRAR_ROLETA:
      case Actions.NOVO_TOKEN:
      case Actions.MARCAR_COMO_LIDA_NOTIFICACOES:
      case Actions.SAIR_ROLETA: {
        socket.emit(type, payload);
        return;
      }
      case Actions.DISCONNECT: {
        socket.disconnect();
        return;
      }
      default: {
        reducerDispatch({ type, payload });
      }
    }
  };

  return (
    <RealTimeContext.Provider value={[state, dispatch]}>
      {children({ state, dispatch })}
      <MessageSnackbar
        open={state.message?.open}
        severity={state.message?.type ?? 'info'}
        message={state.message?.message}
        onClose={() =>
          reducerDispatch({
            type: Actions.SERVER_MESSAGES,
            payload: { ...state.message, open: false },
          })
        }
      />
    </RealTimeContext.Provider>
  );
};

export const useRealtime = () => useContext(RealTimeContext);
