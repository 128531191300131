import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { formatCurrency } from '../../fechamento/utils';
import { useStyles } from './styles';

const Detalhes = ({
  usuario,
  valorTotal,
  quantidadeComissoes,
  imobiliaria,
}: {
  usuario: string;
  quantidadeComissoes: number;
  valorTotal: number;
  imobiliaria: string;
}) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell width='30%' className={classes.titleHeader}>
              Usuário
            </TableCell>
            <TableCell width='30%' className={classes.titleHeader}>
              Quantidade de comissões
            </TableCell>
            <TableCell width='30%' className={classes.titleHeader}>
              Valor total
            </TableCell>
            <TableCell width='30%' className={classes.titleHeader}>
              Imobiliária
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.lastRow}>
            <TableCell>{usuario}</TableCell>
            <TableCell>{quantidadeComissoes}</TableCell>
            <TableCell>{formatCurrency(valorTotal)}</TableCell>
            <TableCell>{imobiliaria}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Detalhes;
