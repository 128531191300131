import List from '@elentari/components-eve/components/List';
import { useListWithFilter } from '@elentari/core';
import { Grid, IconButton } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { AuthContext, useFilter } from 'elentari';
import React, { useContext, useState } from 'react';
import { Form } from 'react-final-form';
import {
  FetcherResponseApi,
  OptionsFetcher,
} from '../../../@types/ListFetcher';
import EmptyComponent from '../../../Components/EmptyList';
import { paginate } from '../../../lib/feathers';
import { restApi } from '../../../services/api';
import { dateFormatter, priceFormatter } from '../../../utils/formater';
import ModalComissaoPagamento from './ModalComissaoPagamento';
import { FormFilterPagamentos } from './formFilterPagamentos';
import { useStyles } from './styles';
import { IPagamentosDataArray } from './types';

type FetcherResponse = FetcherResponseApi<IPagamentosDataArray>;

export function ListaPagamentos() {
  const classes = useStyles();
  const filter = useFilter({});
  const context: any = useContext(AuthContext);
  const isSuperAdmin = context.user?.roles === 'SUPER_ADMIN';
  const [pagamento, setPagamento] = useState<number | null>();

  async function fetcher(
    _endpoint: string,
    { term, page, order, orderBy }: OptionsFetcher,
  ) {
    const count = 15;

    const params = new URLSearchParams([
      paginate.limit(count),
      paginate.skip(page * count),
      paginate.sort(`${orderBy} ${order}`),
      ...Object.entries(term),
    ]);

    const response = await restApi.get<FetcherResponse>(
      `${_endpoint}?${params}`,
    );

    if (response.ok && response.data) {
      return { tag: 'success', ...response.data };
    }

    return { tag: 'error', error: 'Erro ao carregar a lista' };
  }

  async function formSubmit(data: any) {
    if (data.$term) {
      data.$term = data.$term
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim();
    }

    filter.onSubmit(data);
  }

  const { state, ...rest } = useListWithFilter<IPagamentosDataArray>(
    'pagamento-comissao-fianca-digital',
    fetcher,
    { defaultSort: 'id', order: 'desc' },
  );

  function buttonDownload(url: string | null) {
    return (
      <IconButton
        size="small"
        onClick={(event) => event.stopPropagation()}
        disabled={!url}
        href={url || ''}
      >
        <CloudDownloadIcon htmlColor="#7E51DF" />
      </IconButton>
    );
  }

  const formatToList = (records: IPagamentosDataArray[]): any => {
    if (!records) return;

    return records.map((row) => ({
      id: row.id,
      corretor: row.corretor,
      imobiliaria: row.imobiliaria,
      dataPagamento: dateFormatter.format(new Date(row.dataPagamento)),
      valorPagamento: priceFormatter.format(row.valorPagamento),
      usuarioPagamento: row.usuarioPagamento,
      comissoesRelacionadas: row.comissoes.join(', '),
      comprovante: buttonDownload(row.comprovantePagamento),
    }));
  };

  const configAdmin = {
    id: {
      label: 'Id',
    },
    corretor: {
      label: 'Corretor',
    },
    imobiliaria: {
      label: 'Imóbiliaria',
    },
    dataPagamento: {
      label: 'Data de Pagamento',
    },
    valorPagamento: {
      label: 'Valor Pagamento',
    },
    usuarioPagamento: {
      label: 'Usuário pagamento',
    },
    comissoesRelacionadas: {
      label: 'Comissões',
    },
    comprovante: {
      label: 'Comprovante',
    },
  };

  const configUser = {
    id: {
      label: 'Id',
    },
    dataPagamento: {
      label: 'Data de Pagamento',
    },
    valorPagamento: {
      label: 'Valor Pagamento',
    },
    usuarioPagamento: {
      label: 'Usuário pagamento',
    },
    comissoesRelacionadas: {
      label: 'Comissões',
    },
    comprovante: {
      label: 'Comprovante',
    },
  };

  return (
    <Grid container>
      <Form
        onSubmit={formSubmit}
        initialValues={{ ...filter.initialValues }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.container}>
            <FormFilterPagamentos isSuperAdmin={isSuperAdmin} />
            <List
              title=""
              disableFilter
              EmptyComponent={EmptyComponent}
              state={{ ...state, list: formatToList(state.list) }}
              config={isSuperAdmin ? configAdmin : configUser}
              headerProps={{ spacing: 0, className: classes.headerProps }}
              onClick={(_, id) => setPagamento(id as any)}
              height={
                isSuperAdmin ? 'calc(100vh - 420px)' : 'calc(100vh - 260px)'
              }
              {...rest}
            />
          </form>
        )}
      />
      <ModalComissaoPagamento
        pagamentoId={pagamento ? (pagamento as any).id : null}
        open={!!pagamento}
        onClose={() => setPagamento(null as any)}
      />
    </Grid>
  );
}
