import { restApi } from '../../../services/api';

export interface FormValues {
  cidadeId: number;
  city: string;
  estadoId: number;
  state: string;
  paisId: number;
  country: string;
  idBairroOrigem: number;
  idBairroDestino: number;
  tenantIdDestino: number;
  tenantIdOrigem: number;
}

export interface GetBairrosResponse {
  bairroId: number;
  nomeBairro: string;
  cidadeId: number;
  nomeCidade: string;
  estadoId: number;
  nomeEstado: string;
  paisId: number;
  nomePais: string;
  tenantId?: number;
  quantidadeEnderecos: number;
}

export const getBairro = async (bairroId: number) => {
  const response = await restApi.get<GetBairrosResponse>(
    `/gerenciamento-bairros/${bairroId}`,
  );

  return response.data;
};

export const formatMessage = (quantidade: number) => {
  switch (quantidade) {
    case 0:
      return 'Nenhum registro será alterado.';
    case 1:
      return 'Um registro será alterado.';
    default:
      return `Serão alterados ${quantidade} registros.`;
  }
};
