import React from 'react';
import Tooltip from './Tooltip';
import { Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';

interface ValueWithTooltipProps {
  interactive?: boolean;
  arrow?: boolean;
  placement?: string;
  tooltipTitle?: string;
  value: string | number;
  additionalInfo?: string;
  separator?: string;
  linkTo?: string;
  variant?: Variant;
  bold?: boolean;
  onClick?: () => undefined;
  infoBelow?: boolean;
  noWrap?: boolean;
}

const ValueWithTooltip: React.FC<ValueWithTooltipProps> = ({
  interactive,
  arrow,
  placement,
  tooltipTitle,
  value,
  additionalInfo,
  separator,
  linkTo,
  variant,
  bold,
  onClick,
  infoBelow,
  noWrap,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const stopPropagation = (event: React.SyntheticEvent) =>
    event.stopPropagation();
  const custom: React.CSSProperties = infoBelow
    ? { display: 'flex', flexDirection: 'column' }
    : {};

  return (
    <Tooltip
      interactive={interactive}
      arrow={arrow}
      placement={placement}
      title={tooltipTitle}
    >
      <div
        style={{
          width: '100%',
          ...(noWrap
            ? {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            : {}),
          ...custom,
        }}
      >
        <Typography
          onClick={onClick}
          component="span"
          style={
            bold
              ? { display: 'inline', fontWeight: 'bolder' }
              : { display: 'inline' }
          }
        >
          {value}
        </Typography>
        {!linkTo ? (
          <Typography
            variant={variant}
            component="span"
            style={{ color: 'GrayText' }}
          >
            {` ${separator} `}
            {additionalInfo}
          </Typography>
        ) : (
          <>
            <Typography variant={variant} component="span">
              {` ${separator} `}
            </Typography>
            <Link
              variant={variant}
              href={linkTo}
              target={isMobile ? '_self' : '_blank'}
              onClick={stopPropagation}
            >
              {additionalInfo}
            </Link>
          </>
        )}
      </div>
    </Tooltip>
  );
};

ValueWithTooltip.defaultProps = {
  variant: 'body2',
  separator: '',
  additionalInfo: '',
  tooltipTitle: '',
  interactive: true,
  arrow: true,
  placement: 'left-start',
  noWrap: true,
};

export default ValueWithTooltip;
