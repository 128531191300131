import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
  },
  user: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    top: 10,
    right: 10,
    cursor: 'pointer',
    zIndex: 1,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  paper: { position: 'relative', padding: '35px 20px 20px 20px' },
  avatar: { width: 35, height: 35 },
}));
