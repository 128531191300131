import TextField from '@elentari/components-eve/final-form/TextField';
import { Grid, InputAdornment } from '@material-ui/core';
import { useFilter } from 'elentari';
import React from 'react';
import { BsSearch } from 'react-icons/bs';
import { CustomArrayAutoComplete } from '../../../Components/CustomArrayAutoComplete';
import DateRange from '../../../Components/DateRange';
import Field from '../../../Components/Field';
import FilterActions from '../../../Components/FilterActions';
import { useStyles } from './styles';

const statusOptions = [
  { label: 'Pago', value: 'PAGO' },
  { label: 'Pendente', value: 'PENDENTE' },
  { label: 'Cancelado', value: 'CANCELADO' },
];

interface FormFilterComissoesProps {
  isSuperAdmin: boolean;
}

export function FormFilterComissoes({
  isSuperAdmin,
}: FormFilterComissoesProps) {
  const classes = useStyles();
  const filter = useFilter({});

  function onClear() {
    filter.onClear();
  }

  return (
    <Grid container spacing={2} className={classes.filter}>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          size='small'
          name='$term'
          placeholder={
            isSuperAdmin
              ? 'Usuário, imobiliária, cliente ou imóvel'
              : 'Busque por cliente ou imóvel'
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <BsSearch />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Field
          label='Status'
          name='status'
          component={CustomArrayAutoComplete}
          options={statusOptions}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <DateRange
          labelGroup='Previsão ou data de pagamento'
          nameMinValue='previsaoPagamentoMin'
          nameMaxValue='previsaoPagamentoMax'
        />
      </Grid>
      <Grid item xs={12} sm={2} style={{ display: 'flex' }} justify='center'>
        <FilterActions onClear={() => onClear()} />
      </Grid>
    </Grid>
  );
}
