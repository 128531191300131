import { AuthContext } from 'elentari';
import { useContext, useEffect, useState } from 'react';
import { SnackBarContext } from '../../../lib/SnackBarContext';
import { restApi } from '../../../services/api';
import { priceFormatter } from '../../../utils/formater';
import { monthToString } from '../../../utils/utils';
import { ICardsComissoes, ICardsHistorico } from './types';

const useCardsComissoes = () => {
  const [data, setData] = useState<ICardsComissoes>();
  const setSnackbar = useContext(SnackBarContext);
  const context: any = useContext(AuthContext);
  const isSuperAdmin = context.user?.roles === 'SUPER_ADMIN';

  const fetchData = async () => {
    if (!isSuperAdmin) return;

    const response = await restApi.get<ICardsComissoes>(
      '/comissao-fianca-digital?component=CARDS',
    );

    if (response.ok && response.data) {
      const parsedData = { ...response.data };
      parsedData.historicoPagamentos = (
        parsedData.historicoPagamentos as ICardsHistorico[]
      ).map((row) => ({
        key: `${monthToString(row.mes).substring(0, 3)}/${row.ano}`,
        mes: row.mes,
        ano: row.ano,
        quantidadeComissoesPagas: row.quantidadeComissoesPagas,
        valorTotalComissaoPaga: priceFormatter.format(
          row.valorTotalComissaoPaga as number,
        ),
      }));

      setData({ ...parsedData });
      return;
    }

    setSnackbar({
      open: true,
      state: {
        severity: 'error',
        message: 'Houve um erro ao buscar informações dos cards',
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, fetchData };
};

export default useCardsComissoes;
