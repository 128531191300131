import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import Tooltip from '../../../Components/Tooltip';
import ComissaoDialog from './ComissaoDialog';

interface CadastroComissaoFabProps {
  className?: string;
  onSubmit?: () => void;
}

const CadastroComissaoFab = ({ className, onSubmit }: CadastroComissaoFabProps) => {
  const [openCadastroComissao, setOpenCadastroComissao] = useState(false);

  const handleCloseCadastroComissao = () => {
    setOpenCadastroComissao(false);
  };

  const handleClickCadastroComissao = () => {
    setOpenCadastroComissao(true);
  };
  return (
    <>
      <Tooltip title='Adicionar comissão'>
        <Fab
          color='primary'
          className={className}
          aria-label='Add'
          onClick={handleClickCadastroComissao}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <ComissaoDialog
        open={openCadastroComissao}
        onSubmit={onSubmit}
        cadastroManual
        showContratosField
        handleClose={handleCloseCadastroComissao}
      />
    </>
  );
};

export default CadastroComissaoFab;
