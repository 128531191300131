import { createBasicRepository as createRepository } from 'elentari';
import { paginate, query } from '../../lib/feathers';
import { restApi } from '../../services/api';

const props = {
  path: 'teams',
  restApi,
  queryTransform: {
    description: query.like('description'),
    keyword: query.like('keyword'),
    id: query.in('id'),
    ...paginate,
  },
};

export const teamsRepository = createRepository({
  ...props,
});

export const teamsSemPaginacaoRepository = createRepository({
  ...props,
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['data', 'total'],
  },
});
