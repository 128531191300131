import { createBasicRepository as createRepository } from 'elentari';
import { restApi } from '../../services/api';

export const preferencesTemplateRepository = createRepository({
  path: 'template-preferencias',
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['data', 'length'],
  },
  restApi,
});
