import TextField from '@elentari/components-eve/final-form/TextField'
import { Grid, InputAdornment } from '@material-ui/core'
import { useFilter } from 'elentari'
import React from 'react'
import { BsSearch } from 'react-icons/bs'
import DateRange from '../../../Components/DateRange'
import FilterActions from '../../../Components/FilterActions'
import { useStyles } from './styles'

interface FormFilterPagamentosProps {
  isSuperAdmin: boolean
}

export function FormFilterPagamentos({ isSuperAdmin }: FormFilterPagamentosProps) {
  const classes = useStyles()
  const filter = useFilter({})

  function onClear() {
    filter.onClear()
  }

  return (
    <Grid container spacing={2} className={classes.filter}>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          size="small"
          name="$term"
          placeholder={isSuperAdmin ? 'Corretor, imobiliária ou usuário' : 'Busque por usuário'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateRange
          labelGroup="Data de Pagamento"
          nameMinValue="dataPagamentoMin"
          nameMaxValue="dataPagamentoMax"
        />
      </Grid>
      <Grid item xs={12} sm={3} style={{ display: 'flex' }} justify="center">
        <FilterActions onClear={() => onClear()} />
      </Grid>
    </Grid>
  )
}
