import { useEffect, useState } from 'react';
import config from '../../config';
import useIntegration from '../integrations/useIntegration';
import { Chat2DeskRepository, makeChat2DeskRepository } from './chat2DeskRepository';

export function useChat2DeskRepository(apiToken?: string) {
  const chat2DeskIntegration = useIntegration('CHAT2DESK');
  const [chat2DeskRepository, setChat2DeskRepository] = useState<Chat2DeskRepository | null>(
    apiToken ? makeChat2DeskRepository(config.chat2DeskURL, apiToken) : null
  );

  useEffect(() => {
    if (apiToken || chat2DeskIntegration?.config?.active) {
      const chat2DeskToken = apiToken ?? chat2DeskIntegration?.config?.configData?.apiToken;
      setChat2DeskRepository(makeChat2DeskRepository(config.chat2DeskURL, chat2DeskToken));
    }
  }, [chat2DeskIntegration, apiToken]);

  return chat2DeskRepository;
}
