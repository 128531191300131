import { IEndereco } from './adresses';

export interface User {
  id: number;
  tenantId: number;
  name: string;
  username: string;
  cellphone: string;
  initExpires?: Date | string | null;
  operatingSegment?: SegmentOperation | null;
  role: IRoles;
  roleId: number;
  statusFilaRoleta: StatusFila;
  ultimaAtualizacaoStatusRoleta: Date | string;
  officeId?: number | null;
  teamId?: number | null;
  idMigracao?: number | null;
  chat2DeskId?: number | null;
  active: boolean;
  leader?: boolean | null;
  gender?: string | null;
  entryDate?: Date | string | null;
  birthday?: Date | string | null;
  radio?: string | null;
  experienceInYears?: number | null;
  otherExperiences?: string | null;
  aboutMe?: string | null;
  facebookUrl?: string | null;
  instagramUrl?: string | null;
  twitterUrl?: string | null;
  blogUrl?: string | null;
  enderecoId?: number | null;
  endereco?: IEndereco | null;
  creci?: string | null;
  creciType?: CreciType | null;
  rg?: string | null;
  cpf?: string | null;
  cnpj?: string | null;
  dataInativacao?: Date;
  thumbnail?: string;
  photoUrl?: string;
}

export enum SegmentOperation {
  VENDA = 'VENDA',
  LOCACAO = 'LOCACAO',
  AMBOS = 'AMBOS',
}

export interface IRoles {
  label: LabelRole;
  description: string;
  ability: string;
}

export enum LabelRole {
  GERENTE = 'GERENTE',
  GERENTE_PRE_ATENDIMENTO = 'GERENTE_PRE_ATENDIMENTO',
  MARKETING = 'MARKETING',
  SECRETARIA = 'SECRETARIA',
  CORRETOR = 'CORRETOR',
  CORRETOR_1 = 'CORRETOR_1',
  GERENTE_ADMINISTRATIVO = 'GERENTE_ADMINISTRATIVO',
  DIRETOR = 'DIRETOR',
  SUPERVISOR = 'SUPERVISOR',
}

export enum StatusFila {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  AUSENTE_MANUAL = 'AUSENTE_MANUAL',
  AUSENTE_AUTOMATICO = 'AUSENTE_AUTOMATICO',
  OCUPADO = 'OCUPADO',
}

export enum CreciType {
  NAO_INFORMADO = 'NAO_INFORMADO',
  ESTAGIARIO = 'ESTAGIARIO',
  CORRETOR = 'CORRETOR',
}
