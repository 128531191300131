import { Ability, AbilityBuilder } from '@casl/ability';
import isObject from 'lodash.isobject';
import { SegmentOperation } from '../../models/user';

const subjectName = (subject) =>
  !subject || typeof subject === 'string' ? subject : subject.__type;

export default (user, role) => {
  Ability.addAlias('delete', 'remove');
  Ability.addAlias('update', 'patch');
  Ability.addAlias('read', ['find', 'get']);
  if (user.roles === 'SUPER_ADMIN') {
    const ability = AbilityBuilder.define((can, cannot) => {
      can('manage', 'all');
    });
    return ability;
  }
  if (!role) return;
  const ability = [...role.ability];

  role.ability.forEach((rule) => {
    if (rule.conditions) replaceKeyWildCards(rule.conditions);
  });
  const caslAbility = new Ability(ability, { subjectName });
  caslAbility.label = role.label;
  return caslAbility;
};

const replaceKeyWildCards = (conditions) => {
  Object.entries(conditions).forEach(([key, valueCondition]) => {
    if (/\$\{.+\}/.test(key)) {
      conditions[key.replace('$', '#')] = valueCondition;
      delete conditions[key];
    }
    if (isObject(valueCondition)) {
      replaceKeyWildCards(valueCondition);
    }
  });
};

export const injectKeyWildCards = (user, subject = {}) => {
  if (typeof subject === 'object') {
    subject['#{loggedUserId}'] = user?.id;
    subject['#{teamId}'] = user?.teamId;
    subject['#{operatingSegment}'] = handleOperatingSegment(user);
  }
  return subject;
};

function handleOperatingSegment(user) {
  return user?.operatingSegment === SegmentOperation.AMBOS
    ? [SegmentOperation.VENDA, SegmentOperation.LOCACAO]
    : [user?.operatingSegment];
}

export const getTeamIdByAbility = (ability, action, subject, conditionKeys) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const keysArray = Array.isArray(conditionKeys)
    ? conditionKeys
    : [conditionKeys];
  const conditions = ability
    .rulesFor(action, subject)
    .map((rule) => rule.conditions)
    .filter(Boolean);
  const hasTeamConditions = conditions?.some((condition) =>
    keysArray.some((key) => !!condition[key]),
  );
  return {
    conditionTeamIdValue: hasTeamConditions ? user?.teamId : null,
    conditionTeamName: hasTeamConditions ? user?.teamName : null,
    hasTeamConditions,
  };
};

export const getConditionValueByAbility = (
  ability,
  action,
  subject,
  conditionKey,
) => {
  const conditions = ability
    .rulesFor(action, subject)
    .map((rule) => rule.conditions)
    .filter(Boolean);

  const conditionFound = conditions.find(
    (condition) => !!condition && condition[conditionKey] !== undefined,
  );

  const conditionValue = conditionFound ? conditionFound[conditionKey] : null;

  return conditionValue;
};

export const canFilterBySegment = (ability, action, { locacao, venda }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const canFilterByLocacao = locacao
    ? ability.can(action, injectKeyWildCards(user, { __type: locacao }))
    : false;
  const canFilterByVenda = venda
    ? ability.can(action, injectKeyWildCards(user, { __type: venda }))
    : false;
  return {
    canFilterByAmbos: canFilterByLocacao && canFilterByVenda,
    canFilterByLocacao,
    canFilterByVenda,
  };
};
