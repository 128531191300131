import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default function initializeSentry() {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENV,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
}
