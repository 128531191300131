import TextField from '@elentari/components-eve/final-form/TextField';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import * as yup from 'yup';
import { SnackBarContext } from '../../../lib/SnackBarContext';
import yupValidation from '../../../lib/yupValidation';
import { OpcoesStatusComissaoFiancaDigital } from './comissoesRepository';
import statusComissoesRepository from './statusComissoesRepository';
import { IComissoesDataArray } from './types';

interface CancelamentoComissaoDialogProps {
  comissao?: Partial<IComissoesDataArray>;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const schema = yup.object().shape({
  observacao: yup.string().nullable().required('Obrigatório'),
});

const CancelamentoComissaoDialog = ({
  comissao,
  open,
  onClose,
  onSubmit,
}: CancelamentoComissaoDialogProps) => {
  const setSnackbar = useContext(SnackBarContext);

  const handleSubmit = async (values: { observacao: string }) => {
    const response = await statusComissoesRepository.create({
      comissaoFiancaDigitalId: comissao?.id,
      tenantId: comissao?.tenantId,
      observacao: values.observacao,
      status: OpcoesStatusComissaoFiancaDigital.CANCELADO,
    });
    if (response.ok) {
      setSnackbar({
        open: true,
        state: {
          message: 'Comissão cancelada.',
        },
      });
      onSubmit();
    } else {
      setSnackbar({
        open: true,
        state: {
          severity: 'error',
          message: 'Houve um erro ao cancelar comissão.',
        },
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} transitionDuration={{ enter: 100, exit: 100 }}>
      <DialogTitle>Você está cancelando uma comissão de Fiança Digital.</DialogTitle>
      <Form
        validate={yupValidation(schema)}
        onSubmit={handleSubmit}
        render={({ handleSubmit, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ overflow: 'hidden' }}>
                <Grid container direction='column' spacing={2}>
                  <Grid item xs={12}>
                    <DialogContentText>
                      Para continuar, informe o motivo do cancelamento:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name='observacao'
                      label='Motivo do cancelamento'
                      fullWidth
                      multiline
                      rows={5}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color='primary'>
                  Voltar
                </Button>
                <Button onClick={() => form.submit()} color='primary' autoFocus>
                  Confirmar
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default CancelamentoComissaoDialog;
