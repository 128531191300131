import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",

    "&:checked + span": {
      borderColor: theme.palette.primary.main,

      "& span": {
        opacity: 1,
        transform: "scale(1)",
        transition: "transform .3s ease-out, opacity .3s ease-out",
      },
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: "20px",
    height: "20px",
    border: "2px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    transition: "border-color .4s ease-out",
    cursor: "pointer",

    "& span": {
      display: "block",
      width: "16px",
      height: "16px",
      borderRadius: "3px",
      background: theme.palette.primary.main,

      opacity: 0,
      transform: "scale(0.6)",
      transition: "transform .3s ease-out, opacity .3s ease-out",
    },

    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.2)",
    },
  },
}));
