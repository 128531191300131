export const tiposRoletas = [
  { label: 'Pré-atendimento - Geral', value: 'PRE_ATENDIMENTO' },
  {
    label: 'Pré-atendimento - Produto Específico',
    value: 'PRE_ATENDIMENTO_PRODUTO_ESPECIFICO',
  },
  { label: 'Pré-atendimento - Tag', value: 'PRE_ATENDIMENTO_TAG' },
  { label: 'Geral', value: 'GERAL' },
  { label: 'Produto Específico', value: 'PRODUTO_ESPECIFICO' },
  { label: 'Tag', value: 'TAG' },
  { label: "Flip 'n go", value: 'FLIP_AND_GO' },
];

export enum TipoRoletaEnum {
  GERAL = 'GERAL',
  PRE_ATENDIMENTO = 'PRE_ATENDIMENTO',
  PRODUTO_ESPECIFICO = 'PRODUTO_ESPECIFICO',
  TAG = 'TAG',
  FLIP_AND_GO = 'FLIP_AND_GO',
}

export const pretensaoRoletas = [
  { label: 'Venda', value: 'VENDA' },
  { label: 'Locação', value: 'LOCACAO' },
  { label: 'Ambos', value: 'AMBOS' },
];

export const status = [
  { label: 'Online', value: 'ONLINE' },
  { label: 'Offline', value: 'OFFLINE' },
  { label: 'Ocupado', value: 'OCUPADO' },
  { label: 'Ausente (Manual)', value: 'AUSENTE_MANUAL' },
  { label: 'Ausente (Automático)', value: 'AUSENTE_AUTOMATICO' },
];

export const acessoRoleta = [
  { label: 'Todos', value: 'TODOS' },
  { label: 'Personalizado', value: 'PERSONALIZADO' },
];
