import React from 'react';
import { RecoverPassword } from 'elentari';
import api from '../../services/api';

export const handleSubmit = (recoverPassword) => async ({ username }) => {
  const response = await recoverPassword({
    action: 'resetSend',
    username,
  });
  if (response.ok) {
    return Promise.resolve({ ok: true, message: 'E-mail enviado, verifique sua caixa de entrada' });
  }
  return { ok: false, message: 'E-mail inválido' };
};

export default function CustomRecoverPassword({ history }) {
  return (
    <RecoverPassword
      history={history}
      usernameLabel='E-mail'
      backLabel='Voltar'
      submitLabel='Recuperar'
      onBackClick={() => history.goBack()}
      onSubmit={handleSubmit(api.resetPassword)}
    />
  );
}
