import { Collapse, Fade, Grow, Zoom } from '@material-ui/core';
import React from 'react';
import Field from './Field';

interface ConditionProps {
  field: string;
  verify: (value: any) => boolean;
  children: any;
  transition?: 'grow' | 'fade' | 'zoom' | 'collapse';
  transitionProps?: any;
}

const Condition = ({
  field,
  verify,
  children,
  transition,
  transitionProps,
}: ConditionProps) => (
  <Field name={field} subscription={{ value: true }}>
    {({ input: { value } }: any) => {
      switch (transition) {
        case 'grow':
          return (
            <Grow in={verify(value)} {...transitionProps}>
              {children}
            </Grow>
          );
        case 'fade':
          return (
            <Fade in={verify(value)} {...transitionProps}>
              {children}
            </Fade>
          );
        case 'zoom':
          return (
            <Zoom in={verify(value)} {...transitionProps}>
              {children}
            </Zoom>
          );
        case 'collapse':
          return (
            <Collapse in={verify(value)} {...transitionProps}>
              {children}
            </Collapse>
          );
        default:
          return verify(value) ? children : null;
      }
    }}
  </Field>
);

export default Condition;
