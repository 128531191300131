/* eslint-disable react/no-children-prop */
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import DialogModal from '../properties/modals/DialogModal';
import yupValidation from '../../lib/yupValidation';
import Field from '../../Components/Field';
import AsyncPropBasedAutoComplete from '../../Components/AsyncPropBasedAutocomplete';
import { EnderecoFetcher } from '../../utils/address-utils';
import bairrosRepository from '../bairros/BairrosRepository';
import { restApi } from '../../services/api';
import { SnackBarContext } from '../../lib/SnackBarContext';
import { OnChange } from 'react-final-form-listeners';
import ConfirmModal from '../../Components/ConfirmDialog';

const novoBairro = yup.object().shape({
  paisId: yup.number().typeError('Selecione uma opção').required('Obrigatório'),
  estadoId: yup
    .number()
    .typeError('Selecione uma opção')
    .required('Obrigatório'),
  cidadeId: yup
    .number()
    .typeError('Selecione uma opção')
    .required('Obrigatório'),
  bairroId: yup.mixed().test('bairroId', 'Obrigatório', function (value) {
    const { parent } = this;
    const neighborhood = parent.neighborhood;

    if (value) return true;

    if (
      neighborhood === undefined ||
      neighborhood === null ||
      neighborhood === ''
    ) {
      return false;
    }

    return true;
  }),
});

const BairrosDialog = ({ open, handleClose, bairroId }) => {
  const [submitting, setSubmitting] = useState(false);

  const [neighborhoodState, setNeighborhoodState] = useState(null);
  const [city, setCity] = useState(null);
  const [neighborhood, setNeighborhood] = useState(null);
  const enderecoFetcher = new EnderecoFetcher();
  const [bairro, setBairro] = useState();
  const setSnackbar = useContext(SnackBarContext);
  const [confirmEditDialog, setConfirmEditDialog] = useState({
    open: false,
    payload: null,
  });
  const [paisState, setPaisState] = useState({
    nome: 'Brasil',
    id: null,
  });

  useEffect(() => {
    (async () => {
      await loadPais();
      if (bairroId) {
        const response = await restApi.get(
          `/gerenciamento-bairros/${bairroId}`,
        );

        if (response.ok && response.data) {
          setBairro(response.data);
          setNeighborhoodState(
            response.data.estadoId
              ? {
                  id: response.data.estadoId,
                  nomeEstado: response.data.nomeEstado,
                }
              : null,
          );
          setCity(
            response.data.cidadeId
              ? {
                  id: response.data.cidadeId,
                  nomeCidade: response.data.nomeCidade,
                }
              : null,
          );
          setNeighborhood(
            response.data.bairroId
              ? {
                  id: response.data.bairroId,
                  nomeBairro: response.data.nomeBairro,
                }
              : null,
          );
        }
      } else {
        setNeighborhoodState(null);
        setCity(null);
        setNeighborhood(null);
      }
    })();
  }, [bairroId]);

  const loadPais = async () => {
    const paisList = await enderecoFetcher.getPaisListByTerm(paisState.nome);

    setPaisState({
      nome: paisState.nome,
      id: paisList[0]?.id,
    });
  };

  const loadStates = async (stateTerm) => {
    const estadosResponse = await enderecoFetcher.getEstadoListByPaisId(
      paisState.id,
      stateTerm,
    );
    return estadosResponse;
  };

  const loadCities = async (city) => {
    if (!neighborhoodState) return [];
    const citiesResponse = await enderecoFetcher.getCidadeListByEstadoId(
      neighborhoodState.id,
      city,
    );
    return citiesResponse;
  };

  const loadNeighborhoods = async (neighborhood) => {
    if (!city) return [];
    const neighborhoodsResponse = await enderecoFetcher.getBairroListByCidadeId(
      city.id,
      neighborhood,
    );

    return neighborhoodsResponse;
  };

  const handleCloseDeleteDialog = () => {
    setConfirmEditDialog({
      open: false,
      payload: confirmEditDialog.payload?.quantidadeEnderecos,
    });
  };

  const handleEditNeigborhood = async (bairroId, payload) => {
    if (payload.nomeBairro?.id)
      payload.nomeBairro = payload.nomeBairro.nomeBairro;

    const response = await bairrosRepository.update({
      id: bairroId,
      ...payload,
    });
    if (response.ok) {
      setBairro(null);
      setSnackbar({
        open: true,
        state: {
          message: 'Bairro atualizado com sucesso.',
        },
      });
      handleClose();
    } else {
      setSnackbar({
        open: true,
        state: {
          message: response.data.message
            ? response.data.message
            : 'Erro ao atualizar bairro.',
          severity: 'error',
        },
      });
    }
  };

  const handleSubmit = async (values) => {
    setSubmitting(true);

    let response;

    const payload = {
      ...values,
      nomeBairro:
        typeof values.bairroId === 'string' ? values.bairroId : neighborhood,
    };

    if (!bairro) {
      response = await bairrosRepository.create(payload);
      if (response.ok) {
        setBairro(null);
        setSnackbar({
          open: true,
          state: {
            message: 'Bairro cadastrado com sucesso.',
          },
        });
        handleClose();
      } else {
        setSnackbar({
          open: true,
          state: {
            message: response.data.message
              ? response.data.message
              : 'Erro ao cadastrar bairro.',
            severity: 'error',
          },
        });
      }
    } else if (payload.quantidadeEnderecos > 0) {
      setConfirmEditDialog({
        open: true,
        payload,
      });
    } else {
      await handleEditNeigborhood(bairroId, payload);
    }

    setSubmitting(false);
  };

  return (
    <DialogModal
      open={open}
      handleClose={() => {
        setBairro(null);
        handleClose();
      }}
      fullWidth
      transitionDuration={{ enter: 100, exit: 100 }}
      form={{
        handleSubmit: (formValue) => {
          return handleSubmit({
            ...formValue,
          });
        },
        validate: yupValidation(novoBairro),
        initialValues: bairroId
          ? bairro
          : {
              paisId: 1,
            },
      }}
      submitLabel={submitting ? 'Salvando...' : 'Salvar'}
      submitDisabled={submitting}
      title={bairroId ? 'Editar bairro' : 'Cadastro de Bairro'}
      children={({ form }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Field
                label="País"
                name="paisId"
                disabled
                component={AsyncPropBasedAutoComplete}
                initialOptions={[
                  { id: paisState.id, nomePais: paisState.nome },
                ]}
                propLabel="nomePais"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                label="Estado"
                name="estadoId"
                propLabel="nomeEstado"
                component={AsyncPropBasedAutoComplete}
                initialOptions={
                  bairroId && neighborhoodState ? [neighborhoodState] : []
                }
                loadOptions={loadStates}
                onChange={(value) => setNeighborhoodState(value)}
                disabled={!!bairroId}
              />
              <OnChange name="estadoId">
                {() => {
                  if (form.getFieldState('estadoId').modified) {
                    form.change('cidadeId', null);
                    form.change('bairroId', null);
                  }
                }}
              </OnChange>
            </Grid>

            <Grid item xs={12}>
              <Field
                fullWidth
                label="Cidade"
                name="cidadeId"
                propLabel="nomeCidade"
                component={AsyncPropBasedAutoComplete}
                initialOptions={bairroId && city ? [city] : []}
                loadOptions={loadCities}
                onChange={(value) => setCity(value)}
                disabled={!form.getFieldState('estadoId')?.value}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                freeSolo
                autoSelect
                label="Bairro"
                name="bairroId"
                propLabel="nomeBairro"
                component={AsyncPropBasedAutoComplete}
                loadOptions={loadNeighborhoods}
                onChange={(value) => {
                  setNeighborhood(value?.nomeBairro || value);
                  form.change('neighborhood', value?.nomeBairro || value);
                  form.change('bairroId', value?.bairroId || value);
                }}
                initialOptions={bairroId && neighborhood ? [neighborhood] : []}
                disabled={!form.getFieldState('cidadeId')?.value}
              />
            </Grid>
          </Grid>
          <ConfirmModal
            open={confirmEditDialog.open}
            onDeny={handleCloseDeleteDialog}
            onAccept={async () => {
              await handleEditNeigborhood(bairroId, confirmEditDialog.payload);
              handleCloseDeleteDialog();
            }}
            onClose={handleCloseDeleteDialog}
            acceptLabel="Sim"
            denyLabel="Não"
            content={`Serão alterados ${confirmEditDialog.payload?.quantidadeEnderecos} registro(s), deseja continuar?`}
            title="Deseja editar o bairro selecionado?"
          />
        </>
      )}
    />
  );
};

export default BairrosDialog;
