import Filter from '@elentari/components-eve/components/Filter';
import TextField from '@elentari/components-eve/final-form/TextField';
import { InputAdornment, makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useFilter } from 'elentari';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React from 'react';
import { BsSearch } from 'react-icons/bs';
import FilterActions from '../../Components/FilterActions';

const useStyles = makeStyles((theme) => ({
  total: {
    fontSize: 14,
    letterSpacing: 0.225,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  select: {
    '& .MuiOutlinedInput-input': {
      padding: `10.8px 14px`,
    },
  },
  selectLabel: {
    color: theme.palette.primary.main,
    lineHeight: 0.3,
  },
  right: {
    marginLeft: 'auto',
  },
  title: {
    letterSpacing: 0.225,
    color: theme.palette.primary.main,
    fontWeight: 'bolder',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const BairrosFilter = ({ total }: { total: number }) => {
  const classes = useStyles();
  const filter = useFilter({});

  const onSubmit = (values: any) => {
    const nonNullValues = pickBy(values, identity);
    return filter.onSubmit(nonNullValues);
  };

  const onClear = () => filter.onClear();

  return (
    <Filter {...filter} onSubmit={onSubmit} disableActions={true}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            name="$term"
            fullWidth
            size="small"
            placeholder="Cidade ou bairro"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography color="primary" className={classes.total}>
            Total de Bairros: {total}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={4}
          className={classes.right}
          direction="column"
          justify="center"
        >
          <FilterActions onClear={onClear} />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default BairrosFilter;
