import { createBasicRepository as createRepository } from 'elentari';
import { paginate, softDeleteStrategy } from '../../lib/feathers';

import { restApi } from '../../services/api';

const props = {
  path: 'gerenciamento-bairros',
  restApi,
  softDeleteStrategy,
  queryTransform: {
    ...paginate,
  },
};

export default createRepository(props);
