export interface Paginated<T> {
  $limit: number;
  $skip: number;
  data: T[];
  total: number;
}

export type PageFetcher<T> = ($skip: number, $limit: number) => Promise<Paginated<T>>;

export function makePaginatedFetcher<T>(fetchPage: PageFetcher<T>) {
  return async function* (pageSize: number) {
    let done = false;
    let skip = 0;

    while (!done) {
      const page: any = await fetchPage(skip, pageSize);
      const pageData: T[] = Array.isArray(page.data) ? page.data : page.data?.data || [];

      skip += pageData.length;

      if (skip >= page.total || pageData.length === 0) {
        done = true;
      }

      yield page;
    }
  };
}

export default makePaginatedFetcher;
