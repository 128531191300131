import Section from '@elentari/components-eve/components/Section';
import TextField from '@elentari/components-eve/final-form/TextField';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { FieldRenderProps, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import AsyncPropBasedAutoComplete from '../../../Components/AsyncPropBasedAutocomplete';
import Field from '../../../Components/Field';
import RegisterFields from '../../../Components/FormFields/RegisterField';
import {
  IBairroWithTenant,
  ICidade,
  IEstado,
  IPais,
} from '../../../models/adresses';
import { EnderecoFetcher } from '../../../utils/address-utils';
import { useAddress } from '../../addresses/useAddress';

const BairroOrigemFields = () => {
  const enderecoFetcher = new EnderecoFetcher();
  const form = useForm();
  const { initialOptions } = useAddress(enderecoFetcher, 'bairroOrigem');
  const [tenant, setTenant] = useState('');

  return (
    <Section label="Bairro de origem">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Field
            fullWidth
            name="bairroOrigem.paisId"
            label="País"
            component={AsyncPropBasedAutoComplete}
            loadOptions={(input: string) =>
              enderecoFetcher.getPaisListByTerm(input)
            }
            onChange={(option: IPais) =>
              form.change('bairroDestino.pais', option?.nomePais)
            }
            propLabel="nomePais"
            initialOptions={initialOptions?.pais}
          />
          <OnChange name="bairroOrigem.paisId">
            {(paisId) => {
              !paisId && form.change('bairroDestino.pais', null);
              form.change('bairroOrigem.estadoId', null);
              form.change('idBairroOrigem', null);
              form.change('idBairroDestino', null);
            }}
          </OnChange>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="bairroOrigem.paisId">
            {({ input: { value: paisId } }: any) => (
              <>
                <Field
                  fullWidth
                  name="bairroOrigem.estadoId"
                  label="Estado"
                  component={AsyncPropBasedAutoComplete}
                  ignoreFilterOptions
                  loadOptions={(input: string) =>
                    enderecoFetcher.getEstadoListByPaisId(paisId, input)
                  }
                  onChange={(option: IEstado) =>
                    form.change('bairroDestino.estado', option?.nomeEstado)
                  }
                  propLabel="nomeEstado"
                  initialOptions={initialOptions.estado}
                  disabled={!paisId}
                />
                <OnChange name="bairroOrigem.estadoId">
                  {(estadoId) => {
                    !estadoId && form.change('bairroDestino.estado', null);
                    form.change('bairroOrigem.cidadeId', null);
                    form.change('idBairroOrigem', null);
                    form.change('idBairroDestino', null);
                  }}
                </OnChange>
              </>
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field name="bairroOrigem.estadoId">
            {({ input: { value: estadoId } }: FieldRenderProps<number>) => (
              <>
                <Field
                  fullWidth
                  name="bairroOrigem.cidadeId"
                  label="Cidade"
                  component={AsyncPropBasedAutoComplete}
                  loadOptions={(input: string) =>
                    enderecoFetcher.getCidadeListByEstadoId(estadoId, input)
                  }
                  onChange={(option: ICidade) =>
                    form.change('bairroDestino.cidade', option?.nomeCidade)
                  }
                  propLabel="nomeCidade"
                  disabled={!estadoId}
                  initialOptions={initialOptions.cidade}
                />
                <OnChange name="bairroOrigem.cidadeId">
                  {(cidadeId) => {
                    !cidadeId && form.change('bairroDestino.cidade', null);
                    form.change('idBairroOrigem', null);
                    form.change('idBairroDestino', null);
                  }}
                </OnChange>
              </>
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field name="bairroOrigem.cidadeId">
            {({ input: { value: cidadeId } }: any) => (
              <>
                <Field
                  fullWidth
                  label="Bairro de origem"
                  name="idBairroOrigem"
                  component={AsyncPropBasedAutoComplete}
                  loadOptions={(input: string) =>
                    enderecoFetcher.getBairroListByCidadeId(cidadeId, input)
                  }
                  onChange={(bairro: IBairroWithTenant) => {
                    if (bairro && bairro.tenant) {
                      setTenant(bairro.tenant.name);
                    } else {
                      setTenant('');
                    }
                    form.change('tenantIdOrigem', bairro?.tenantId);
                  }}
                  propLabel="nomeBairro"
                  initialOptions={initialOptions?.bairro}
                  disabled={!cidadeId}
                />
                <OnChange name="idBairroOrigem">
                  {(idBairroOrigem) => {
                    !idBairroOrigem && form.change('idBairroDestino', null);
                  }}
                </OnChange>
              </>
            )}
          </Field>
          <RegisterFields names={['tenantIdOrigem']} />
        </Grid>
        {tenant !== '' && (
          <Grid item xs={12}>
            <TextField
              size="small"
              value={tenant}
              fullWidth
              name={'imobiliaria'}
              label={'Imobiliária'}
              disabled
            />
          </Grid>
        )}
      </Grid>
    </Section>
  );
};

export default BairroOrigemFields;
