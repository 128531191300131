import Select from '@elentari/components-eve/final-form/Select';
import { Grid, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { AuthContext } from 'elentari';
import { FormApi } from 'final-form';
import { default as React, useCallback, useContext, useEffect, useState } from 'react';
import { OnChange } from 'react-final-form-listeners';
import Condition from '../../../Components/Condition';
import Field from '../../../Components/Field';
import SwitchField from '../../../Components/SwitchField';
import Tooltip from '../../../Components/Tooltip';
import { IAuthContext } from '../../../models/authContext';
import roletasRepository from '../../roletas/roletasRepository';
import { usersSemPaginacaoRepository } from '../../users/usersRepository';
import { sortLabelsAlphabetically } from '../utils';
import { UserAutoCompleteAdapter } from './UserAutoCompleteAdapter';
import { IRoleta } from '../../../models/roletas';

interface TransferirEmMassaFormProps {
  form: FormApi;
  total: number;
  setRoletas: React.Dispatch<React.SetStateAction<any[]>>;
  roletas: IRoleta[];
}

const TransferirEmMassaForm = ({
  form,
  total,
  setRoletas,
  roletas,
}: TransferirEmMassaFormProps) => {
  const context = useContext<IAuthContext>(AuthContext);
  const [empreendimentosRoleta, setEmpreendimentosRoleta] = useState([]);
  const [tagsRoleta, setTagsRoleta] = useState([]);
  const [tiposRoletas, setTiposRoletas] = useState([]);
  const [pretensoesRoletas, setPretensoesRoletas] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const roletasFlipAndGo = roletas.filter((roleta) => roleta.tipo === 'FLIP_AND_GO');

  const loadUserOptions = async () => {
    const response = await usersSemPaginacaoRepository.list({
      query: { limit: -1, active: true, sort: 'name' },
    });
    setUserOptions(response.ok ? response.data : []);
  };

  useEffect(() => {
    loadUserOptions();
  }, []);

  const loadRoletas = useCallback(async () => {
    if (tiposRoletas.length > 0) {
      return;
    }

    const {
      tipos,
      tags,
      empreendimentos,
      pretensoes,
      roletas,
    } = await roletasRepository.listTiposRoletasELancamentosAtivos();
    setEmpreendimentosRoleta(sortLabelsAlphabetically(empreendimentos));
    setTagsRoleta(sortLabelsAlphabetically(tags));
    setTiposRoletas(tipos);
    setPretensoesRoletas(pretensoes);
    setRoletas(sortLabelsAlphabetically(roletas));
  }, [tiposRoletas]);

  useEffect(() => {
    loadRoletas();
  }, [loadRoletas]);

  return (
    <Grid container spacing={2}>
      {total && (
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Typography display='inline'>{`${total} clientes selecionados`}</Typography>
          <Tooltip
            placement='right'
            title='Clientes inativados permanentemente ("Negócio fechado" ou "Não perturbe") serão ignorados'
          >
            <Info htmlColor='#7E51DF' />
          </Tooltip>
        </Grid>
      )}
      <Grid item xs={12}>
        <Field
          component={SwitchField as any}
          name='possuiResponsavel'
          label='Possui Responsável ?'
          type='checkbox'
        />
      </Grid>
      <OnChange name='possuiResponsavel'>
        {(possuiResponsavel) => {
          if (possuiResponsavel) {
            form.change('tipoRoleta', null);
            form.change('pretensao', null);
            form.change('produtoId', null);
            form.change('tagId', null);
            form.change('roletaId', null);
            form.change('novoResponsavel', +context?.user.id);
          } else {
            form.change('novoResponsavel', null);
          }
        }}
      </OnChange>

      <Condition field='possuiResponsavel' verify={(value) => value === true}>
        <Grid item xs={12}>
          <Field
            fullWidth
            label='Novo Responsável'
            name='novoResponsavel'
            component={UserAutoCompleteAdapter}
            getOptionLabel={(option: { id: number; name: string }) => option.name}
            parserOption={(option: { id: number; name: string }) => option.id}
            options={userOptions}
            size='normal'
          />
        </Grid>
      </Condition>

      <Condition field='possuiResponsavel' verify={(value) => value === false}>
        <Grid item sm={6}>
          <Select label='Tipo da roleta *' fullWidth name='tipoRoleta' items={tiposRoletas} />
        </Grid>

        <Grid item sm={6}>
          <Select label='Pretensão *' fullWidth name='pretensao' items={pretensoesRoletas} />
        </Grid>

        <Condition
          field="tipoRoleta"
          verify={(value) =>
            [
              'PRODUTO_ESPECIFICO',
              'PRE_ATENDIMENTO_PRODUTO_ESPECIFICO',
            ].includes(value)
          }
        >
          <Grid item sm={12}>
            <Select label='Produto *' fullWidth name='produtoId' items={empreendimentosRoleta} />
          </Grid>
        </Condition>

        <Condition
          field="tipoRoleta"
          verify={(value) => ['TAG', 'PRE_ATENDIMENTO_TAG'].includes(value)}
        >
          <Grid item sm={12}>
            <Select label='Tag *' fullWidth name='tagId' items={tagsRoleta} />
          </Grid>
        </Condition>

        <Condition field='tipoRoleta' verify={(value) => value === 'FLIP_AND_GO'}>
          <Grid item sm={12}>
            <Select label='Roleta *' fullWidth name='roletaId' items={roletasFlipAndGo as any} />
          </Grid>
        </Condition>
      </Condition>
    </Grid>
  );
};

export default TransferirEmMassaForm;
