import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CloseIcon from '@material-ui/icons/Close';
import { FormApi } from 'final-form';
import React, { useContext, useEffect, useState } from 'react';
import { FieldRenderProps, Form } from 'react-final-form';
import ConfirmModal from '../../../Components/ConfirmDialog';
import Field from '../../../Components/Field';
import { SnackBarContext } from '../../../lib/SnackBarContext';
import BairroDestinoFields from './BairroDestinoFields';
import BairroOrigemFields from './BairroOrigemFields';
import { useStyles } from './styles';
import { FormValues, formatMessage, getBairro } from './utils';

interface UnificarBairrosDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (values: FormValues) => void;
  setSnackbar: any;
}

interface ContentProps {
  handleSubmit: (values: FormValues) => void;
  handleClose: () => void;
  isMobile: boolean;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const Content = ({
  handleClose,
  handleSubmit: onSubmit,
  isMobile,
  setMessage,
}: ContentProps) => {
  const classes = useStyles();
  const [confirmModal, setConfirmModal] = useState<{
    open: boolean;
    quantidadeRegistrosAlterados?: number;
  }>({ open: false });

  const handleClickUnificar = async (
    form: FormApi<FormValues, Partial<FormValues>>,
  ) => {
    const tenantIdDestino = form.getFieldState('tenantIdDestino')
      ?.value as number;
    const tenantIdOrigem = form.getFieldState('tenantIdOrigem')
      ?.value as number;

    if (tenantIdDestino !== tenantIdOrigem) {
      const message =
        tenantIdDestino === null || tenantIdOrigem === null
          ? 'Não é possível unificar um bairro associado a uma imobiliária com um bairro não associado'
          : 'Não é possível unificar bairros de diferentes imobiliárias';
      setMessage(message);
      return;
    }

    const idBairroOrigem = form.getFieldState('idBairroOrigem')
      ?.value as number;

    const bairroOrigem = await getBairro(idBairroOrigem);

    if (!bairroOrigem) {
      setMessage('Erro ao buscar bairros');
      return;
    }

    setConfirmModal({
      open: true,
      quantidadeRegistrosAlterados: bairroOrigem.quantidadeEnderecos,
    });
  };

  const handleCloseConfirm = () => setConfirmModal({ open: false });

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ country: 'Brasil' }}
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit, form }) => (
        <>
          <form onSubmit={handleSubmit}>
            <DialogTitle style={{ textAlign: 'center', padding: '10px 24px' }}>
              <span>Unificar bairro</span>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction={isMobile ? 'column' : 'row'}
              >
                <Grid item xs={12} className={classes.bairroForm}>
                  <BairroOrigemFields />
                </Grid>
                <Grid item xs={12} className={classes.icon}>
                  {isMobile ? (
                    <ArrowDownwardIcon color="primary" />
                  ) : (
                    <ArrowRightAltIcon color="primary" fontSize="large" />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.bairroForm}>
                  <BairroDestinoFields />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Field name="idBairroDestino">
                {({
                  input: { value: idBairroDestino },
                }: FieldRenderProps<number>) => (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleClickUnificar(form)}
                    disabled={!idBairroDestino}
                  >
                    Unificar
                  </Button>
                )}
              </Field>
            </DialogActions>
          </form>
          <ConfirmModal
            title={'Deseja continuar?'}
            content={formatMessage(
              confirmModal?.quantidadeRegistrosAlterados as number,
            )}
            open={confirmModal.open}
            onDeny={handleCloseConfirm}
            onAccept={handleSubmit}
            onClose={handleCloseConfirm}
            acceptLabel="Sim"
            denyLabel="Não"
          />
        </>
      )}
    />
  );
};

const UnificarBairrosDialog = ({
  open,
  handleClose,
  handleSubmit,
  setSnackbar,
}: UnificarBairrosDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (message !== '') {
      setSnackbar({
        open: true,
        state: {
          message,
          severity: 'error',
        },
      });
      setMessage('');
    }
  }, [message, setSnackbar]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      classes={{ paper: classes.dialogPaper }}
    >
      <Content
        handleClose={handleClose}
        isMobile={isMobile}
        handleSubmit={handleSubmit}
        setMessage={setMessage}
      />
    </Dialog>
  );
};

export default UnificarBairrosDialog;
