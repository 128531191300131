import { Fab, Zoom } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import React, { useState } from 'react';
import Tooltip from '../../../Components/Tooltip';
import { IComissoesDataArray } from '../comissoes/types';
import CadastroPagamentoDialog from './CadastroPagamentoDialog';

interface CadastroPagamentoFabProps {
  comissoes: IComissoesDataArray[];
  onSubmit?: () => void;
}

const CadastroPagamentoFab = ({ comissoes = [], onSubmit }: CadastroPagamentoFabProps) => {
  const [openCadastroPagamento, setOpenCadastroPagamento] = useState(false);

  const handleCloseCadastroPagamento = () => {
    setOpenCadastroPagamento(false);
  };

  const handleSubmitCadastroPagamento = () => {
    onSubmit && onSubmit();
    setOpenCadastroPagamento(false);
  };

  const handleClickCadastroPagamento = () => {
    setOpenCadastroPagamento(true);
  };
  return (
    <>
      <Zoom in={!!comissoes.length}>
        <Tooltip title='Cadastrar pagamento'>
          <Fab color='primary' onClick={handleClickCadastroPagamento}>
            <AttachMoneyIcon />
          </Fab>
        </Tooltip>
      </Zoom>
      <CadastroPagamentoDialog
        open={openCadastroPagamento}
        handleClose={handleCloseCadastroPagamento}
        comissoes={comissoes}
        onSubmit={handleSubmitCadastroPagamento}
      />
    </>
  );
};

export default CadastroPagamentoFab;
