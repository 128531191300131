import React from 'react';
import { useForm } from 'react-final-form';
import * as FA from './FormFilter/ExpansiveFilter/FilterActions';

const FilterActions = ({ onClear }) => {
  const form = useForm();

  const handleClear = (event) => {
    form.reset();
    onClear(event);
  };

  return <FA.default onClear={() => handleClear()} />;
};

export default FilterActions;
