import firebase from 'firebase';
import api from './services/api';

export const askForPermissioToReceiveNotifications = () => {
  try {
    const messaging = firebase.messaging();
    Notification.requestPermission()
      .then(() => {
        messaging
          .getToken()
          .then((tokenFcm) => {
            sessionStorage.tokenFcm = tokenFcm;
            api.setFcmToken(tokenFcm);
          })
          .catch((err) => console.error(err));
      })
      .catch(() => null);
  } catch (e) {
    console.log(e);
  }
};
