import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormatCustom from './NumberFormatCustom';
import TextField from '@elentari/components-eve/components/TextField';

const PorcentagemTextField = ({ input, meta, ...props }) => {
  return (
    <TextField
      {...props}
      {...input}
      error={meta?.touched && !!meta?.error}
      helperText={meta?.touched && meta?.error}
      InputProps={{
        ...props.InputProps,
        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
        inputComponent: PorcentagemFormat,
      }}
    />
  );
};

const PorcentagemFormat = ({ inputRef, onChange, ...rest }) => {
  return <NumberFormatCustom inputRef={inputRef} onChange={onChange} {...rest} max={100} />;
};

export default PorcentagemTextField;
