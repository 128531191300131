import TextField from '@elentari/components-eve/final-form/TextField';
import { IconButton, InputAdornment } from '@material-ui/core';
import { VisibilityOff } from '@material-ui/icons';
import Visibility from '@material-ui/icons/Visibility';
import React, { useState } from 'react';

export const Password = ({ InputProps = {}, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      name="password"
      label="Senha"
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        ...(InputProps ?? {}),
      }}
      {...props}
    />
  );
};

export default Password;
