import Dialog from '@elentari/components-eve/final-form/Dialog';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
    width: '90%',
    overflowY: 'scroll',
    '& .MuiDialogContent-root': {
      overflowY: 'visible',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
      margin: theme.spacing(2),
    },
  },
  fullScreenPaper: {
    padding: theme.spacing(1),
    scrollbarWidth: 'none',
    '& .MuiDialogContent-root:first-child': {
      scrollbarWidth: 'none',
      paddingTop: 0,
    },
    '& .MuiDialogTitle-root': {
      paddingBottom: 5,
      maxWidth: '90vw',
    },
    '& button.MuiButtonBase-root.MuiIconButton-root': {
      top: 8,
      right: 8,
    },
  },
}));

const DialogModal = ({ children, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Dialog
      fullScreen={isMobile}
      classes={{
        paper: isMobile ? classes.fullScreenPaper : classes.paper,
      }}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export default DialogModal;
