export default function addUploadCapabilities(repository) {
  const _create = repository.create;
  repository.create = (data, options) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, data[key]);
      }
    });
    return _create(formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  const _update = repository.update;
  repository.update = (data, options) => {
    const formData = new FormData();
    formData.id = data.id;
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, data[key]);
      }
    });
    return _update(formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
}
