import { ApiResponse } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { emitTokenRefreshedEvent } from '../../events/token-refreshed-event-emitter';
import * as jwt from '../../utils/jwt';

export class PeriodicalAccessTokenRefresher {
  private serverToClientDateDiff?: number;

  constructor(private api: any, private refreshInterval: number) {
    this.monitorServerDate = this.monitorServerDate.bind(this);
    this.transformAccessToken = this.transformAccessToken.bind(this);
  }

  monitorServerDate(response: ApiResponse<unknown>) {
    const headers: Record<string, string> = response.headers || {};
    if (headers.date) {
      this.serverToClientDateDiff = new Date(headers.date).getTime() - new Date().getTime();
    }
  }

  async transformAccessToken(request: AxiosRequestConfig) {
    if (request.url?.includes('authentication')) {
      return;
    }
    const accessToken = this.getAccessToken(request);
    const refreshToken = this.getRefreshToken();
    if (refreshToken && this.shouldRefreshAccessToken(accessToken)) {
      await this.refreshAccessToken(refreshToken);
      this.setNewTokenOnRequest(request);
      emitTokenRefreshedEvent(refreshToken);
    }
  }

  private get currentServerDate() {
    if (this.serverToClientDateDiff !== undefined) {
      return new Date(new Date().getTime() + this.serverToClientDateDiff);
    }
    return undefined;
  }

  private getAccessToken(request: AxiosRequestConfig) {
    return request.headers['Authorization']?.replace(/bearer\s*/i, '');
  }

  private getRefreshToken() {
    const user = this.getUserFromLocalStorage();
    return user?.refreshToken;
  }

  private shouldRefreshAccessToken(token: string) {
    const issuedAt = moment(jwt.issuedAt(token));
    const refreshTime = moment(issuedAt).add(this.refreshInterval, 'minute');
    const now = moment(this.currentServerDate);
    return this.isTokenExpired(token) || now.isAfter(refreshTime);
  }

  private isTokenExpired(token: string) {
    const now = moment(this.currentServerDate);
    const expiresAt = moment(jwt.expiresAt(token));
    return now.isAfter(expiresAt);
  }

  private async refreshAccessToken(refreshToken: string) {
    if (!this.isTokenExpired(refreshToken)) {
      const response = await this.api.refreshToken(refreshToken);
      if (response.ok) {
        this.saveNewTokensInLocalStorage(response);
        this.api.setToken(response.data.accessToken);
      }
    }
  }

  private saveNewTokensInLocalStorage(response: any) {
    const user = this.getUserFromLocalStorage();
    if (user) {
      user.token = response.data.accessToken;
      user.refreshToken = response.data.refreshToken;
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  private getUserFromLocalStorage() {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
  }

  private setNewTokenOnRequest(request: AxiosRequestConfig) {
    const user = this.getUserFromLocalStorage();
    if (user) {
      request.headers['Authorization'] = `Bearer ${user.token}`;
    }
  }
}
