import React from 'react';
import FormGroup from '@material-ui/core/FormControl';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel } from '@material-ui/core';
import CheckboxSimple from '@elentari/components-eve/final-form/CheckboxSimple';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: `solid 1px ${grey['400']}`,
    borderRadius: 28,
  },
  label: {
    position: 'absolute',
    left: '7%',
    bottom: '80%',
    paddingRight: 3,
    paddingLeft: 3,
    letterSpacing: '1.5px',
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    flexDirection: 'row',
    paddingTop: '6px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '16px',
    },
  },
}));

const CheckboxGroup = ({ options = [], label }) => {
  const classes = useStyles();
  return (
    <FormGroup className={classes.root}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <div className={classes.fields}>
        {options.map((option, index) => {
          return (
            <div key={option.name + index}>
              <CheckboxSimple
                fullWidth
                label={option.label}
                name={option.name}
                defaultValue={option.defaultValue || false}
              />
            </div>
          );
        })}
      </div>
    </FormGroup>
  );
};

export default CheckboxGroup;
