import { createFilterOptions } from '@material-ui/lab';
import React from 'react';
import AutoComplete from './AutoComplete';
import { handleArrayFilterValue } from './StringArrayAutoComplete';
const WAIT_INTERVAL = 700;

const filter = createFilterOptions();

const AsyncPropBasedAutoComplete = ({
  loadOptions,
  initialOptions = [],
  updateValueWithOptions = false,
  input,
  prop = 'id',
  alternativeProp = 'alternativeId',
  propLabel = 'name',
  multiple = false,
  fixedValue,
  setValue,
  onChange = () => undefined,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [newValue, setNewValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [timer, setTimer] = React.useState(false);

  React.useEffect(() => {
    if (initialOptions?.length > 0) {
      setOptions(initialOptions);
    }
  }, [initialOptions]);

  const handleLoadOptions = React.useCallback((inputValue, loadOptions) => {
    setLoading(true);
    loadOptions(inputValue).then((options) => {
      setLoading(false);
      setOptions(options);
    });
  }, []);

  React.useEffect(() => {
    if (open) {
      handleLoadOptions(inputValue, loadOptions);
    }
  }, [open, inputValue]);

  const valueFromInput = multiple
    ? handleArrayFilterValue(input.value)
    : options[0]?.[alternativeProp]
      ? options[0]?.[alternativeProp]
      : input.value;

  const value =
    multiple && options.length
      ? options?.filter((option) =>
          valueFromInput.some(
            (value) => value === (option[option?.prop] || option[prop]),
          ),
        )
      : options?.find(
          (option) =>
            (
              option[option?.prop] ||
              option[prop] ||
              option[alternativeProp]
            )?.toString() === valueFromInput?.toString(),
        ) || null;

  return (
    <AutoComplete
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (
          !option?.new &&
          !option?.[alternativeProp] &&
          option?.[prop] == null
        ) {
          return '';
        }
        return (
          option?.[option?.propLabel]?.toString() ||
          option[propLabel]?.toString()
        );
      }}
      onChange={(_, newValue) => {
        const value = multiple
          ? newValue?.map((value) => value[value?.prop || prop])
          : newValue
            ? newValue[newValue?.prop || prop] || (rest.allowNew && newValue)
            : undefined;
        input.onChange(value);
        if (onChange) onChange(newValue);
        if (rest.allowNew) setNewValue(newValue);
        if (setValue) setValue(newValue);
      }}
      onFocus={
        !multiple
          ? () => {
              input.onChange('');
              setInputValue('');
            }
          : undefined
      }
      blurOnSelect={!multiple}
      size="small"
      filterOptions={(options, params) => {
        let filtered = options;

        if (rest.allowNew && params.inputValue !== '') {
          filtered = filter(options, params);
          const newValue = {
            new: true,
            [prop]: null,
            [propLabel]: `Adicionar "${params.inputValue}"`,
            selectedLabel: params.inputValue,
          };
          if (
            !filtered.some(
              (opt) =>
                opt[propLabel]?.toLowerCase() ===
                params.inputValue?.toLowerCase(),
            )
          )
            filtered.push(newValue);
        }

        return filtered;
      }}
      {...rest}
      multiple={multiple}
      loading={loading}
      options={options}
      open={open}
      value={newValue?.selectedLabel || (fixedValue ?? value)}
      disableCloseOnSelect={multiple}
      getOptionSelected={(option, value) => {
        return +option[option?.prop || prop] === +value[option?.prop || prop];
      }}
      loadingText={'Carregando...'}
      onInputChange={(_, newInputValue, reason) => {
        clearTimeout(timer);
        setLoading(true);
        if (reason === 'input')
          setTimer(
            setTimeout(() => setInputValue(newInputValue), WAIT_INTERVAL),
          );
      }}
      onOpen={() => {
        setOpen(true);
        rest.onOpen && rest.onOpen();
      }}
      onClose={() => {
        setOpen(false);
        rest.onClose && rest.onClose();
      }}
    />
  );
};

export default AsyncPropBasedAutoComplete;
