import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Form } from 'react-final-form';
import * as yup from 'yup';
import AsyncPropBasedAutoComplete from '../../../Components/AsyncPropBasedAutocomplete';
import Field from '../../../Components/Field';
import InfoIcon from '../../../Components/InfoIcon';
import ValueWithTooltip from '../../../Components/ValueWithTooltip';
import yupValidation from '../../../lib/yupValidation';
import { PlanosFiancaDigital } from '../../../models/fiancaDigital';
import { validateDate } from '../../../utils/utils';
import { formatCurrency } from '../../fechamento/utils';
import contratosRepository, {
  Contrato,
} from '../contratos/contratosRepository';
import { statusContratoEnum } from '../contratos/utils';
import { useStyles } from './styles';

interface CadastroComissaoDialogProps {
  open: boolean;
  showContratosField?: boolean;
  handleClose: () => void;
  contratoValues?: Contrato & { imovelId?: number };
  handleSelectContrato?: (contrato: Contrato) => void;
  onSubmit: (values: any) => void;
}

const schema = (cadastroManual: boolean) =>
  yup.object().shape({
    dataPrevisaoPagamento: yup.date().required('Data obrigatória'),
    ...(cadastroManual
      ? { contratoFiancaDigitalId: yup.number().required('Obrigatório') }
      : {}),
  });

function tooltipComissao(
  valorSetup?: number,
  planoFiancaDigital?: PlanosFiancaDigital,
) {
  if (!valorSetup || !planoFiancaDigital) return '';

  return (
    <div style={{ display: 'grid' }}>
      <span>Taxa de setup: {formatCurrency(valorSetup)}</span>
      <span>
        Comissão Plano {planoFiancaDigital?.nomePlano}:{' '}
        {planoFiancaDigital?.percentualComissao}%
      </span>
    </div>
  );
}

const CadastroComissaoDialog = ({
  open,
  showContratosField,
  handleClose,
  contratoValues,
  handleSelectContrato,
  onSubmit,
}: CadastroComissaoDialogProps) => {
  const classes = useStyles();
  const initialValues = {
    contratoFiancaDigitalId: contratoValues?.id,
    dataPrevisaoPagamento: contratoValues?.comissao?.dataPrevisaoPagamento,
  };

  const fetchContratos = async ($term: string) => {
    const response = await contratosRepository.list({
      query: {
        $term,
        semComissao: true,
        status: statusContratoEnum.ATIVO,
      },
    });

    return response.ok ? response.data : [];
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      transitionDuration={{ enter: 100, exit: 100 }}
    >
      <DialogTitle>
        <Typography align="center" variant="h6">
          Geração de comissão da fiança digital
        </Typography>
      </DialogTitle>
      <Form
        validate={yupValidation(schema(!contratoValues))}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ overflow: 'hidden' }}>
                <Grid container direction="column">
                  {showContratosField && (
                    <Grid item container>
                      <Grid xs={12}>
                        <Typography className={classes.title}>
                          Selecione um contrato
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          label="Nome da imobiliária, cliente ou proprietário"
                          name="contratoFiancaDigitalId"
                          component={AsyncPropBasedAutoComplete}
                          loadOptions={fetchContratos}
                          propLabel="nomeCliente"
                          renderOption={(contrato: Contrato) => {
                            return (
                              <ValueWithTooltip
                                value={`[${contrato.tenant.name}] ${contrato.nomeCliente}`}
                                additionalInfo={
                                  contrato.proposta.imovel.codigoImovel
                                }
                              />
                            );
                          }}
                          setValue={handleSelectContrato}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Zoom in={!!contratoValues?.id || !!contratoValues?.comissao}>
                    <Grid container xs={12} spacing={1}>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  width="40%"
                                  className={classes.titleHeader}
                                >
                                  Corretor
                                </TableCell>
                                <TableCell
                                  width="30%"
                                  className={classes.titleHeader}
                                >
                                  Valor
                                </TableCell>
                                <TableCell
                                  width="130%"
                                  className={classes.titleHeader}
                                >
                                  Imobiliária
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow className={classes.lastRow}>
                                <TableCell>
                                  {contratoValues?.usuarioCriador?.name ||
                                    contratoValues?.analise?.usuarioCriador
                                      ?.name}
                                </TableCell>
                                <TableCell>
                                  <InfoIcon
                                    tooltipTitle={tooltipComissao(
                                      contratoValues?.valorSetup,
                                      contratoValues?.planoFiancaDigital,
                                    )}
                                  >
                                    {formatCurrency(
                                      contratoValues?.comissao?.valorComissao,
                                    )}
                                  </InfoIcon>
                                </TableCell>
                                <TableCell>
                                  {contratoValues?.tenant?.name}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          name="dataPrevisaoPagamento"
                          label="Data de previsão do pagamento"
                          format="DD/MM/yyyy"
                          validate={validateDate}
                          fullWidth
                          disablePast
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Zoom>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Fechar
                </Button>
                <Button onClick={() => form.submit()} color="primary" autoFocus>
                  Gerar Comissão
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default CadastroComissaoDialog;
