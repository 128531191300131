import { createBasicRepository as createRepository } from 'elentari';
import { paginate, softDeleteStrategy } from '../../../lib/feathers';
import { BaseModel } from '../../../models/baseModel';
import { restApi } from '../../../services/api';

export interface PagamentoComissaoFiancaDigital extends BaseModel {
  id: number;
  dataPagamento: Date;
  usuarioPagamentoId: number;
  comprovantePagamento?: string;
  observacao?: string;
  usuarioPagamento: { id: number; username: string };
}

const props = {
  path: 'pagamento-comissao-fianca-digital',
  restApi,
  softDeleteStrategy,
  queryTransform: {
    ...paginate,
  },
};

export default createRepository(props);
