import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';

export default createMuiTheme({
  typography: {
    fontFamily: 'Gilroy, Roboto, Helvetica, Arial, sans-serif',
  },
  palette: {
    primary: {
      50: '#F5F5FB',
      100: '#FFBEE9',
      200: '#b37fff',
      300: '#E4E4FF',
      500: '#7E51DF',
      800: '#4824ac',
    },
    grey: grey,
    text: {
      primary: '#3A3A3A',
      secondary: '#676767',
      hint: '#8E8FA6',
    },
    background: {
      default: '#F5F5FB',
    },
    button: {
      success: green[500],
      error: red[500],
    },
  },
  overrides: {
    MuiTableCell: {
      stickyHeader: {
        left: 'unset',
      },
    },
  },
});
