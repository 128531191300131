import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Error } from '@material-ui/icons';
import BlockIcon from '@material-ui/icons/Block';
import CallIcon from '@material-ui/icons/Call';
import CloseIcon from '@material-ui/icons/Close';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EmailIcon from '@material-ui/icons/Email';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import TagsIcon from '@material-ui/icons/LocalOffer';
import PersonIcon from '@material-ui/icons/Person';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TransferIcon from '@material-ui/icons/TransferWithinAStation';
import UpdateIcon from '@material-ui/icons/Update';
import WarningIcon from '@material-ui/icons/Warning';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import moment from 'moment';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import When from '../../Components/When';
import newNotificationMp3 from '../../audios/notificacao-curta.mp3';
import newLeadMp3 from '../../audios/notificacao-longa.mp3';
import { Actions } from '../../realtime/RealTimeProvider';

const NotificacaoIconConfig = {
  NOVO_CLIENTE: PersonIcon,
  ATENDIMENTO_PENDENTE: CallIcon,
  AUTORIZACAO_VENCIMENTO: HomeWorkIcon,
  PROPOSTA_VENDA: HomeWorkIcon,
  PROPOSTA_LOCACAO: HomeWorkIcon,
  FECHAMENTO_VENDA: HomeWorkIcon,
  FECHAMENTO_LOCACAO: HomeWorkIcon,
  NOVO_IMOVEL: HomeWorkIcon,
  TRANSFERENCIA_CLIENTES: TransferIcon,
  NOVA_OPORTUNIDADE: HowToRegIcon,
  INTEGRACAO_WHATSAPP: WhatsAppIcon,
  PERSONALIZADA: EmailIcon,
  ATRIBUICAO_TAGS_EM_LOTE: TagsIcon,
  VISITA_AGENDADA: ScheduleIcon,
  ERRO_LEAD: Error,
  IMOVEL_VENCIDO: UpdateIcon,
  IMOVEL_DESATUALIZADO: UpdateIcon,
  IMOVEL_REPROVADO: BlockIcon,
  ANALISE_ATUALIZADA: CreditCardIcon,
  CONTRATO_ATUALIZADO: CreditCardIcon,
  INADIMPLENCIA_ATUALIZADA: CreditCardIcon,
  QUANTIDADE_INTEGRACAO: WarningIcon,
  ACESSO_FORA_DE_HORA: ExitToAppIcon,
  LEMBRETE: ScheduleIcon,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(50),
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
  },
  tituloNotificacao: {
    fontSize: '1.30rem',
  },
  subtitulo: {
    lineHeight: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));

const Notificacoes = ({ open, handleClose, notificacoes, setNotificacoes, dispatchSockets }) => {
  const classes = useStyles();
  const [currentNotificationIds, setCurrentNotificatiosIds] = useState([]);
  const [soundIsPlaying, setSoundIsPlaying] = useState(false);
  const [hasExecuted, setHasExecuted] = useState(false);
  const [newLeadArrayBuffer, setNewLeadArrayBuffer] = useState(null);
  const [newNotificationArrayBuffer, setNewNotificationArrayBuffer] = useState(null);
  const audioContext = 'AudioContext' in window ? new AudioContext() : null;

  const atualizaNotificacaoLida = async (notificacao) => {
    dispatchSockets({
      type: Actions.MARCAR_COMO_LIDA_NOTIFICACOES,
      payload: { evento: 'UNICA', ids: [notificacao.id] },
    });
    notificacao.lida = true;
    setNotificacoes([...notificacoes]);
  };

  const redirectiona = (urlNotificacao, novaAba) => {
    novaAba ? window.open(urlNotificacao) : (window.location = urlNotificacao);
  };

  const handleClickNotificacao = async (event, notificacao) => {
    const novaAba = event.ctrlKey;
    await atualizaNotificacaoLida(notificacao);
    if (window.location.origin !== notificacao.url) {
      redirectiona(notificacao.url, novaAba);
    }
    if (notificacao?.key) closeSnackbar(notificacao.key);
    handleClose();
  };

  const notificationAnimation = () => {
    const notificationIcon = document.getElementById('notification-icon');
    notificationIcon.style.animation = `shake 1s ease-in-out 1`;
    setTimeout(() => {
      notificationIcon.style.animation = '';
    }, 1000);
  };

  const playSound = (buffer) => {
    if (!audioContext) {
      return;
    }
    if (!soundIsPlaying) {
      const source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);
      source.start();

      notificationAnimation();
      setSoundIsPlaying(true);
      setTimeout(() => {
        setSoundIsPlaying(false);
      }, 15000);
    }
  };

  const openNovoClienteSnackBar = (notificacao) => {
    const autoHideDuration = moment(notificacao?.conteudo?.tempoLimiteExibicao).diff(
      moment(),
      'milliseconds'
    );
    if (notificacao.conteudo) {
      const key = enqueueSnackbar('Novo cliente!', {
        variant: 'novoCliente',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        notificacao,
        expandedDelay: 15000,
        atualizaNotificacaoLida,
        autoHideDuration: autoHideDuration > 0 ? autoHideDuration : null,
      });
      notificacao.key = key;
    }
  };

  useEffect(() => {
    if (!audioContext) {
      return;
    }
    const loadAudio = async (audio) => {
      const response = await fetch(audio);
      const audioData = await response.arrayBuffer();
      return await audioContext.decodeAudioData(audioData);
    };
    loadAudio(newLeadMp3).then((buffer) => setNewLeadArrayBuffer(buffer));
    loadAudio(newNotificationMp3).then((buffer) => setNewNotificationArrayBuffer(buffer));
  }, []);

  useEffect(() => {
    if (currentNotificationIds.length === 0) {
      setCurrentNotificatiosIds(notificacoes.map((notificacao) => notificacao.id));
    } else {
      const newNotifications = notificacoes.filter(
        (notificacao) => !currentNotificationIds.includes(notificacao.id)
      );
      if (newNotifications.length > 0) {
        if (newNotifications[0].tipoNotificacao === 'NOVO_CLIENTE') {
          playSound(newLeadArrayBuffer);
          openNovoClienteSnackBar(newNotifications[0]);
        } else {
          playSound(newNotificationArrayBuffer);
        }
        setCurrentNotificatiosIds(notificacoes.map((notificacao) => notificacao.id));
      }
    }
  }, [notificacoes]);

  useEffect(() => {
    if (notificacoes.length && !hasExecuted) {
      const ultimasSnackbarsNaoLidas = notificacoes
        .filter(
          (notificacao) =>
            !notificacao.lida &&
            notificacao.conteudo &&
            (moment(notificacao.conteudo?.tempoLimiteExibicao).isAfter(moment()) ||
              notificacao.conteudo?.tempoLimiteExibicao == null)
        )
        .slice(0, 3);

      ultimasSnackbarsNaoLidas.forEach((notificacao) => {
        if (notificacao.tipoNotificacao === 'NOVO_CLIENTE') openNovoClienteSnackBar(notificacao);
      });
      setHasExecuted(true);
    }
  }, [notificacoes]);

  const NotificacoesHeader = () => (
    <ListSubheader component='div' disableSticky={true} classes={{ root: classes.cabecalho }}>
      <Grid container justify='space-between'>
        <Hidden smUp>
          <Grid item xs={2}>
            <IconButton style={{ paddingLeft: 0 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Hidden>
        <Grid item xs={8} sm={10} className={classes.tituloNotificacao}>
          Notificações
        </Grid>
        <Grid item xs={2} className={classes.subtitulo}>
          <Tooltip title='Marcar todas como lidas'>
            <IconButton
              edge='end'
              onClick={() => {
                notificacoes.forEach((notificacao) => (notificacao.lida = true));
                dispatchSockets({
                  type: Actions.MARCAR_COMO_LIDA_NOTIFICACOES,
                  payload: { evento: 'TODAS' },
                });
                setNotificacoes([...notificacoes]);
              }}
            >
              <DoneAllIcon fontSize='small' color='primary' />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </ListSubheader>
  );
  return (
    <Drawer anchor={'right'} open={open} onClose={handleClose}>
      <List subheader={<NotificacoesHeader />} className={classes.root}>
        <When is={notificacoes?.length <= 0}>
          <ListItem>
            <Typography variant='subtitle1'>Sem notificações</Typography>
          </ListItem>
        </When>
        <When is={notificacoes?.length > 0}>
          {notificacoes.map((notificacao, idx) => {
            const Icon = NotificacaoIconConfig[notificacao.tipoNotificacao];
            return (
              <ListItem button key={idx}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  onClick={(event) => handleClickNotificacao(event, notificacao)}
                  primary={notificacao.titulo}
                  secondary={
                    <React.Fragment>
                      <Typography component='span' variant='body2'>
                        {notificacao.mensagem}
                      </Typography>
                      <br />
                      <em>
                        <Typography component='span' variant='body2'>
                          {moment(notificacao.dataNotificacao).fromNow()}
                        </Typography>
                      </em>
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <When is={notificacao.lida === false}>
                    <Tooltip title='Marcar como lida'>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => {
                          atualizaNotificacaoLida(notificacao);
                        }}
                      >
                        <FiberManualRecordSharpIcon fontSize='small' color='primary' />
                      </IconButton>
                    </Tooltip>
                  </When>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </When>
      </List>
    </Drawer>
  );
};

export default Notificacoes;
