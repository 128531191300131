import { createBasicRepository as createRepository } from 'elentari';
import { restApi } from '../../services/api';
import addUploadCapabilities from '../../lib/addUploadCapabilities';

const arquivosUploaderRepository = createRepository({
  path: 'arquivos-uploader',
  restApi,
});
addUploadCapabilities(arquivosUploaderRepository);

export { arquivosUploaderRepository };
