import Section from '@elentari/components-eve/components/Section';
import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import TextField from '@elentari/components-eve/final-form/TextField';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import Field from '../../../Components/Field';
import Loading from '../../../Components/Loading';
import MoneyTextField from '../../../Components/MoneyTextField';
import { restApi } from '../../../services/api';
import { useStyles } from './DetalhesDialog/style';
import { IPagamentosGetData } from './types';

interface ModalComissaoPagamentoProps {
  pagamentoId: number;
  open: boolean;
  onClose: () => void;
}

const ModalComissaoPagamento = ({ pagamentoId, open, onClose }: ModalComissaoPagamentoProps) => {
  const [data, setData] = useState<IPagamentosGetData>();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      if (pagamentoId) {
        const response = await restApi.get<IPagamentosGetData>(
          `/pagamento-comissao-fianca-digital/${pagamentoId}`,
        );
        if (response.ok && response.data) {
          setData(response.data);
        }
      }
    })();
  }, [pagamentoId, onClose]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <DialogTitle
        style={{
          textAlign: 'center',
        }}
      >
        Detalhes do pagamento
      </DialogTitle>
      {!data && <Loading className={classes.loading} />}

      {!!data && (
        <Form
          onSubmit={() => {}}
          initialValues={{
            ...data,
            comissoes: data.comissoes.join('; '),
          }}
          render={({ values, form }) => (
            <form>
              <DialogContent>
                <Section label={''}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                      <TextField
                        name="id"
                        size="small"
                        label="ID do Pagamento"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <DatePicker
                        name="dataPagamento"
                        label="Data do pagamento"
                        format="DD/MM/yyyy"
                        fullWidth
                        size="small"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="usuarioPagamento"
                        label="Usuário do Pagamento"
                        fullWidth
                        disabled
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Field
                        name="valorTotalPago"
                        label="Valor Total Pago"
                        disabled
                        fullWidth
                        size="small"
                        component={MoneyTextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <TextField
                        name="observacao"
                        size="small"
                        label="Observação"
                        fullWidth
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3} style={{ alignSelf: 'center' }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                        startIcon={<DownloadIcon />}
                        href={values.comprovantePagamento || ''}
                        disabled={!values.comprovantePagamento}
                      >
                        Comprovante
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <TextField
                        name="comissoes"
                        label="Comissões neste pagamento"
                        fullWidth
                        size="small"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Section>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => onClose()} color="secondary">
                  Fechar
                </Button>
              </DialogActions>
            </form>
          )}
        />
      )}
    </Dialog>
  );
};

export default ModalComissaoPagamento;
