import React from 'react';
import CheckboxSimple from '@elentari/components-eve/final-form/CheckboxSimple';

const CheckBox = (props) => {
  return (
    <CheckboxSimple
      color='primary'
      type='checkbox'
      fullWidth
      name='checkbox'
      defaultValue={false}
      {...props}
    />
  );
};
export default CheckBox;
