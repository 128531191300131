import moment from 'moment';
import { dateFormatter, priceFormatter } from '../../../utils/formater';
import { ComissaoFiancaDigitalConfiguration } from '../../features/features';
import { IComissoesData } from './types';

export const statusComissoesColor = {
  PAGO: '#50DFB3',
  PENDENTE: '#FFDE59',
  CANCELADO: '#E56173',
};

export const getDataPrevisaoPagamento = (
  configuracao?: ComissaoFiancaDigitalConfiguration,
) => {
  if (
    !configuracao?.diaPrevisaoPagamentoPrimeiroPeriodo &&
    !configuracao?.diaPrevisaoPagamentoSegundoPeriodo
  ) {
    return;
  }

  const diaInicioPrimeiroPeriodo = Number(
    configuracao.diaInicioPrimeiroPeriodo,
  );
  const diaFimPrimeiroPeriodo = Number(configuracao.diaFimPrimeiroPeriodo);
  const diaPrevisaoPagamentoPrimeiroPeriodo = Number(
    configuracao.diaPrevisaoPagamentoPrimeiroPeriodo,
  );

  const diaInicioSegundoPeriodo = Number(configuracao.diaInicioSegundoPeriodo);
  const diaFimSegundoPeriodo = Number(configuracao.diaFimSegundoPeriodo);
  const diaPrevisaoPagamentoSegundoPeriodo = Number(
    configuracao.diaPrevisaoPagamentoSegundoPeriodo,
  );

  const diaHoje = moment().get('date');

  if (diaHoje >= diaInicioPrimeiroPeriodo && diaHoje <= diaFimPrimeiroPeriodo) {
    if (diaPrevisaoPagamentoPrimeiroPeriodo < diaFimPrimeiroPeriodo) {
      return moment()
        .date(diaPrevisaoPagamentoPrimeiroPeriodo)
        .add(1, 'month')
        .toDate();
    }

    return moment().date(diaPrevisaoPagamentoPrimeiroPeriodo).toDate();
  }

  if (diaHoje >= diaInicioSegundoPeriodo && diaHoje <= diaFimSegundoPeriodo) {
    if (diaPrevisaoPagamentoSegundoPeriodo < diaFimSegundoPeriodo) {
      return moment()
        .date(diaPrevisaoPagamentoSegundoPeriodo)
        .add(1, 'month')
        .toDate();
    }

    return moment().date(diaPrevisaoPagamentoSegundoPeriodo).toDate();
  }
};

export function MapperComissao(data: IComissoesData) {
  let pagamento = null;

  if (data.pagamento) {
    pagamento = {
      id: data.pagamento.id,
      usuarioPagamento: data.pagamento.usuarioPagamento,
      dataPagamento: dateFormatter.format(
        new Date(data.pagamento.dataPagamento),
      ),
      observacao: data.pagamento.observacao ?? null,
      comprovante: data.pagamento.comprovante ?? null,
      valorTotalPago: priceFormatter.format(
        data.pagamento.valorTotalPago as number,
      ),
      idsComissoes: data.pagamento.idsComissoes,
    };
  }

  if (data.status.dataCancelamento) {
    data.status.dataCancelamento = dateFormatter.format(
      new Date(data.status.dataCancelamento),
    );
  }

  return {
    id: data.id,
    codigoImovel: data.codigoImovel,
    imovelId: data.imovelId,
    valorComissao: data.valorComissao,
    cliente: data.cliente,
    usuario: data.usuario,
    imobiliaria: data.imobiliaria,
    dataPrevisaoPagamento: data.dataPrevisaoPagamento,
    dataGeracao: dateFormatter.format(new Date(data.dataGeracao)),
    pagamento,
    status: data.status,
  };
}

export function getValorComissao(
  percentualComissao: number,
  valorSetup: number,
) {
  const valorComissao = (percentualComissao / 100) * valorSetup;
  return valorComissao;
}
