import {
  Button,
  Card,
  CardActions,
  Collapse,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventIcon from '@material-ui/icons/Event';
import clsx from 'clsx';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { default as React, forwardRef, useCallback, useEffect, useState } from 'react';
import CountdownLinearProgress from '../../../../Components/CountdownLinearProgressProps';
import ConfirmModal from '../../../../Components/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    '@media (min-width:600px)': {
      width: theme.spacing(50),
    },
  },
  card: {
    width: '100%',
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    color: '#000',
    transition: 'all .2s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    backgroundColor: '#fff',
    padding: 16,
    paddingBottom: 0,
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

interface GoogleAgendaProps extends CustomContentProps {
  googleCalendarLink: string;
  disableGoogleCalendarLink: () => void;
}

const GoogleAgenda = forwardRef<HTMLDivElement, GoogleAgendaProps>(
  ({ id, googleCalendarLink, disableGoogleCalendarLink, ...props }, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState({ isExpanded: true, userExpanded: false });
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => ({ isExpanded: !oldExpanded.isExpanded, userExpanded: true }));
    }, []);

    useEffect(() => {
      let timeoutId: NodeJS.Timeout;

      if (props.autoHideDuration)
        timeoutId = setTimeout(() => {
          closeSnackbar(id);
        }, props.autoHideDuration);
      return () => {
        clearTimeout(timeoutId);
      };
    }, []);

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Card className={classes.card} style={{ backgroundColor: '#FFF' }} elevation={3}>
          <CardActions classes={{ root: classes.actionRoot }}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs='auto'>
                  <EventIcon htmlColor='#0000008a' />
                </Grid>
                <Grid item xs>
                  <Typography variant='body1' className={classes.typography}>
                    {props.message}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.icons}>
              <IconButton
                size='small'
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded.isExpanded,
                })}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
              <IconButton size='small' className={classes.expand} onClick={handleDismiss}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </Grid>
          </CardActions>
          <Collapse in={expanded.isExpanded} timeout='auto'>
            <Paper className={classes.paper}>
              <Grid container direction='column' spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant='body1'>
                            Deseja adicionar a visita à sua Agenda do Google?
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='caption'>
                            Para desativar essa opção{' '}
                            <Link
                              style={{ cursor: 'pointer' }}
                              onClick={() => setOpenConfirmDialog(true)}
                            >
                              clique aqui
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container justify='center' spacing={2}>
                        <Grid item alignContent='center'>
                          <Button
                            size='small'
                            color='secondary'
                            variant='outlined'
                            onClick={handleDismiss}
                          >
                            Não
                          </Button>
                        </Grid>

                        <Grid item alignContent='center'>
                          <Button
                            size='small'
                            color='primary'
                            variant='contained'
                            href={googleCalendarLink}
                            target='_blank'
                          >
                            Sim
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {props.autoHideDuration && (
                    <CountdownLinearProgress
                      valorInicial={props.autoHideDuration}
                      valorTotal={props.autoHideDuration}
                      showTimer
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Collapse>
        </Card>
        <ConfirmModal
          open={openConfirmDialog}
          onDeny={() => setOpenConfirmDialog(false)}
          onAccept={() => {
            disableGoogleCalendarLink();
            setOpenConfirmDialog(false);
          }}
          acceptLabel='Sim'
          denyLabel='Não'
          content='Deseja desativar a criação de eventos para a Agenda do Google?'
        />
      </SnackbarContent>
    );
  }
);

GoogleAgenda.displayName = 'GoogleAgenda';

export default GoogleAgenda;
