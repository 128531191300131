import { AuthContext } from 'elentari';
import React, { useContext, useState } from 'react';
import { FormRenderProps } from 'react-final-form';
import * as yup from 'yup';
import yupValidation from '../../../lib/yupValidation';
import { IAuthContext } from '../../../models/authContext';
import { IRoleta } from '../../../models/roletas';
import DialogModal from '../../properties/modals/DialogModal';
import TransferirEmMassaForm from './TransferirEmMassaForm';

const transferirAtendimentoSchema = yup.object().shape({
  novoResponsavel: yup
    .string()
    .nullable()
    .test('novoResponsavel-required', 'Obrigatório', function (novoResponsavel) {
      const required =
        this.parent.possuiResponsavel !== true ||
        (novoResponsavel != null && novoResponsavel !== '');
      const repeatead = novoResponsavel && +this.parent.responsavel === +novoResponsavel;
      if (!required) {
        return this.createError({
          message: 'Obrigatório',
          path: 'novoResponsavel',
        });
      } else if (repeatead) {
        return this.createError({
          message: 'Responsável repetido',
          path: 'novoResponsavel',
        });
      }
      return true;
    }),
  tipoRoleta: yup
    .string()
    .nullable()
    .test('tipoRoleta-required', 'Obrigatório', function (tipoRoleta) {
      return this.parent.possuiResponsavel !== false || (tipoRoleta != null && tipoRoleta !== '');
    }),
  pretensao: yup
    .string()
    .nullable()
    .test('pretensao-required', 'Obrigatório', function (pretensao) {
      return this.parent.possuiResponsavel !== false || (pretensao != null && pretensao !== '');
    }),
  produtoId: yup
    .string()
    .nullable()
    .test('produtoId-required', 'Obrigatório', function (produtoId) {
      return (
        this.parent.possuiResponsavel !== false ||
        !['PRODUTO_ESPECIFICO', 'PRE_ATENDIMENTO_PRODUTO_ESPECIFICO'].includes(
          this.parent.tipoRoleta
        ) ||
        produtoId != null
      );
    }),
  tagId: yup
    .string()
    .nullable()
    .test('tagId-required', 'Obrigatório', function (tagId) {
      return (
        this.parent.possuiResponsavel !== false ||
        !['TAG', 'PRE_ATENDIMENTO_TAG'].includes(this.parent.tipoRoleta) ||
        tagId != null
      );
    }),
  roletaId: yup
    .string()
    .nullable()
    .test('roletaId-required', 'Obrigatório', function (roletaId) {
      return (
        this.parent.possuiResponsavel !== false ||
        this.parent.tipoRoleta !== 'FLIP_AND_GO' ||
        roletaId != null
      );
    }),
});

interface TransferirEmMassaDialogProps {
  total: number;
  open: boolean;
  onClose: () => void;
  handleSubmit: (values: any, roletas: any) => void;
}

const TransferirEmMassaDialog = ({
  total,
  open,
  onClose,
  handleSubmit,
}: TransferirEmMassaDialogProps) => {
  const context = useContext<IAuthContext>(AuthContext);
  const [roletas, setRoletas] = useState<IRoleta[]>([]);

  return (
    <DialogModal
      open={open}
      title='Transferência de Cliente'
      handleClose={onClose}
      form={{
        handleSubmit: (values: any) => handleSubmit(values, roletas),
        validate: yupValidation(transferirAtendimentoSchema),
        initialValues: {
          possuiResponsavel: true,
          responsavel: null,
          novoResponsavel: +context.user.id,
        },
      }}
      submitLabel='Confirmar Transferência'
      submitDisabled={false}
      children={({ form }: FormRenderProps) => (
        <TransferirEmMassaForm
          form={form}
          total={total}
          setRoletas={setRoletas}
          roletas={roletas}
        />
      )}
    />
  );
};

export default TransferirEmMassaDialog;
