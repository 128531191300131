import React from 'react';
import AutoComplete from './AutoComplete';
import { useFormData } from './FormFilter/DataContext';

const WAIT_INTERVAL = 700;

export const AsyncAutoComplete = ({
  loadOptions,
  initialOptions = [],
  updateValueWithOptions = false,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [timer, setTimer] = React.useState(false);
  const formDataContext = useFormData();

  React.useEffect(() => {
    if (initialOptions?.length > 0) {
      setOptions(initialOptions);
    }
  }, [initialOptions]);

  const handleLoadOptions = React.useCallback((inputValue, loadOptions) => {
    setLoading(true);
    loadOptions(inputValue).then((options) => {
      setLoading(false);
      setOptions(options);
    });
  }, []);

  React.useEffect(() => {
    if (open) {
      handleLoadOptions(inputValue, loadOptions);
    }
  }, [open, inputValue, loadOptions, handleLoadOptions]);

  const value = updateValueWithOptions
    ? options.find((option) => rest.getOptionSelected(option, rest.value)) ||
      null
    : rest.value;

  React.useEffect(() => {
    if (formDataContext && value.length) {
      formDataContext.updateFormFieldData(rest?.name, value);
    }
  }, []);

  return (
    <AutoComplete
      {...rest}
      value={value}
      options={options}
      loading={loading}
      open={open}
      loadingText={'Carregando...'}
      onInputChange={(_, newInputValue) => {
        clearTimeout(timer);
        setLoading(true);
        setTimer(setTimeout(() => setInputValue(newInputValue), WAIT_INTERVAL));
      }}
      onOpen={() => {
        setOpen(true);
        rest.onOpen && rest.onOpen();
      }}
      onClose={() => {
        setOpen(false);
        rest.onClose && rest.onClose();
      }}
    />
  );
};

export default AsyncAutoComplete;
