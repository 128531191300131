import Box from '@material-ui/core/Box';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface CountdownLinearProgressProps extends LinearProgressProps {
  valorInicial: number;
  valorTotal: number;
  showTimer?: boolean;
}

const CountdownLinearProgress = ({
  valorInicial,
  valorTotal,
  showTimer,
  ...props
}: CountdownLinearProgressProps) => {
  const [progress, setProgress] = useState({
    tempo: valorInicial || 0,
    porcentagem: 100,
  });
  const min = moment.duration(Math.round(progress.tempo)).minutes();
  const sec = moment.duration(Math.round(progress.tempo)).seconds();

  useEffect(() => {
    const totalTime = valorTotal || 0;

    if (totalTime <= 0) return;

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const tempo = prevProgress.tempo - 1000;
        const porcentagem = (tempo / totalTime) * 100;
        return { porcentagem, tempo };
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [valorInicial]);

  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearProgress variant='determinate' value={progress.porcentagem} {...props} />
      </Box>
      {showTimer && (
        <Box minWidth={35}>
          <Typography variant='body2' color='textSecondary'>
            {`${min}:${sec < 10 ? `0${sec}` : sec}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CountdownLinearProgress;
