import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  bairroForm: {
    [theme.breakpoints.up('sm')]: {
      flexGrow: 0,
      maxWidth: '48%',
      flexBasis: '48%',
    },
  },
  icon: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      flexGrow: 0,
      maxWidth: '4%',
      flexBasis: '4%',
    },
    zIndex: 10,
  },
  dialogPaper: {
    borderRadius: '10px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
}));
