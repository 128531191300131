import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

const Loading = ({ contained = false, message = '', messageVariant = 'h5', ...props }) => {
  return (
    <Grid
      container
      spacing={3}
      direction='column'
      alignItems='center'
      justify='center'
      style={{ minHeight: contained ? '100%' : '80vh' }}
      {...props}
    >
      {message && (
        <Grid item xs={12}>
          <Typography variant={messageVariant} color='primary'>
            {message}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Loading;
