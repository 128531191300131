import Tab from '@elentari/components-eve/components/Tab';
import Tabs from '@elentari/components-eve/components/Tabs';
import { Grid, Paper } from '@material-ui/core';
import { AuthContext } from 'elentari';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CardsComissoes } from './cardsComissoes';
import { ListaComissoes } from './listaComissoes';
import { ListaPagamentos } from './listaPagamentos';
import useCardsComissoes from './useCardsComissoes';

const TAB_COMISSOES = 0;
const TAB_PAGAMENTOS = 1;

export default function ComissoesPage() {
  const history = useHistory();
  const cardsComissoes = useCardsComissoes();
  const [tabIndex, setTabIndex] = useState(TAB_COMISSOES);
  const context: any = useContext(AuthContext);
  const isSuperAdmin = context.user?.roles === 'SUPER_ADMIN';

  const handleTabChange = (_: ChangeEvent<unknown>, value: number) => {
    const tab = value === TAB_PAGAMENTOS ? 'pagamentos' : 'comissoes';
    history.push(`/fianca-digital/${tab}`);
  };

  const fetchCards = () => {
    cardsComissoes.fetchData();
  };

  const renderContent = () => {
    switch (tabIndex) {
      case TAB_COMISSOES:
        return <ListaComissoes onComissaoChange={fetchCards} />;
      case TAB_PAGAMENTOS:
        return <ListaPagamentos />;
      default:
        return <div></div>;
    }
  };

  useEffect(() => {
    const path = history.location.pathname;
    if (path === '/fianca-digital/pagamentos') {
      setTabIndex(TAB_PAGAMENTOS);
    } else {
      setTabIndex(TAB_COMISSOES);
    }
  }, [history.location.pathname]);

  return (
    <Grid container xs={12}>
      {isSuperAdmin && <CardsComissoes cardsData={cardsComissoes.data} />}
      <Paper elevation={0} style={{ width: '100%' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
        >
          <Tab label="Comissões" />
          <Tab label="Pagamentos" />
        </Tabs>
        <div>{renderContent()}</div>
      </Paper>
    </Grid>
  );
}
