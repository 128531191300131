import TextField from '@elentari/components-eve/components/TextField';
import React from 'react';

export default ({ input, meta, ...props }) => {
  return (
    <TextField
      {...props}
      {...input}
      error={(meta.touched && !!meta.error) || props.error}
      helperText={(meta.touched && meta.error) || props.error}
      type='time'
      InputLabelProps={{
        shrink: true,
        ...props.InputLabelProps,
      }}
      inputProps={{
        step: 300,
        style: { paddingRight: '12px' },
        ...props.inputProps,
      }}
    />
  );
};
