export const validateAddress = (endereco) => {
  if (!endereco) return false;
  var hasValue = false;

  Object.keys(endereco).forEach((value) => {
    if (endereco[value] !== '') hasValue = true;
  });
  return hasValue;
};

export const sortLabelsAlphabetically = (arr) =>
  arr.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
