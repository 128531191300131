import { createBasicRepository as createRepository } from 'elentari';
import { paginate, softDeleteStrategy } from '../../../lib/feathers';
import { IEndereco } from '../../../models/adresses';
import { BaseModel } from '../../../models/baseModel';
import { PlanosFiancaDigital } from '../../../models/fiancaDigital';
import { restApi } from '../../../services/api';
import { StatusContrato } from './statusContratoRepository';

export interface Contrato extends BaseModel {
  id: number;
  tenantId: number;
  analiseId: number;
  nomeCliente: string;
  emailCliente: string;
  telefoneCliente: string;
  cpfCliente: string;
  rgCliente: string;
  dataInicio: Date;
  dataFim: Date;
  valorAluguel: number;
  valorCondominio: number;
  outrasTaxas: number;
  valorTotal: number;
  endereco: Partial<IEndereco>;
  comissao?: { valorComissao: number; dataPrevisaoPagamento?: Date };
  usuarioCriador: { id: number; name: string };
  proposta: { id: number; imovel: { id: number; codigoImovel: string } };
  analise: { id: number; usuarioCriador: { id: number; name: string } };
  tenant: { id: number; name: string };
  status: StatusContrato[];
  inadimplenciaAndamento: boolean;
  valorSetup: number;
  planoFiancaDigital: PlanosFiancaDigital;
}

const props = {
  path: 'contrato-fianca-digital',
  restApi,
  softDeleteStrategy,
  queryTransform: {
    ...paginate,
  },
};

export default createRepository(props);
