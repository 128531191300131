import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { INotificacoes } from '../../../../models/notifications';
import GroupValue from '../../../../Components/GroupValue';

interface TransferenciaClienteSnackbarProps {
  notificacao: INotificacoes;
  onClose: () => void;
}

interface Conteudo {
  cliente: { id: number; nome: string };
  transferidoPor: string;
}

const TransferenciaClienteSnackbar = ({
  notificacao,
  onClose,
}: TransferenciaClienteSnackbarProps) => {
  const { cliente, transferidoPor } = notificacao?.conteudo as Conteudo;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='body2'>{notificacao?.mensagem}</Typography>
      </Grid>
      {cliente?.nome && (
        <Grid item xs={12} md={6}>
          <GroupValue title='Cliente' value={cliente.nome} />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <GroupValue title='Transferido por' value={transferidoPor} />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='center'>
          <Button
            size='small'
            color='primary'
            variant='outlined'
            onClick={(e) => {
              onClose();
              e.stopPropagation();
              window.open(notificacao.url);
            }}
          >
            {cliente?.id ? 'Ver cliente' : 'Ver clientes'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TransferenciaClienteSnackbar;
