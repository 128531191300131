import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  spacing: {
    display: 'flex',
    padding: '12px',
  },
  loading: {
    width: '100%',
    minHeight: '300px',
    margin: 0,
  },
  footer: {
    justifyContent: 'space-between',
    padding: '8px 24px',
  },
  button: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '8px 24px',
    maxHeight: '85%',

    '& svg': {
      marginRight: '8px',
    },

    '&:disabled': {
      borderColor: '#EEE',
    },
  },
}));
