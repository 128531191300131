import React from 'react';
import { useStyles } from './style';

export function InfoBox({ children, icon: Icon }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Icon && (
        <div className={classes.icon}>
          <Icon />
        </div>
      )}
      <div className={classes.content}>
        <div style={{ padding: '24px 16px' }}>{children}</div>
      </div>
    </div>
  );
}
