import { ApiResponse, ApisauceInstance } from 'apisauce';
import { AxiosError } from 'axios';
import makePaginatedFetcher, { Paginated } from '../../utils/paginated-fetcher';
import { Chat2DeskResponse, SuccessfulResponse } from './Chat2DeskResponse';

interface Channel {
  id: number;
  name: string;
  phone: string;
  transports: Transport[];
}

type Transport = string;

interface Operator {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

interface CustomClientField {
  id: number;
  name: string;
}

export interface Chat2DeskClient {
  apiToken: string;
  getOperators: () => Promise<Operator[]>;
  getChannels: () => Promise<Channel[]>;
  getCustomClientFields: () => Promise<CustomClientField[]>;
}

export class ApisauceChat2DeskClient implements Chat2DeskClient {
  constructor(private readonly api: ApisauceInstance, apiToken?: string) {
    if (apiToken) {
      this.apiToken = apiToken;
    }
  }

  public set apiToken(apiToken: string) {
    if (apiToken) {
      this.api.setHeader('Authorization', apiToken);
    } else {
      this.api.deleteHeader('Authorization');
    }
  }

  public get apiToken() {
    return this.api.headers['Authorization'];
  }

  public async getChannels() {
    const getChannelPage = async ($skip: number, $limit: number): Promise<Paginated<Channel>> => {
      const response = await this.api.get<Chat2DeskResponse<Channel[], true>>('/v1/channels', {
        limit: $limit,
        offset: $skip,
      });
      return this.handlePaginatedResponse(response);
    };
    const channelPagesWithPageSizeOf = makePaginatedFetcher(getChannelPage);
    const channels = [];
    for await (const channelPage of channelPagesWithPageSizeOf(200)) {
      channels.push(...channelPage.data);
    }
    return channels;
  }

  public async getOperators() {
    const getOperatorPage = async ($skip: number, $limit: number): Promise<Paginated<Operator>> => {
      const response = await this.api.get<Chat2DeskResponse<Operator[], true>>('/v1/operators', {
        limit: $limit,
        offset: $skip,
      });
      return this.handlePaginatedResponse(response);
    };
    const operatorPagesWithPageSizeOf = makePaginatedFetcher(getOperatorPage);
    const operators = [];
    for await (const operatorPage of operatorPagesWithPageSizeOf(200)) {
      operators.push(...operatorPage.data);
    }
    return operators;
  }

  public async getCustomClientFields() {
    const response = await this.api.get<Chat2DeskResponse<CustomClientField[], true>>(
      '/v1/custom_client_fields'
    );
    return this.handleResponse(response);
  }

  private handleResponse<T, paginated = false>(
    response: ApiResponse<Chat2DeskResponse<T, paginated>>
  ) {
    if (this.isSuccessfulResponse(response)) {
      return response.data.data;
    } else {
      throw this.makeError(response.originalError);
    }
  }

  private handlePaginatedResponse<T>(
    response: ApiResponse<Chat2DeskResponse<T[], true>>
  ): Paginated<T> {
    if (this.isSuccessfulResponse(response)) {
      return {
        $limit: response.data.meta.limit,
        $skip: response.data.meta.offset,
        data: response.data.data,
        total: response.data.meta.total,
      };
    } else {
      throw this.makeError(response.originalError);
    }
  }

  private isSuccessfulResponse<T, paginated = false>(
    response: ApiResponse<Chat2DeskResponse<T, paginated>>
  ): response is SuccessfulResponse<T, paginated> {
    return response.ok && ['success', 'ok'].includes(response.data?.status as string);
  }

  private makeError(originalError: AxiosError | null) {
    return originalError
      ? new Error(originalError.message)
      : new Error('Houve um erro ao comunicar com o Chat2Desk');
  }
}
