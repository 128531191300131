import React, { useEffect } from 'react';
import AutoComplete from './AutoComplete';
import { useFormData } from './FormFilter/DataContext';
import { handleArrayFilterValue } from './StringArrayAutoComplete';

const IdBasedAutoComplete = ({ input, ...rest }) => {
  const formDataContext = useFormData();
  const { multiple = true } = rest;
  const ids = handleArrayFilterValue(input.value);
  const value = rest.options?.filter((option) =>
    ids.some((id) => +id === +option.id),
  );

  useEffect(() => {
    if (formDataContext && value.length)
      formDataContext.updateFormFieldData(input?.name, value);
  }, []);

  return (
    <AutoComplete
      disableCloseOnSelect
      size="small"
      multiple={!!multiple}
      value={value}
      getOptionSelected={(option, value) => +option.id === +value.id}
      onChange={(_, newValue) => {
        input.onChange(
          multiple ? newValue.map((value) => value.id) : newValue.id,
        );
      }}
      {...rest}
    />
  );
};

export default IdBasedAutoComplete;
