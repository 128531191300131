import React from 'react';
import MaterialAutocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@elentari/components-eve/components/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { FiChevronDown } from 'react-icons/fi';
import Chip from '@material-ui/core/Chip';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 6,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 27,
    color: theme.palette.primary.main,
  },
  label: {
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 'calc(100% - 55px)',
    '&.Mui-focused': {
      width: '100%',
    },
  },
  chip: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50vw',
    },
  },
}));

const filter = createFilterOptions();

const sanitize = (data) => {
  if (typeof data === 'object') {
    data = Object.values(data).join('');
  }
  return data;
};
export const AutoComplete = ({
  label,
  allowNew = false,
  getOptionLabel = (option) => option.label,
  meta = {},
  inputMode = 'text',
  ...rest
}) => {
  const classes = useStyles();
  const thereIsValue = rest?.value && Array.isArray(rest.value) && rest.value.length;
  return (
    <MaterialAutocomplete
      classes={{
        popupIndicator: classes.icon,
      }}
      popupIcon={<FiChevronDown />}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (allowNew && params.inputValue !== '') {
          filtered.push({
            _new: true,
            _inputValue: params.inputValue,
            label: `Adicionar "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      noOptionsText={'Sem opções'}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={getOptionLabel(option)}
            {...getTagProps({ index })}
            className={classes.chip}
          />
        ))
      }
      clearOnEscape
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label={label}
          helperText={meta?.touched && sanitize(meta?.error)}
          error={rest.error || (!!meta?.error && meta?.touched)}
          classes={{ root: classes.root }}
          InputLabelProps={{
            ...(!thereIsValue && { className: classes.label }),
          }}
          inputProps={{
            ...params.inputProps,
            'aria-autocomplete': 'none',
            form: {
              AutoComplete: 'off',
            },
            inputMode,
          }}
        />
      )}
      getOptionLabel={getOptionLabel}
      openText='Abrir'
      closeText='Fechar'
      clearText='Limpar'
      {...rest}
    />
  );
};

export default AutoComplete;
