import { create } from 'apisauce';
import { ApisauceChat2DeskClient, Chat2DeskClient } from './chat2DeskClient';

export interface Chat2DeskUser {
  id: number;
  name: string;
  email: string;
}

export interface Chat2DeskChannel {
  id: number;
  name: string;
  phone: string;
  transports: string[];
}

export type Chat2DeskTransport = string;

export interface Chat2DeskCustomClientField {
  id: number;
  name: string;
}

export class Chat2DeskRepository {
  constructor(private readonly client: Chat2DeskClient) {}

  get apiToken() {
    return this.client.apiToken;
  }

  set apiToken(apiToken: string) {
    this.client.apiToken = apiToken;
  }

  async getChannels(): Promise<Chat2DeskChannel[]> {
    return await this.client.getChannels();
  }

  async getUsers(): Promise<Chat2DeskUser[]> {
    const users = await this.client.getOperators();
    return users.map((user) => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
    }));
  }

  async getCustomClientFields(): Promise<Chat2DeskCustomClientField[]> {
    return await this.client.getCustomClientFields();
  }
}

export function makeChat2DeskRepository(
  chat2DeskURL: string,
  chat2DeskToken?: string
): Chat2DeskRepository {
  const api = create({ baseURL: chat2DeskURL });
  const chat2DeskClient = new ApisauceChat2DeskClient(api, chat2DeskToken);
  return new Chat2DeskRepository(chat2DeskClient);
}
