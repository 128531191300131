import { createBasicRepository as createRepository } from 'elentari';
import { paginate, softDeleteStrategy } from '../../lib/feathers';
import { restApi } from '../../services/api';

export const featuraValidationRepository = createRepository({
  path: 'feature-validation',
  restApi,
  softDeleteStrategy,
  queryTransform: {
    ...paginate,
  },
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['data', 'length'],
  },
});
