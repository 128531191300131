import { TextField } from 'elentari';
import React from 'react';
import Field from './Field';
import { formatOnlyNumbers } from '../utils/utils';

const Cellphone = ({ initialCellphone = '', ...props }) => {
  return (
    <Field
      label='Celular'
      name='cellphone'
      fullWidth
      parse={(value) => formatOnlyNumbers(value, '(99) 99999-9999')}
      format={(value) => formatOnlyNumbers(value, '(99) 99999-9999')}
      placeholder='(__) ______-____'
      component={TextField}
      variant='outlined'
      initialValue={initialCellphone}
      inputProps={{ inputMode: 'tel' }}
      {...props}
    />
  );
};
export default Cellphone;
