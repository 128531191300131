import EventEmitter from 'events';
import memoize from 'lodash/memoize';

export class TokenRefreshedEventEmitter extends EventEmitter {}

export const tokenRefreshedEventEmitter = new TokenRefreshedEventEmitter();

export const emitTokenRefreshedEvent = memoize((refreshToken: string) => {
  tokenRefreshedEventEmitter.emit('tokenRefreshed', refreshToken);
});
