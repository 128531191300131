import { withTransaction } from '@elastic/apm-rum-react';
import {
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AttachMoney, Cake, NewReleases } from '@material-ui/icons';
import AccountBox from '@material-ui/icons/AccountBox';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AppsIcon from '@material-ui/icons/Apps';
import Assessment from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Email from '@material-ui/icons/Email';
import FlagIcon from '@material-ui/icons/Flag';
import GroupIcon from '@material-ui/icons/Group';
import HelpOutline from '@material-ui/icons/HelpOutline';
import HistoryIcons from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LabelIcon from '@material-ui/icons/Label';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import PersonIcon from '@material-ui/icons/Person';
import RoletaIcon from '@material-ui/icons/RotateLeft';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Settings from '@material-ui/icons/Settings';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import StoreIcon from '@material-ui/icons/Store';
import RoletaClienteIcon from '@material-ui/icons/SupervisedUserCircle';
import PlaceIcon from '@material-ui/icons/Place';
import KeyIcon from '@material-ui/icons/VpnKey';
import WebIcon from '@material-ui/icons/Web';
import Work from '@material-ui/icons/Work';
import moment from 'moment';
import * as R from 'ramda';
import React, { lazy, useEffect, useState } from 'react';
import { AiOutlineQrcode } from 'react-icons/ai';
import { FiTrendingUp } from 'react-icons/fi';
import { useIdleTimer } from 'react-idle-timer';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import AppWrap from './Components/AppWrap/AppWrap';
import Loading from './Components/Loading';
import ScrollToTop from './Components/ScrollToTop';
import { ResourceAcessAuditProvider } from './hooks/useResourceAcessAudit';
import flipLetras from './icons/flip-letras-inverse.svg';
import flipLogotipo from './icons/flip-logotipo-inverse.svg';
import {
  CadastroRoletasIcon,
  DashIcon,
  DashLocacaoIcon,
  DashVendasIcon,
  RelatorioChavesIcon,
  RelatorioCondominiosIcon,
  RelatorioFechamentosIcon,
  RelatorioImoveisIcon,
  RelatorioIntegracoesIcon,
  RelatorioVisitasIcon,
} from './icons/menuIcons';
import SecureRoute from './lib/SecureRoute';
import ComissoesPage from './modules/analise-credito/comissoes';
import Notificacoes from './modules/notificacoes/NotificacoesList';
import { useAbility } from './modules/users';
import { useFeatures } from './modules/users/FeaturesContext';
import { injectKeyWildCards } from './modules/users/ability';
import { Actions, useRealtime } from './realtime/RealTimeProvider';
import BairrosList from './modules/bairros/BairrosList';

const UserTabs = lazy(() => import('./modules/users/UserTabs'));
const DashboardMeta = lazy(() => import('./modules/adm-metas/DashboardMeta'));
const AgendamentoNotificacoesForm = lazy(
  () =>
    import('./modules/agendamento-notificacoes/AgendamentoNotificacoesForm'),
);
const AgendamentoNotificacoesList = lazy(
  () =>
    import('./modules/agendamento-notificacoes/AgendamentoNotificacoesList'),
);
const AgendamentoPesquisasForm = lazy(
  () => import('./modules/agendamento-pesquisas/AgendamentoPesquisasForm'),
);
const AgendamentoPesquisasList = lazy(
  () => import('./modules/agendamento-pesquisas/AgendamentoPesquisasList'),
);
const AnaliseCreditoForm = lazy(
  () => import('./modules/analise-credito/AnaliseCreditoForm'),
);
const AnaliseCreditoListADM = lazy(
  () => import('./modules/analise-credito/AnaliseCreditoListADM'),
);
const AnaliseCreditoListTenant = lazy(
  () => import('./modules/analise-credito/AnaliseCreditoListTenant'),
);
const ContratoForm = lazy(
  () => import('./modules/analise-credito/contratos/ContratoForm'),
);
const ContratoList = lazy(
  () => import('./modules/analise-credito/contratos/ContratoList'),
);
const InadimplenciaForm = lazy(
  () => import('./modules/analise-credito/inadimplencias/InadimplenciaForm'),
);
const CalendarioAtendimento = lazy(
  () => import('./modules/atendimentos/CalendarioAtendimento'),
);
const ChaveiroEmprestimoList = lazy(
  () => import('./modules/chaveiro-emprestimo/ChaveiroEmprestimoList'),
);
const ConfiguracoesForm = lazy(
  () => import('./modules/configuracoes/ConfiguracoesForm'),
);
const CustomerTabs = lazy(() => import('./modules/customers/CustomerTabs'));
const CustomerView = lazy(() => import('./modules/customers/CustomerView'));
const CustomerList = lazy(
  () => import('./modules/customers/list/CustomerList'),
);
const DashboardAluguel = lazy(
  () => import('./modules/dashboards/aluguel/DashboardAluguel'),
);
const DashboardVendas = lazy(
  () => import('./modules/dashboards/venda/DashboardVendas'),
);
const DashboardMarketingList = lazy(
  () => import('./modules/dashboards/marketing/DashboardMarketingList'),
);

const EmpreendimentosList = lazy(
  () => import('./modules/empreendimentos/EmpreendimentosList'),
);
const EmpreendimentosTabs = lazy(
  () => import('./modules/empreendimentos/EmpreendimentosTabs'),
);
const EmpreendimentosView = lazy(
  () => import('./modules/empreendimentos/EmpreendimentosView'),
);
const EventosEnvioEmailList = lazy(
  () => import('./modules/eventos-envio-email/EventosEnvioEmailList'),
);
const EventosRoletasList = lazy(
  () => import('./modules/eventos-roletas/EventosRoletasList'),
);
const FechamentoTabs = lazy(
  () => import('./modules/fechamento/FechamentoTabs'),
);
const GerenciarRoletasList = lazy(
  () => import('./modules/roletas/GerenciarRoletasList'),
);
const Homepage = lazy(() => import('./modules/homepage/Homepage'));
const IntegrationConfigList = lazy(
  () => import('./modules/integrations/IntegrationConfigList'),
);
const IntegrationReportView = lazy(
  () => import('./modules/integrationsReport/IntegratioReportView'),
);
const IntegrationReportList = lazy(
  () => import('./modules/integrationsReport/IntegrationReportList'),
);
const MetasForm = lazy(() => import('./modules/metas/MetasForm'));
const NegociacoesList = lazy(
  () => import('./modules/negociacoes/NegociacoesList'),
);
const NovidadesSistemaForm = lazy(
  () => import('./modules/novidades-sistema/NovidadeSistemaForm'),
);
const NovidadesSistema = lazy(
  () => import('./modules/novidades-sistema/NovidadesSistema'),
);
const OfficeList = lazy(() => import('./modules/offices/OfficeList'));
const OfficeTabs = lazy(() => import('./modules/offices/OfficeTabs'));
const PagamentosList = lazy(
  () => import('./modules/pagamentos/PagamentosList'),
);
const Pesquisas = lazy(() => import('./modules/pesquisas/Pesquisas'));
const AvaliacaoCliente = lazy(
  () => import('./modules/properties/AvaliacaoCliente'),
);
const ImovelList = lazy(() => import('./modules/properties/ImovelList'));
const ImovelTabs = lazy(() => import('./modules/properties/ImovelTabs'));
const PropertyView = lazy(() => import('./modules/properties/PropertyView'));
const PropertyViewRede = lazy(
  () => import('./modules/properties/view/imoveis-rede/PropertyViewRede'),
);
const PropostaVendaForm = lazy(
  () => import('./modules/propostaVenda/PropostaVendaForm'),
);
const RecebimentosList = lazy(
  () => import('./modules/recebimentos/RecebimentosList'),
);
const RelatorioAniversariantesList = lazy(
  () =>
    import('./modules/relatorios/aniversariantes/RelatorioAniversariantesList'),
);
const RelatorioChavesList = lazy(
  () => import('./modules/relatorios/chaves/RelatorioChavesList'),
);
const RelatorioCondominiosList = lazy(
  () => import('./modules/relatorios/condomínios/RelatorioCondominiosList'),
);
const RelatorioDesempenhoList = lazy(
  () => import('./modules/relatorios/desempenho/RelatorioDesempenhoList'),
);
const RelatorioFechamentosList = lazy(
  () => import('./modules/relatorios/fechamentos/RelatorioFechamentosList'),
);
const RelatorioImoveisList = lazy(
  () => import('./modules/relatorios/imoveis/RelatorioImoveisList'),
);
const RelatorioIntegracaoLeadsList = lazy(
  () =>
    import(
      './modules/relatorios/integracao-leads/RelatorioIntegracaoLeadsList'
    ),
);
const RelatorioLeadsList = lazy(
  () => import('./modules/relatorios/leads/RelatorioLeadsList'),
);
const RelatorioMarketingList = lazy(
  () => import('./modules/relatorios/marketing/RelatorioMarketingList'),
);
const RelatorioVisitasList = lazy(
  () => import('./modules/relatorios/visitas/RelatorioVisitasList'),
);
const RentalProposalForm = lazy(
  () => import('./modules/rentalProposal/RentalProposalForm'),
);
const RoletasClientesList = lazy(
  () => import('./modules/roletas/RoletasClientesList'),
);
const RoletasList = lazy(() => import('./modules/roletas/RoletasList'));
const RoletasTabs = lazy(() => import('./modules/roletas/RoletasTabs'));
const SiteTabs = lazy(() => import('./modules/site/SiteTabs'));
const SiteNoticiaForm = lazy(
  () => import('./modules/site/blog/SiteNoticiaForm'),
);
const Tenant = lazy(() => import('../src/modules/tenants/Tenant'));
const TenantTabs = lazy(() => import('../src/modules/tenants/TenantTabs'));
const LinksForm = lazy(() => import('./modules/site/links/Form/LinksForm'));
const TagsClientesList = lazy(
  () => import('./modules/tags-clientes/TagsClientesList'),
);
const TagsForm = lazy(() => import('./modules/tags/TagsForm'));
const TagsList = lazy(() => import('./modules/tags/TagsList'));
const TeamsList = lazy(() => import('./modules/teams/TeamsList'));
const TeamsTabs = lazy(() => import('./modules/teams/TeamsTabs'));
const TeamsView = lazy(() => import('./modules/teams/TeamsView'));
const DynamicForm = lazy(
  () => import('./modules/termos-impressao/DynamicForm'),
);
const TermosImpressao = lazy(
  () => import('./modules/termos-impressao/TermosImpressao'),
);
const User = lazy(() => import('./modules/users/User'));
const VisitScheduleForm = lazy(
  () => import('./modules/visitSchedule/VisitScheduleForm'),
);
const VisitScheduleList = lazy(
  () => import('./modules/visitSchedule/VisitScheduleList'),
);
const WhatsAppIntegration = lazy(
  () => import('./modules/whats-extention/WhatsAppIntegration'),
);
const WhatsAppSync = lazy(() => import('./modules/whatsapp-sync/WhatsAppSync'));
const HistoricosTabs = lazy(
  () => import('./modules/historicos-tabs/HistoricosTabs'),
);

const superAdmin = [
  {
    label: 'Dash Meta',
    icon: FlagIcon,
    pathname: '/dashboard-meta',
  },
  {
    label: 'Imobiliárias',
    icon: AccountBox,
    pathname: '/contas',
  },
  {
    label: 'Novidades do sistema',
    icon: NewReleases,
    pathname: '/novidades-sistema',
  },
  {
    label: 'Agendamento de notificações',
    icon: ScheduleIcon,
    pathname: '/agendamento-notificacoes',
  },
  {
    label: 'Agendamento de pesquisas',
    icon: ScheduleIcon,
    pathname: '/agendamento-pesquisas',
  },
  {
    label: 'Bairros',
    icon: PlaceIcon,
    pathname: '/bairros',
  },
  {
    name: 'solicitacao-analise-credito',
    label: 'Análise de crédito',
    icon: CreditCardIcon,
    pathname: '/analise-credito',
  },
  {
    name: 'contrato-fianca-digital',
    label: 'Contratos',
    icon: AssignmentIcon,
    pathname: '/contratos',
  },
  {
    name: 'comissao-fianca-digital',
    label: 'Comissões',
    icon: AssignmentIcon,
    pathname: '/fianca-digital/comissoes',
  },
];

const immobilies = (ability, features, user) => {
  const isFeatureEnabled = (featureName) =>
    features.find((feature) => [featureName].includes(feature.titulo))?.enabled;

  return [
    {
      name: 'home',
      label: 'Home',
      pathname: '/home',
      icon: HomeIcon,
    },
    {
      name: 'calendario-atendimento',
      label: 'Calendário',
      icon: DateRangeIcon,
      pathname: '/calendario-atendimento',
    },
    {
      name: 'dashboard-venda',
      label: 'Dash Vendas',
      icon: DashVendasIcon,
      pathname: '/dashboard-vendas',
      hide:
        [
          'CORRETOR',
          'CORRETOR_1',
          'GERENTE',
          'GERENTE_PRE_ATENDIMENTO',
        ].includes(ability.label) && user?.operatingSegment === 'LOCACAO',
    },
    {
      name: 'dashboard-locacao',
      label: 'Dash Aluguéis',
      icon: DashLocacaoIcon,
      pathname: '/dashboard-alugueis',
      hide:
        [
          'CORRETOR',
          'CORRETOR_1',
          'GERENTE',
          'GERENTE_PRE_ATENDIMENTO',
        ].includes(ability.label) && user?.operatingSegment === 'VENDA',
    },
    {
      name: 'customers',
      label: 'Clientes',
      icon: PersonIcon,
      pathname: ['CORRETOR', 'CORRETOR_1'].includes(ability.label)
        ? `/clientes?responsavelId=${user?.id}&status=EM_ESPERA%2CEM_ESPERA_OFERTA_ATIVA%2CEM_ATENDIMENTO&tipos=INTERESSADO`
        : ['GERENTE', 'GERENTE_PRE_ATENDIMENTO'].includes(ability.label)
          ? `/clientes?teamId=${user?.teamId}&responsavelId=${user?.id}&status=EM_ESPERA%2CEM_ESPERA_OFERTA_ATIVA%2CEM_ATENDIMENTO&tipos=INTERESSADO`
          : '/clientes',
    },
    {
      name: 'properties',
      label: 'Imóveis',
      icon: HomeWorkIcon,
      pathname: '/imoveis?status=ATIVO%2CATIVO_NEGOCIACAO',
      hide: ability.cannot(
        'find',
        injectKeyWildCards(user, { __type: 'properties' }),
      ),
    },
    {
      name: 'empreendimentos',
      label: 'Condomínios',
      icon: ApartmentIcon,
      pathname: '/empreendimentos',
    },
    {
      name: 'rusers',
      label: 'Usuários',
      icon: AccountBox,
      pathname: '/usuarios?status=ATIVO',
    },
    {
      name: 'teams',
      label: 'Times',
      method: 'create',
      icon: GroupIcon,
      pathname: '/teams',
    },
    {
      name: 'offices',
      icon: Work,
      method: 'create',
      pathname: '/escritorios',
      label: 'Escritórios',
    },
    {
      name: 'site-imobiliaria',
      label: 'Configurações do Site',
      pathname: '/site',
      method: 'update',
      icon: WebIcon,
    },
    {
      name: 'tenant-configurations',
      label: 'Configurações',
      method: 'update',
      pathname: '/configuracoes',
      icon: Settings,
    },
    {
      name: 'acesso-historico-recursos',
      label: 'Históricos e Acessos',
      pathname: '/acesso-historico-recursos',
      icon: HistoryIcons,
    },
    {
      name: 'visit-schedule',
      label: 'Agendamentos',
      pathname: '/agendamentos?status=PENDENTE,CONFIRMADO&order=startDate desc',
      icon: ScheduleIcon,
      hide: ['CORRETOR'].includes(ability.label),
    },
    {
      name: 'negociacoes',
      label: 'Negociações',
      pathname: '/negociacoes',
      icon: CardTravelIcon,
    },
    {
      name: 'solicitacao-analise-credito',
      label: 'Análise de crédito',
      icon: CreditCardIcon,
      pathname: '/analise-credito',
      hide:
        !isFeatureEnabled('Análise de crédito') ||
        ability.cannot(
          'find',
          injectKeyWildCards(user, { __type: 'solicitacao-analise-credito' }),
        ),
    },
    {
      name: 'contrato-fianca-digital',
      label: 'Contratos',
      icon: AssignmentIcon,
      pathname: '/contratos',
      hide:
        !isFeatureEnabled('Análise de crédito') ||
        ability.cannot(
          'find',
          injectKeyWildCards(user, { __type: 'contrato-fianca-digital' }),
        ),
    },
    {
      name: 'comissao-fianca-digital',
      label: 'Comissões',
      icon: AssignmentIcon,
      pathname: '/fianca-digital/comissoes',
      hide:
        !isFeatureEnabled('Análise de crédito') ||
        ability.cannot(
          'find',
          injectKeyWildCards(user, { __type: 'contrato-fianca-digital' }),
        ),
    },
    {
      name: 'integrations-config',
      pathname: '/integrations-config',
      method: 'update',
      label: 'Integrações',
      icon: AppsIcon,
    },
    {
      name: 'integrations-reports',
      pathname: '/integrations-reports',
      label: 'Relatório de integração',
      icon: RelatorioIntegracoesIcon,
    },
    {
      name: 'eventos-envio-email',
      pathname: '/eventos-envio-email',
      label: 'E-mails enviados',
      icon: Email,
    },
    {
      name: 'chaveiro-emprestimo',
      label: 'Chaves',
      pathname: '/chaveiro-emprestimo?order=dataHoraDevolucao desc',
      icon: KeyIcon,
    },
    {
      name: 'metas',
      label: 'Metas',
      pathname: '/metas',
      icon: FiTrendingUp,
    },
    {
      name: 'roletas',
      label: 'Cadastro',
      icon: CadastroRoletasIcon,
      method: 'create',
      pathname: '/roletas',
    },
    {
      name: 'roletas',
      label: 'Roletas',
      icon: RoletaIcon,
      pathname: '/roletas-view',
      hide: ability.cannot('find', 'roletas-view'),
    },
    {
      name: 'eventos-roletas',
      label: 'Eventos',
      icon: HistoryIcons,
      pathname: '/eventos-roletas',
    },
    {
      name: 'posicoes-clientes-roletas',
      label: 'Clientes',
      icon: RoletaClienteIcon,
      pathname: '/roletas-clientes-view',
    },
    {
      name: 'relatorio-imoveis',
      label: 'Relatório de Imóveis',
      icon: RelatorioImoveisIcon,
      pathname: '/relatorio-imoveis',
    },
    {
      name: 'relatorio-condominios',
      label: 'Relatório de Condomínios',
      icon: RelatorioCondominiosIcon,
      pathname: '/relatorio-condominios',
    },
    {
      name: 'relatorio-fechamentos',
      label: 'Relatório de Fechamentos',
      icon: RelatorioFechamentosIcon,
      pathname: '/relatorio-fechamentos?dateRange=lastThirtyDays',
    },
    {
      name: 'relatorio-chaves',
      label: 'Relatório de Chaves',
      icon: RelatorioChavesIcon,
      pathname: '/relatorio-chaves',
    },
    {
      name: 'gerenciamento-bairros',
      label: 'Bairros',
      icon: PlaceIcon,
      pathname: '/bairros',
    },
    {
      name: 'relatorio-visitas',
      label: 'Relatório de Visitas',
      icon: RelatorioVisitasIcon,
      pathname: '/relatorio-visitas',
    },
    {
      name: 'relatorio-desempenho',
      label: 'Relatório de Desempenho',
      icon: DashIcon,
      pathname: '/relatorio-desempenho?dateRange=currentMonth',
    },
    {
      name: 'relatorio-leads',
      label: 'Relatório de Leads',
      icon: AccountBox,
      pathname: '/relatorio-leads',
    },
    {
      name: 'relatorio-marketing',
      label: 'Relatório de Marketing',
      icon: AccountBox,
      pathname: '/relatorio-marketing',
    },
    {
      name: 'relatorio-aniversariantes',
      label: 'Relatório de Aniversariantes',
      icon: Cake,
      pathname: `/relatorio-aniversariantes?aniversarioMin=${moment().format('DD/MM')}`,
    },
    {
      name: 'recebimentos',
      label: 'Recebimentos',
      icon: PaymentIcon,
      pathname: '/recebimentos?status=A_RECEBER%2CATRASADO',
    },
    {
      name: 'pagamentos-view',
      label: 'Pagamentos',
      icon: AttachMoney,
      pathname: '/pagamentos?status=A_PAGAR%2CATRASADA',
    },
    {
      name: 'lead-integration-reports',
      label: 'Relatório de Integração de Leads',
      icon: DashIcon,
      pathname: '/relatorio-integracao-leads',
    },
    {
      name: 'tags',
      label: 'Tags de Imóveis',
      icon: LabelIcon,
      pathname: '/tags?active=true',
      hide: ![
        'DIRETOR',
        'GERENTE',
        'GERENTE_PRE_ATENDIMENTO',
        'MARKETING',
      ].includes(ability.label),
    },
    {
      name: 'tags-clientes',
      label: 'Tags de Clientes',
      icon: LabelIcon,
      pathname: '/tags-clientes?active=true',
      hide: ![
        'DIRETOR',
        'GERENTE',
        'GERENTE_PRE_ATENDIMENTO',
        'MARKETING',
      ].includes(ability.label),
    },
    {
      name: 'termos-impressao',
      label: 'Termos para impressão',
      pathname: '/termos-impressao',
      method: 'create',
      icon: WebIcon,
    },
  ];
};

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.default,
    '& * ::-webkit-scrollbar': {
      [theme.breakpoints.up('sm')]: {
        width: '.9em!important',
        height: '.9em!important',
      },
    },
    '&::-webkit-scrollbar': {
      [theme.breakpoints.up('sm')]: {
        width: '.9em!important',
        height: '.9em!important',
      },
    },
    marginTop: 68,
    padding: 12,
    paddingBottom: 30,
  },
  notificationIcon: {
    fontSize: 40,
  },
  qrcodeIcon: {
    fontSize: 40,
    color: theme.palette.primary.main,
  },
  /* creates new keyframe for shaking the notification icon inside NotificacoesList */
  '@global': {
    '@keyframes shake': {
      '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
      '10%': { transform: 'translate(-1px, -2px) rotate(-1deg)' },
      '20%': { transform: 'translate(-3px, 0px) rotate(1deg)' },
      '30%': { transform: 'translate(3px, 2px) rotate(0deg)' },
      '40%': { transform: 'translate(1px, -1px) rotate(1deg)' },
      '50%': { transform: 'translate(-1px, 2px) rotate(-1deg)' },
      '60%': { transform: 'translate(-3px, 1px) rotate(0deg)' },
      '70%': { transform: 'translate(3px, 1px) rotate(-1deg)' },
      '80%': { transform: 'translate(-1px, -1px) rotate(1deg)' },
      '90%': { transform: 'translate(1px, 2px) rotate(0deg)' },
      '100%': { transform: 'translate(1px, -2px) rotate(-1deg)' },
    },
  },
  notificationButton: {
    padding: 4,
    color: 'inherit',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 1px',
    },
  },
  dropdown: {
    position: 'absolute',
    bottom: -80,
    left: -80,
    height: 80,
    width: 180,
    boxShadow: '1px 1px 12px #000000',
    borderRadius: '10px',
    flexDirection: 'column',
    backgroundColor: '#fff',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: -20,
      left: '60%',
      marginLeft: -8,
      borderWidth: 10,
      borderStyle: 'solid',
      borderColor: 'transparent transparent #fff transparent',
    },
  },
  dropdownItem: {
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: 'grey',
  },
  drawerHeader: {
    '&~.MuiList-root': {
      paddingBottom: 64,
    },
  },
}));

export const getMenuItems = (ability, features, user) =>
  ability.can('manage', 'tenants')
    ? superAdmin
    : immobilies(ability, features, user);

const formatMenuItems = (menuItems, groups) => {
  const isSameGroupItem = (groupItem, menuItem) => {
    return typeof groupItem === 'string'
      ? groupItem === menuItem.name
      : groupItem.name === menuItem.name &&
          groupItem.method === menuItem.method;
  };

  const groupContainsMenuItem = (group, menuItem) => {
    return (
      group.items.find((groupItem) => isSameGroupItem(groupItem, menuItem)) !=
      null
    );
  };

  const singleMenuItems = menuItems.filter((menuItem) => {
    const possuiGrupo = groups.some((group) => {
      return groupContainsMenuItem(group, menuItem);
    });
    return !possuiGrupo;
  });

  const groupItems = groups.map((group) => ({
    ...group,
    items: group.items
      .map((groupItem) =>
        menuItems.find((menuItem) => isSameGroupItem(groupItem, menuItem)),
      )
      .filter((item) => item != null),
  }));
  return [
    ...singleMenuItems,
    ...groupItems.filter((group) => group.items && group.items.length > 0),
  ];
};

const Acessos = () => <h1>Acessos</h1>;

const Logo = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignCenter: 'center',
        }}
      >
        <div>
          <a href="/">
            <img src={flipLogotipo} alt="Flip Logo" height="44px" />
          </a>
        </div>
        <div
          style={{
            whiteSpace: 'nowrap',
            marginLeft: 16,
          }}
        >
          <a href="/">
            <img src={flipLetras} alt="Flip" height="25px" />
          </a>
        </div>
      </div>
    </>
  );
};

const Home = () => {
  const classes = useStyles();
  const ability = useAbility();
  const features = useFeatures();
  const [state, dispatch] = useRealtime();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const isSuperAdmin = user?.roles === 'SUPER_ADMIN';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const location = useLocation();

  const [openListaNotificacao, setOpenListaNotificacao] = useState(false);
  const [notificacoesNaoLidas, setNotificacoesNaoLidas] = useState(null);
  const [notificacoes, setNotificacoes] = useState([]);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useIdleTimer({
    onAction: setOnlineIfAutoAbsent,
    eventsThrottle: 1000,
  });

  function setOnlineIfAutoAbsent() {
    if (
      state?.user?.statusRoleta === 'AUSENTE_AUTOMATICO' ||
      state?.user?.statusRoleta === 'OFFLINE'
    ) {
      dispatch({
        type: Actions.EDITAR_USER_STATUS_ROLETA,
        payload: { status: 'ONLINE' },
      });
    }
  }

  useEffect(() => {
    window.addEventListener('storage', () => {
      setUser(JSON.parse(localStorage.getItem('user')));
    });

    return () => {
      window.removeEventListener('storage', null);
    };
  }, []);

  useEffect(() => {
    setNotificacoesNaoLidas(
      notificacoes.filter((notificacao) => notificacao.lida === false).length,
    );
  }, [notificacoes]);

  useEffect(() => {
    if (notificacoesNaoLidas) {
      document.title = `(${notificacoesNaoLidas}) Flip CRM`;
    } else {
      document.title = 'Flip CRM';
    }
  }, [notificacoesNaoLidas]);

  useEffect(() => {
    setNotificacoes(state.notificacoes);
  }, [state.notificacoes]);

  if (!ability || !features) {
    return <Loading message="carregando..." />;
  }

  const menuItems = getMenuItems(ability, features, user)
    .filter(({ name, method = 'find' }) => ability.can(method, name))
    .filter(({ hide }) => !hide);

  const imobiliariaGroup = {
    group: true,
    icon: StoreIcon,
    label: 'Imobiliária',
    items: [
      'gerenciamento-bairros',
      'rusers',
      'teams',
      'offices',
      'acesso-historico-recursos',
      'metas',
      'tags',
      'tags-clientes',
      'integrations-config',
      'integrations-reports',
      'tenant-configurations',
      'site-imobiliaria',
      'eventos-envio-email',
      'termos-impressao',
    ],
  };

  const roletasGroup = {
    group: true,
    icon: SettingsApplications,
    label: 'Ger. Roletas',
    items: [
      { name: 'roletas', method: 'create' },
      'eventos-roletas',
      'posicoes-clientes-roletas',
    ],
  };

  const relatoriosGroup = {
    group: true,
    icon: Assessment,
    label: 'Relatórios',
    items: [
      'relatorio-imoveis',
      'relatorio-condominios',
      'relatorio-fechamentos',
      'relatorio-chaves',
      'relatorio-visitas',
      'relatorio-desempenho',
      'relatorio-leads',
      'relatorio-marketing',
      'lead-integration-reports',
      'relatorio-aniversariantes',
    ],
  };

  const comissoesGroup = {
    group: true,
    icon: AttachMoney,
    label: 'Comissões',
    items: ['recebimentos', 'pagamentos-view'],
  };

  const dashboardsGroup = {
    group: true,
    icon: DashIcon,
    label: 'Dashs',
    items: menuItems
      .filter((item) => item.name?.startsWith('dashboard'))
      .map((item) => item.name),
  };

  const fiancaDigitalGroup = {
    group: true,
    icon: CreditCardIcon,
    label: 'Fiança digital',
    items: [
      'solicitacao-analise-credito',
      'contrato-fianca-digital',
      'comissao-fianca-digital',
    ],
  };

  const groupsList = [
    roletasGroup,
    comissoesGroup,
    fiancaDigitalGroup,
    relatoriosGroup,
    imobiliariaGroup,
  ];
  const canGroupDashboard = dashboardsGroup.items.length > 1;

  // se o usuário pode ver mais de um dashboard, agrupa
  if (canGroupDashboard) {
    groupsList.push(dashboardsGroup);
  }

  const formattedMenuItems = formatMenuItems(menuItems, groupsList);

  // se os dashs estão agrupados, coloca-os na segunda posição do menu lateral
  if (canGroupDashboard) {
    const cutOut = formattedMenuItems.splice(
      formattedMenuItems.length - 1,
      1,
    )[0];
    formattedMenuItems.splice(1, 0, cutOut);
  }

  const defaultRedirect = R.pathOr('/', [0, 'pathname'], menuItems);

  const greetingLabel = () => (isMobile ? '' : 'Olá, ');
  const formattedUserName = () =>
    isMobile ? user?.name?.split(' ')?.[0] : user?.name;

  return (
    <>
      <ResourceAcessAuditProvider location={location}>
        <ScrollToTop />
        <AppWrap
          classes={classes}
          logo={<Logo />}
          pathReadableMap={{
            contatos: 'Contatos',
            users: 'Usuários',
            corretor: 'Corretor',
            members: 'Membros',
            'dados-contratuais': 'Dados Contratuais',
            fechamentos: 'Fechamentos',
            'proposta-locacao': 'Propostas Locação',
            'proposta-venda': 'Propostas Venda',
            'avaliacao-cliente': 'Avaliação Cliente',
            'novidades-sistema': 'Novidades do Sistema',
            'whatsapp-sync': 'Integração com WhatsApp',
            'agendamento-notificacoes': 'Agendamento de Notificações',
            'agendamento-pesquisas': 'Agendamento de Pesquisas',
            'relatorio-aniversariantes': 'Aniversariantes',
            'analise-credito': 'Análise de crédito',
            inadimplencias: 'Inadimplências',
            home: 'Home',
            'acesso-historico-recursos': 'Histórico e Acessos',
            'fianca-digital': 'Fiança digital',
            pagamentos: 'Pagamentos',
          }}
          userAvatarProps={{
            action: 'Sair',
            label: greetingLabel(),
            onNameClick: () =>
              isSuperAdmin ? null : history.push(`/usuarios/${user?.id}`),
            onAvatarClick: () =>
              isSuperAdmin ? null : history.push(`/usuarios/${user?.id}`),
            avatar: user?.thumbnail || 'true',
            name: formattedUserName(),
            children: (
              <div style={{ display: 'flex' }}>
                <div style={{ position: 'relative' }}>
                  {ability.can('create', 'whatsapp-sync') && !isSuperAdmin && (
                    <Tooltip title="Integração com WhatsApp">
                      <IconButton
                        onClick={() => history.push('/whatsapp-sync')}
                        className={classes.notificationButton}
                      >
                        <AiOutlineQrcode
                          className={classes.qrcodeIcon}
                          id="qrcode-icon"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  <IconButton
                    onClick={(event) => {
                      setDropdownOpen((prev) => !prev);
                      setAnchorEl(event.currentTarget);
                    }}
                    className={classes.notificationButton}
                  >
                    <HelpOutline
                      fontSize="large"
                      htmlColor="#7E51DF"
                      className={classes.notificationIcon}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={dropdownOpen}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={() => {
                      setDropdownOpen(false);
                      setAnchorEl(null);
                    }}
                  >
                    <MenuItem
                      onClick={() =>
                        window.open(
                          'https://flipcrm.freshdesk.com/support/solutions',
                          '_blank',
                        )
                      }
                    >
                      Central de ajuda
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push('/novidades-sistema');
                        setDropdownOpen(false);
                      }}
                    >
                      Novidades do sistema
                    </MenuItem>
                  </Menu>
                </div>
                <Tooltip title="Notificações">
                  <IconButton
                    onClick={() => {
                      setOpenListaNotificacao(true);
                    }}
                    className={classes.notificationButton}
                  >
                    <Badge
                      overlap="circle"
                      badgeContent={
                        notificacoesNaoLidas > 99 ? '99+' : notificacoesNaoLidas
                      }
                      color="primary"
                    >
                      <NotificationsNoneOutlinedIcon
                        fontSize="large"
                        className={classes.notificationIcon}
                        id="notification-icon"
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </div>
            ),
          }}
          menuItems={formattedMenuItems}
          hideBreadcrumb={isMobile}
          variant={isMobile ? 'temporary' : 'permanent'}
          closeOnClick={isMobile}
        >
          <Switch>
            <SecureRoute
              ability={ability}
              name="tenants"
              exact
              path="/contas"
              component={Tenant}
              isAdminRoute
              isSuperAdmin={isSuperAdmin}
            />
            <SecureRoute
              ability={ability}
              name="tenants"
              path="/contas/:id"
              component={TenantTabs}
              isAdminRoute
              isSuperAdmin={isSuperAdmin}
            />
            <SecureRoute
              ability={ability}
              name="home"
              path="/home"
              component={Homepage}
            />
            <SecureRoute
              ability={ability}
              name="agendamento-notificaces"
              exact
              path="/agendamento-notificacoes"
              component={AgendamentoNotificacoesList}
            />
            <SecureRoute
              ability={ability}
              name="agendamento-notificaces"
              path="/agendamento-notificacoes/:id"
              component={AgendamentoNotificacoesForm}
            />
            <SecureRoute
              ability={ability}
              name="agendamento-pesquisas"
              exact
              path="/agendamento-pesquisas"
              component={AgendamentoPesquisasList}
            />
            <SecureRoute
              ability={ability}
              name="agendamento-pesquisas"
              path="/agendamento-pesquisas/:id"
              component={AgendamentoPesquisasForm}
            />
            <SecureRoute
              ability={ability}
              name="properties"
              exact
              path="/imoveis"
              component={ImovelList}
              user={user}
            />
            <SecureRoute
              ability={ability}
              name="properties"
              exact
              path="/imoveis/:id/view"
              component={PropertyView}
              user={user}
            />
            <SecureRoute
              ability={ability}
              name="properties"
              exact
              path="/imoveis/:idTenantParceiro/:codigoImovel/view"
              component={PropertyViewRede}
              user={user}
            />
            <SecureRoute
              ability={ability}
              name="properties"
              exact
              path="/avaliacao-cliente/:idAvaliacao"
              component={AvaliacaoCliente}
              user={user}
            />
            <SecureRoute
              ability={ability}
              name="properties"
              path="/imoveis/:id"
              component={ImovelTabs}
              user={user}
            />
            <SecureRoute
              ability={ability}
              name="empreendimentos"
              exact
              path="/empreendimentos/:id"
              component={EmpreendimentosTabs}
              user={user}
            />
            <SecureRoute
              ability={ability}
              name="empreendimentos"
              exact
              path="/empreendimentos/:id/view"
              component={EmpreendimentosView}
              user={user}
            />
            <SecureRoute
              ability={ability}
              name="empreendimentos"
              exact
              path="/empreendimentos"
              component={EmpreendimentosList}
              user={user}
            />
            <SecureRoute
              ability={ability}
              name="acessos"
              path="/acessos"
              component={Acessos}
            />
            <SecureRoute
              ability={ability}
              name="rusers"
              exact
              path="/usuarios"
              component={User}
            />
            <SecureRoute
              ability={ability}
              name="rusers"
              path="/usuarios/:id"
              component={UserTabs}
            />
            <SecureRoute
              ability={ability}
              name="customers"
              exact
              path="/clientes"
              component={CustomerList}
            />
            <SecureRoute
              ability={ability}
              name="customers"
              exact
              path="/clientes/:id/view"
              component={CustomerView}
            />
            <SecureRoute
              ability={ability}
              name="customers"
              path="/clientes/:id"
              component={CustomerTabs}
            />
            <SecureRoute
              ability={ability}
              name="offices"
              method="create"
              exact
              path="/escritorios"
              component={OfficeList}
            />
            <SecureRoute
              ability={ability}
              name="offices"
              method="update"
              path="/escritorios/:id"
              component={OfficeTabs}
            />
            <SecureRoute
              ability={ability}
              name="site-imobiliaria"
              method="update"
              exact
              path="/site"
              component={SiteTabs}
            />
            <SecureRoute
              name="calendario-atendimento"
              exact
              path="/calendario-atendimento"
              component={CalendarioAtendimento}
            />
            <SecureRoute
              ability={ability}
              name="dashboard-venda"
              exact
              path="/dashboard-vendas"
              component={DashboardVendas}
            />
            <SecureRoute
              ability={ability}
              name="dashboard-locacao"
              exact
              path="/dashboard-alugueis"
              component={DashboardAluguel}
            />
            <SecureRoute
              ability={ability}
              name="visit-schedule"
              exact
              path="/agendamentos"
              component={VisitScheduleList}
            />
            <SecureRoute
              ability={ability}
              name="visit-schedule"
              path="/agendamentos/:id"
              component={VisitScheduleForm}
            />
            <SecureRoute
              ability={ability}
              name="propostas"
              path="/negociacoes/proposta-locacao/:id"
              component={RentalProposalForm}
            />
            <SecureRoute
              ability={ability}
              name="proposta-venda"
              path="/negociacoes/proposta-venda/:id"
              component={PropostaVendaForm}
            />
            <SecureRoute
              ability={ability}
              name="negociacoes"
              path="/negociacoes/proposta-venda"
            >
              <Redirect to="/negociacoes?tipo=VENDA" />
            </SecureRoute>
            <SecureRoute
              name="negociacoes"
              exact
              path="/negociacoes"
              component={NegociacoesList}
            />
            <SecureRoute
              ability={ability}
              name="roletas"
              method="create"
              exact
              path="/roletas"
              component={GerenciarRoletasList}
            />
            <SecureRoute
              ability={ability}
              name="roletas-view"
              exact
              path="/roletas-view"
              component={RoletasList}
            />
            <SecureRoute
              ability={ability}
              name="eventos-roletas"
              exact
              path="/eventos-roletas"
              component={EventosRoletasList}
            />
            <SecureRoute
              ability={ability}
              name="eventos-envio-email"
              exact
              path="/eventos-envio-email"
              component={EventosEnvioEmailList}
            />
            <SecureRoute
              ability={ability}
              name="posicoes-clientes-roletas"
              exact
              path="/roletas-clientes-view"
              component={RoletasClientesList}
            />
            <SecureRoute
              ability={ability}
              name="roletas"
              path="/roletas/:id"
              component={RoletasTabs}
            />
            <SecureRoute
              ability={ability}
              name="teams"
              exact
              method="create"
              path="/teams"
              component={TeamsList}
            />
            <SecureRoute
              ability={ability}
              name="teams"
              exact
              path="/teams/:id/view"
              component={TeamsView}
            />
            <SecureRoute
              ability={ability}
              name="teams"
              method="update"
              path="/teams/:id"
              component={TeamsTabs}
            />
            <SecureRoute
              ability={ability}
              method="update"
              name="integrations-config"
              path="/integrations-config"
              component={IntegrationConfigList}
            />
            <SecureRoute
              ability={ability}
              name="integrations-reports"
              exact
              path="/integrations-reports"
              component={IntegrationReportList}
            />
            <SecureRoute
              ability={ability}
              name="integrations-reports"
              path="/integrations-reports/:id/view"
              component={IntegrationReportView}
            />
            <SecureRoute
              ability={ability}
              name="tenant-configurations"
              method="update"
              path="/configuracoes"
              component={ConfiguracoesForm}
            />
            <SecureRoute
              ability={ability}
              name="chaveiro-emprestimo"
              path="/chaveiro-emprestimo"
              component={ChaveiroEmprestimoList}
            />
            <SecureRoute
              ability={ability}
              name="fechamentos"
              exact
              path="/fechamentos/:id"
              component={FechamentoTabs}
            />
            <SecureRoute
              ability={ability}
              name="fechamentos"
              exact
              path="/fechamentos"
              component={NegociacoesList}
            />
            <SecureRoute
              ability={ability}
              name="relatorio-imoveis"
              path="/relatorio-imoveis"
              component={RelatorioImoveisList}
            />
            <SecureRoute
              ability={ability}
              name="relatorio-condominios"
              path="/relatorio-condominios"
              component={RelatorioCondominiosList}
            />
            <SecureRoute
              ability={ability}
              name="relatorio-fechamentos"
              path="/relatorio-fechamentos"
              component={RelatorioFechamentosList}
            />
            <SecureRoute
              ability={ability}
              name="relatorio-chaves"
              path="/relatorio-chaves"
              component={RelatorioChavesList}
            />
            <SecureRoute
              ability={ability}
              name="relatorio-aniversariantes"
              path="/relatorio-aniversariantes"
              component={RelatorioAniversariantesList}
            />
            <SecureRoute
              ability={ability}
              name="relatorio-visitas"
              path="/relatorio-visitas"
              component={RelatorioVisitasList}
            />{' '}
            <SecureRoute
              ability={ability}
              name="gerenciamento-bairros"
              path="/bairros"
              component={BairrosList}
            />
            <SecureRoute
              ability={ability}
              name="relatorio-desempenho"
              path="/relatorio-desempenho"
              component={RelatorioDesempenhoList}
            />
            <SecureRoute
              ability={ability}
              name="relatorio-leads"
              path="/relatorio-leads"
              component={RelatorioLeadsList}
            />
            <SecureRoute
              ability={ability}
              name="relatorio-marketing"
              path="/relatorio-marketing"
              component={RelatorioMarketingList}
            />
            <SecureRoute
              ability={ability}
              name="recebimentos"
              path="/recebimentos"
              component={RecebimentosList}
            />
            <SecureRoute
              ability={ability}
              name="pagamentos-view"
              path="/pagamentos"
              component={PagamentosList}
            />
            <SecureRoute
              ability={ability}
              name="novidades-sistema"
              exact
              path="/novidades-sistema"
              component={NovidadesSistema}
            />
            <SecureRoute
              ability={ability}
              name="novidades-sistema"
              path="/novidades-sistema/:id"
              component={NovidadesSistemaForm}
            />
            <SecureRoute
              ability={ability}
              name="site-noticias"
              path="/site-noticias/:id"
              component={SiteNoticiaForm}
            />
            <SecureRoute
              ability={ability}
              name="lead-integration-reports"
              path="/relatorio-integracao-leads"
              component={RelatorioIntegracaoLeadsList}
            />
            <SecureRoute
              ability={ability}
              name="termos-impressao"
              exact
              path="/termos-impressao"
              component={TermosImpressao}
            />
            <SecureRoute
              ability={ability}
              name="termos-impressao"
              path="/termos-impressao/:id"
              component={DynamicForm}
            />
            <SecureRoute
              ability={ability}
              name="tags"
              exact
              path="/tags"
              component={TagsList}
            />
            <SecureRoute
              ability={ability}
              name="tags-clientes"
              exact
              path="/tags-clientes"
              component={TagsClientesList}
            />
            <SecureRoute
              ability={ability}
              name="tags"
              path="/tags/:id"
              component={TagsForm}
            />
            <SecureRoute
              name="metas"
              exact
              path="/metas"
              component={MetasForm}
            />
            <SecureRoute
              name="site-links"
              path="/site-links/:id"
              component={LinksForm}
            />
            <SecureRoute
              name="whatsapp-sync"
              path="/whatsapp-sync"
              component={WhatsAppSync}
            />
            <SecureRoute
              ability={ability}
              name="dashboard-meta"
              path="/dashboard-meta"
              component={DashboardMeta}
            />
            <SecureRoute
              name="pesquisas"
              path="/pesquisas/:id"
              component={Pesquisas}
            />
            <SecureRoute
              name="whatsapp-integration"
              path="/whatsapp-integration"
              component={WhatsAppIntegration}
            />
            <SecureRoute
              ability={ability}
              name="solicitacao-analise-credito"
              path="/analise-credito/:id"
              component={AnaliseCreditoForm}
              feature={isSuperAdmin ? undefined : 'Análise de crédito'}
            />
            <SecureRoute
              ability={ability}
              feature={isSuperAdmin ? undefined : 'Análise de crédito'}
              name="solicitacao-analise-credito"
              path="/analise-credito/"
              component={
                isSuperAdmin ? AnaliseCreditoListADM : AnaliseCreditoListTenant
              }
            />
            <SecureRoute
              ability={ability}
              feature={isSuperAdmin ? undefined : 'Análise de crédito'}
              name="contrato-fianca-digital"
              path="/contratos/:id"
              component={ContratoForm}
            />
            <SecureRoute
              ability={ability}
              feature={isSuperAdmin ? undefined : 'Análise de crédito'}
              name="contrato-fianca-digital"
              path="/contratos/"
              component={() => <ContratoList isSuperAdmin={isSuperAdmin} />}
            />
            <SecureRoute
              ability={ability}
              feature={isSuperAdmin ? undefined : 'Análise de crédito'}
              name="comissao-fianca-digital"
              path="/fianca-digital/:tab"
              component={ComissoesPage}
            />
            <SecureRoute
              ability={ability}
              feature={isSuperAdmin ? undefined : 'Análise de crédito'}
              name="inadimplencias-fianca-digital"
              path="/inadimplencias/:id"
              component={InadimplenciaForm}
            />
            <SecureRoute
              ability={ability}
              name="acesso-historico-recursos"
              path="/acesso-historico-recursos/"
              component={HistoricosTabs}
            />
            <Redirect
              exact
              from="/negociacoes/proposta-locacao"
              to="/negociacoes"
            />
            <Redirect from="/" to={defaultRedirect} />
          </Switch>
        </AppWrap>
        <Notificacoes
          open={openListaNotificacao}
          handleClose={() => setOpenListaNotificacao(false)}
          notificacoes={notificacoes}
          setNotificacoes={setNotificacoes}
          dispatchSockets={dispatch}
        />
      </ResourceAcessAuditProvider>
    </>
  );
};

export default withTransaction('Home', 'component')(Home);
