import React from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 'bold',
    color: 'white',
    boxShadow: '0px 4px 20px rgba(0,0,0,0.1)',
    maxWidth: 500,
  },
  arrow: {
    color: theme.palette.primary.main,
    boxShadow: '0px 4px 20px rgba(0,0,0,0.1)',
  },
}));

const Tooltip = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <MuiTooltip classes={classes} {...props}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
