import { Delete, Edit } from '@material-ui/icons';
import clsx from 'clsx';
import React, {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  InputHTMLAttributes,
  useState,
} from 'react';
import { Field } from 'react-final-form';
import { useStyles } from './style';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  defaultValue?: string;
  fullWidth?: boolean;
  imageProps?: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
}

function RFFImageBox({
  name,
  label = '250x250',
  defaultValue,
  imageProps,
  fullWidth,
  ...props
}: Props) {
  const classes = useStyles();
  const [preview, setPreview] = useState<string | null>(defaultValue ?? null);

  function handlePreview(file: FileList) {
    if (!file || file.length === 0) {
      return;
    }

    const previewURL = URL.createObjectURL(file[0]);
    setPreview(previewURL);
  }

  return (
    <Field name={name}>
      {({ input: { value, onChange, ...input } }) => (
        <div
          className={clsx(classes.imageBox, fullWidth && classes.fullWidth)}
          data-preview={!!(preview || value)}
          style={{ background: value && 'transparent' }}
        >
          <label>
            <input
              type="file"
              onChange={({ target }) => {
                handlePreview(target.files!);
                onChange(target.files);
              }}
              {...input}
              {...props}
            />

            {preview || value ? (
              <img src={preview ?? value} alt="Preview" {...imageProps} />
            ) : (
              <span>{label}</span>
            )}
          </label>
          <div className="actions">
            <button type="button">
              <Edit />
            </button>
            <button
              type="button"
              onClick={() => {
                setPreview(null);
                onChange(null);
              }}
            >
              <Delete />
            </button>
          </div>
        </div>
      )}
    </Field>
  );
}

export default RFFImageBox;
