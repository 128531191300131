import { AuthContext, loginRoutes } from 'elentari';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { restApi } from '../../services/api';

const VerifyLogin = () => {
  const { loggedin, logout } = useContext(AuthContext);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    restApi.addMonitor((response) => {
      if (loggedin) {
        if (response.status === 401) {
          logout().then(() => setExpired(true));
        } else {
          setExpired(false);
        }
      }
    });
  }, []);

  if (expired) {
    return <Redirect to={loginRoutes.login} />;
  }

  return null;
};

export default VerifyLogin;
