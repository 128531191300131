import EveGroupValue from '@elentari/components-eve/typography/GroupValue';
import React from 'react';
import { useStyles } from './Tooltip';

const GroupValue = ({
  maxLines = 1,
  showFullText = undefined,
  disableTooltip = true,
  ...props
}) => {
  const classes = useStyles();
  return (
    <EveGroupValue
      tooltipProps={{ classes }}
      disableTooltip={disableTooltip}
      valueProps={{
        style: {
          overflowWrap: 'anywhere',
          WebkitLineClamp: maxLines,
          textAlign: 'left',
          WebkitBoxOrient: showFullText ? 'horizontal' : 'vertical',
        },
      }}
      {...props}
    />
  );
};

export default GroupValue;
