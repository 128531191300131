import { useState, useEffect } from 'react';
import rolesRepository from './rolesRepository';

export const LabelRole = {
  GERENTE: 'GERENTE',
  GERENTE_PRE_ATENDIMENTO: 'GERENTE_PRE_ATENDIMENTO',
  MARKETING: 'MARKETING',
  SECRETARIA: 'SECRETARIA',
  CORRETOR: 'CORRETOR',
  CORRETOR_1: 'CORRETOR_1',
  GERENTE_ADMINISTRATIVO: 'GERENTE_ADMINISTRATIVO',
  DIRETOR: 'DIRETOR',
  SUPERVISOR: 'SUPERVISOR',
};

export const LabelRoleList = [
  { value: 'CORRETOR', label: 'Corretor' },
  { value: 'SUPERVISOR', label: 'Supervisor' },
  { value: 'DIRETOR', label: 'Diretor' },
  { value: 'GERENTE', label: 'Gerente' },
  { value: 'GERENTE_PRE_ATENDIMENTO', label: 'Gerente de pré-atendimento' },
  { value: 'GERENTE_ADMINISTRATIVO', label: 'Gerente administrativo' },
  { value: 'SECRETARIA', label: 'Secretária' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'CORRETOR_1', label: 'Corretor Sênior' },
];

export const ROLES = {
  CORRETOR: 'Corretor',
  SUPERVISOR: 'Supervisor',
  DIRETOR: 'Diretor',
  GERENTE: 'Gerente',
  GERENTE_PRE_ATENDIMENTO: 'Gerente de pré-atendimento',
  GERENTE_ADMINISTRATIVO: 'Gerente administrativo',
  SECRETARIA: 'Secretária',
  MARKETING: 'Marketing',
  CORRETOR_1: 'Corretor Sênior',
};

const useRoles = (query) => {
  const [roles, setRoles] = useState([]);

  const fetch = async () => {
    const response = await rolesRepository.list(query);
    if (response.ok) {
      setRoles(
        response.data.map((role) => {
          return {
            value: role.id,
            label: ROLES[role.label],
            key: role.label,
          };
        })
      );
    } else {
      setRoles({ label: 'Tente Novamente' });
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return roles;
};

export default useRoles;
