import { init } from "@elastic/apm-rum";
import config from "./config";

export default function initApm() {
  return init({
    active: config.apmActive,
    serviceName: config.apmServiceName,
    serverUrl: config.apmServerUrl,
    distributedTracingOrigins: config.apmDistributedTracingOrigins,
    environment: config.apmEnvironment,
    breakdownMetrics: config.apmBreakdownMetrics,
  });
}
