import { IEndereco } from '../models/adresses';

interface Condominio {
  nome?: string;
}

interface Torre {
  nome?: string;
}

export default class AddressFormatter {
  formatted = '';

  constructor(
    private endereco: Partial<IEndereco>,
    private condominio?: Condominio,
    private torre?: Torre
  ) {}

  static format(endereco: Partial<IEndereco>, condominio?: Condominio, torre?: Torre) {
    return new AddressFormatter(endereco, condominio, torre).format();
  }

  format() {
    this.formatted = '';

    this.add(null, this.endereco?.streetDescription);
    if (this.endereco?.streetDescription) {
      this.add(', ', this.endereco?.streetNumber);
    }
    this.add('. ', this.condominio?.nome);
    this.add('. ', this.torre?.nome || this.endereco?.torreBloco);
    if (this.endereco?.streetDescription && this.endereco?.streetNumber) {
      this.formatComplement();
    }
    this.add(' - ', this.endereco?.neighborhood);
    this.add(' - ', this.endereco?.city);
    this.add('/', this.endereco?.state);
    this.add(', ', this.endereco?.zipCode);

    return this.formatted;
  }

  add(separator: string | null, addressPart?: string) {
    if (addressPart) {
      if (this.formatted && separator) {
        this.formatted += separator;
      }
      this.formatted += addressPart;
    }
  }
  formatComplement() {
    const formattedNamesMap = {
      apartamento:
        this.endereco?.apartamento
          ?.replace(/\b(?:ap(?:to)?\.?|apartamento|apt\.?)\b/gi, '')
          .replace(/\./g, '')
          .trim() || null,
      torreBloco: this.endereco?.torreBloco?.replace(/\b(torre\s*|bloco\s*)/gi, '').trim() || null,
      lote: this.endereco?.lote?.replace(/\b(lote\s*)/gi, '') || null,
      quadra:
        this.endereco?.quadra
          ?.replace(/\./g, '')  
          .replace(/\b(?:quadra)\b/gi, '') 
          .replace(/\bq\b(?!\s*$)/gi, '') 
          .trim() || null
    };

    if (formattedNamesMap.apartamento) {
      this.add(', Ap. ', formattedNamesMap.apartamento);
    }
    if (formattedNamesMap.quadra) {
      this.add(', Quadra ', formattedNamesMap.quadra);
    }
    if (formattedNamesMap.lote) {
      this.add(', Lote ', formattedNamesMap.lote);
    }
    this.add('. ', this.endereco?.complement);
  }
}
