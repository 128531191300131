import jsonwebtoken from 'jsonwebtoken';

export function expiresAt(token: string) {
  const payload = jsonwebtoken.decode(token, {
    json: true,
  });
  if (!payload?.exp) {
    throw new Error('Invalid JWT payload');
  }
  return new Date(payload.exp * 1000);
}

export function issuedAt(token: string) {
  const payload = jsonwebtoken.decode(token, {
    json: true,
  });
  if (!payload?.iat) {
    throw new Error('Invalid JWT payload');
  }
  return new Date(payload.iat * 1000);
}
