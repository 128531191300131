import React from 'react';
import Field from '../Field';

interface RegisterFieldsProps {
  names: string[];
}

const RegisterFields = ({ names }: RegisterFieldsProps) => (
  <>
    {names.map((name) => (
      <Field key={name} name={name} render={() => null} />
    ))}
  </>
);

export default RegisterFields;
