import initApm from './apm';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase/app';
import Notifier from 'react-desktop-notification';
import config from './config';
import initializeSentry from './sentry';

initApm();
initializeSentry();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
});

try {
  firebase.initializeApp({
    apiKey: config.firebaseApiKey,
    authDomain: config.firebaseAuthDomain,
    projectId: config.firebaseProjectId,
    appId: config.firebaseAppId,
    messagingSenderId: config.firebaseMessagingSenderId,
  });
  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(config.firebasePublicVapidKey);
  messaging.onMessage(function (payload) {
    if (payload.notification) {
      const { title, body } = payload.notification;
      try {
        Notifier.focus(title, body);
      } catch (e) {
        serviceWorker.showNotification(title, body);
      }
    }
  });
} catch (e) {
  console.error(e);
}
