import { createBasicRepository as createRepository } from 'elentari';
import { paginate, query } from '../../lib/feathers';
import { restApi } from '../../services/api';

const props = {
  path: 'offices',
  restApi,
  queryTransform: {
    name: query.like('name'),
    ...paginate,
  },
};

export const officesRepository = createRepository(props);

export const officesSemPaginacaoRepository = createRepository({
  ...props,
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['data', 'total'],
  },
});
