import { useEffect } from 'react';
import config from '../config';

const useScript = () => {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    function initSalesMachine() {
      if (window?.salesmachine) {
        window.salesmachine.Settings(config.salesMachineKey, {});
      }
      if (window?.salesmachine && user) {
        window.salesmachine.contact(user.id, {
          name: user.name,
          email: user.email,
          account_uid: user.tenantId,
        });
        window.salesmachine.account(user.tenantId, {
          name: user.tenantName,
        });
        window.salesmachine.pageview(user.id, {
          account_uid: user.tenantId,
        });
      }
    }

    window.addEventListener
      ? window.addEventListener('load', initSalesMachine, !1)
      : window.attachEvent('load', initSalesMachine, !1);
  }, []);
};

export default useScript;
