import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import AutoComplete from './AutoComplete';

export interface AutoCompleteAdapterProps<T> {
  label: string;
  options: T[];
  parseOption: (option: T | null) => string;
  getOptionLabel: (option: T) => string;
  getOptionSelected: (option: T, value: T) => boolean;
  filterOptions?: (options: T[], state: object) => T[];
  onChange?: (value: string) => void;
  input: FieldRenderProps<string>['input'];
}

export function AutoCompleteAdapter<T>({
  input,
  parseOption,
  onChange,
  options,
  ...rest
}: AutoCompleteAdapterProps<T>) {
  const [value, setValue] = useState<T | null>(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const selectedOption = options.find((option) => parseOption(option) === input.value);
    setValue(selectedOption ?? null);
  }, [input.value, parseOption, options]);

  return (
    <AutoComplete
      {...input}
      {...rest}
      options={options}
      value={value}
      inputValue={inputValue}
      onChange={(_event: object, newValue: T) => {
        const parsedValue = parseOption(newValue);
        setValue(newValue);
        setInputValue(parsedValue);
        input.onChange(parsedValue);
        onChange?.(parsedValue);
      }}
      onInputChange={(_event: object, newValue: string) => {
        setInputValue(newValue);
      }}
    />
  );
}

export default AutoCompleteAdapter;
