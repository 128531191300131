import { withTransaction } from '@elastic/apm-rum-react';
import List from '@elentari/components-eve/components/List';
import { useListWithFilter } from '@elentari/core';
import { Fab, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import { makeStyles, useTheme } from '@material-ui/styles';
import { AuthContext } from 'elentari';
import React, { useContext, useState } from 'react';
import ConfirmModal from '../../Components/ConfirmDialog';
import EmptyComponent from '../../Components/EmptyList';
import Tooltip from '../../Components/Tooltip';
import { SnackBarContext } from '../../lib/SnackBarContext';
import { paginate } from '../../lib/feathers';
import { restApi } from '../../services/api';
import BairrosDialog from './BairrosDialog';
import BairrosFilter from './BairrosFilter.tsx';
import bairrosRepository from './BairrosRepository';
import UnificarBairrosDialog from './unificar-bairro/UnificarBairrosDialog';

const useStyles = makeStyles((theme) => ({
  fabs: {
    display: 'flex',
    gap: '8px',
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    zIndex: 10,
  },
  titleProps: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  headerProps: {
    padding: 0,
  },
  mobileList: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },
}));

const config = {
  id: 'Id',
  nomeCidade: 'Cidade',
  nomeBairro: 'Bairro',
  nomeTenant: { label: 'Imobiliária', disableOrder: true },
  actionButtons: '',
};

export const BairrosList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const context = useContext(AuthContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const setSnackbar = useContext(SnackBarContext);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    open: false,
  });
  const [openUnificarBairro, setOpenUnificarBairro] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    bairroId: null,
  });
  const isSuperAdmin = context.user?.roles === 'SUPER_ADMIN';

  const [total, setTotal] = React.useState(0);

  async function fetcher(_endpoint, { term, page, order, orderBy }) {
    const count = 15;

    const params = new URLSearchParams([
      paginate.limit(count),
      paginate.skip(page * count),
      paginate.sort(`${orderBy} ${order}`),
      ...Object.entries(term),
    ]);

    const response = await restApi.get(`${_endpoint}?${params}`);

    if (response.ok && response.data) {
      setTotal(response.data.total);
      return { tag: 'success', ...response.data };
    }

    return { tag: 'error', error: 'Erro ao carregar a lista' };
  }

  const { state, onSearch, ...rest } = useListWithFilter(
    'gerenciamento-bairros',
    fetcher,
    { defaultSort: 'id', order: 'desc' },
  );

  const formatList = (list) => {
    return list?.map((item) => {
      return {
        id: item.bairroId,
        nomeCidade: item.nomeCidade,
        nomeBairro: item.nomeBairro,
        nomeTenant: item.tenant ? item.tenant.name : '-',
        actionButtons: <DeleteField bairroId={item.bairroId} />,
      };
    });
  };

  const handleCloseDeleteDialog = () => {
    setConfirmDeleteDialog({ open: false });
  };

  const handleSubmitUnificarBairros = async (values) => {
    const response = await restApi.post(`/unificacao-bairros`, {
      idBairroOrigem: values.idBairroOrigem,
      idBairroDestino: values.idBairroDestino,
    });

    if (!response.ok) {
      setSnackbar({
        open: true,
        state: {
          message: response.data?.message,
          severity: 'error',
        },
      });
      return;
    }

    setSnackbar({
      open: true,
      state: {
        message: 'Bairros unificados com sucesso!',
      },
    });
    onSearch();
    setOpenUnificarBairro(false);
  };

  const handleSubmitDelete = async () => {
    const response = await bairrosRepository.remove({
      id: confirmDeleteDialog.bairroId,
    });
    if (response.ok) {
      setSnackbar({
        open: true,
        state: {
          message: `Bairro excluído com sucesso.`,
        },
      });
    }
    if (!response.ok) {
      setSnackbar({
        open: true,
        state: {
          message: response.data.message,
          severity: 'error',
        },
      });
    }

    handleCloseDeleteDialog();
    onSearch();
  };

  const DeleteField = ({ bairroId }) => {
    const handleClickDelete = (event) => {
      event.stopPropagation();
      setConfirmDeleteDialog({ open: true, bairroId });
    };
    return (
      <IconButton color="grey" size="small" onClick={handleClickDelete}>
        <DeleteIcon />
      </IconButton>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BairrosFilter total={total} />
        </Grid>
        <Grid item xs={12} className={classes.mobileList}>
          <List
            config={config}
            disableFilter
            onClick={(_, record) => {
              setOpenDialog({
                open: true,
                bairroId: record.id,
              });
            }}
            state={{ ...state, list: formatList(state.list) }}
            height={isMobile ? 'calc(100vh - 200px)' : 'calc(100vh - 260px)'}
            titleProps={{ className: classes.titleProps }}
            headerProps={{ spacing: 0, className: classes.headerProps }}
            EmptyComponent={EmptyComponent}
            {...rest}
          />
        </Grid>
      </Grid>
      <div className={classes.fabs}>
        {isSuperAdmin && (
          <Tooltip title="Unificar bairros">
            <Fab
              color="primary"
              aria-label="Add"
              onClick={() => setOpenUnificarBairro(true)}
            >
              <MergeTypeIcon />
            </Fab>
          </Tooltip>
        )}
        <Tooltip title="Adicionar bairro">
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() =>
              setOpenDialog({
                open: true,
                bairroId: null,
              })
            }
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      <BairrosDialog
        bairro={openDialog.bairro}
        bairroId={openDialog.bairroId}
        open={openDialog.open}
        handleClose={() => {
          setOpenDialog({
            open: false,
            bairroId: null,
          });
          onSearch();
        }}
      />
      {isSuperAdmin && (
        <UnificarBairrosDialog
          open={openUnificarBairro}
          handleClose={() => setOpenUnificarBairro(false)}
          handleSubmit={handleSubmitUnificarBairros}
          setSnackbar={setSnackbar}
        />
      )}
      <ConfirmModal
        open={confirmDeleteDialog.open}
        onDeny={handleCloseDeleteDialog}
        onAccept={handleSubmitDelete}
        onClose={handleCloseDeleteDialog}
        acceptLabel="Sim"
        denyLabel="Não"
        title="Deseja deletar o bairro selecionado?"
      />
    </>
  );
};

export default withTransaction('BairrosList', 'component')(BairrosList);
