import { createBasicRepository as createRepository } from 'elentari';
import { paginate, softDeleteStrategy } from '../../../lib/feathers';
import { BaseModel } from '../../../models/baseModel';
import { restApi } from '../../../services/api';

export interface ComissaoFiancaDigital extends BaseModel {
  id: number;
  contratoFiancaDigitalId: number;
  valorComissao: number;
  dataPrevisaoPagamento: Date;
  usuarioId: number;
  tenantId: number;
  imovelId: number;
  pagamentoId?: number;
  statusAtual: { status: OpcoesStatusComissaoFiancaDigital; observacao: string };
}

export interface ComissaoFiancaDigitalResponse {
  id: number;
  codigoImovel: string;
  imovelId: number;
  valorComissao: number;
  imobiliaria: string;
  dataPrevisaoPagamento: Date | string;
  usuario: {
    id: number;
    nome: string;
    codigoPix?: string;
    cpfCnpjPix?: string;
    beneficiarioPix?: string;
  };
  cliente: string;
  status: OpcoesStatusComissaoFiancaDigital;
  dataGeracao: Date | string | null;
  dataPagamento: Date | string | null;
}

export enum OpcoesStatusComissaoFiancaDigital {
  PENDENTE = 'PENDENTE',
  PAGO = 'PAGO',
  CANCELADO = 'CANCELADO',
}

const props = {
  path: 'comissao-fianca-digital',
  restApi,
  softDeleteStrategy,
  queryTransform: {
    ...paginate,
  },
};

export default createRepository(props);
