import findKey from 'lodash/findKey';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { restApi } from '../services/api';
import { SnackBarContext } from './SnackBarContext';

const pathMap = {
  '/imoveis': ['properties-view', 'properties-rede'],
  '/clientes': 'customers',
  '/empreendimentos': 'empreendimentos',
};

const errors = {
  '/imoveis': 'Não foi possível encontrar o imóvel',
  '/clientes': 'Não foi possível encontrar o cliente',
  '/empreendimentos': 'Não foi possível encontrar o empreendimento',
};

export const RedirectNotFound = () => {
  const [redirectPath, setRedirectPath] = useState();
  const setSnackbar = useContext(SnackBarContext);

  useEffect(() => {
    restApi.addMonitor((response) => {
      if (response.status === 404) {
        const { url } = response.config;
        setRedirectPath(
          findKey(pathMap, (path) =>
            Array.isArray(path) ? path.some((path) => url.includes(path)) : url.includes(path)
          )
        );
      } else {
        setRedirectPath(undefined);
      }
    });
  }, []);

  useEffect(() => {
    if (redirectPath) {
      setSnackbar({
        open: true,
        state: {
          message: errors[redirectPath],
          severity: 'error',
        },
      });
      setTimeout(() => {
        setSnackbar((snackbar) => ({ ...snackbar, open: false }));
      }, 2000);
    }
  }, [redirectPath, setSnackbar]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return null;
};

export default RedirectNotFound;
