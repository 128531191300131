import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = ({
  inputRef,
  onChange,
  decimalScale = 2,
  min = 0,
  max = 999999999.99,
  thousandSeparator = '.',
  ...rest
}) => {
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value && values.value !== '' ? Number(values.value) : null,
          },
        });
      }}
      isNumericString
      allowNegative={false}
      thousandSeparator={thousandSeparator}
      decimalSeparator=','
      decimalScale={decimalScale}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || (min <= floatValue && floatValue <= max);
      }}
    />
  );
};

export default NumberFormatCustom;
