import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'elentari';
import * as R from 'ramda';
import ListTile from './ListTile';

const UserAvatar = (props) => {
  const context = useContext(AuthContext);

  const logout = () => {
    context.logout && context.logout();
    props.onLogout && props.onLogout();
  };

  const username = props.name ?? R.path(['user', 'name'], context);
  return (
    <ListTile
      {...props}
      onActionClick={props.onActionClick || logout}
      name={username}
      hideBreadcrumb={props.hideBreadcrumb}
    />
  );
};

UserAvatar.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onNameClick: PropTypes.func,
  avatar: PropTypes.string,
  onAvatarClick: PropTypes.func,
  action: PropTypes.node,
  onActionClick: PropTypes.func,
  onLogout: PropTypes.func,
  hideBreadcrumb: PropTypes.bool,
};

UserAvatar.defaultProps = {
  label: 'Hello',
  name: 'Unknown',
  onNameClick: undefined,
  avatar: undefined,
  onAvatarClick: undefined,
  action: undefined,
  onActionClick: undefined,
  onLogout: undefined,
  hideBreadcrumb: false,
};

export default UserAvatar;
