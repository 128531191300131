import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'elentari';
import Route from './Route';

const PrivateRoute = ({ component: Component, render, loginPath = '/login', ...props }) => (
  <AuthContext.Consumer>
    {({ loggedin, redirectPath }) => {
      const path = redirectPath ? `${loginPath}?redirectPath=${redirectPath}` : loginPath;
      return (
        <Route
          exact
          {...props}
          render={(props) =>
            loggedin ? Component ? <Component /> : render(props) : <Redirect to={path} />
          }
        />
      );
    }}
  </AuthContext.Consumer>
);

PrivateRoute.propTypes = {
  loginPath: PropTypes.string,
  loggedin: PropTypes.bool,
};

export default PrivateRoute;
