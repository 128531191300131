import React from 'react';
import AutoComplete from '../../../Components/AutoComplete';

export const UserAutoCompleteAdapter = ({ input, ...rest }) => {
  const value = rest.options.find((user) => +user.id === +input.value) || null;
  return (
    <AutoComplete
      size='small'
      value={value}
      getOptionSelected={(option, value) => +option.id === +value.id}
      onChange={(_event, newValue) => {
        input.onChange(newValue?.id);
      }}
      {...rest}
    />
  );
};
