import React from 'react';
import { handleArrayFilterValue } from './StringArrayAutoComplete';
import AutoComplete from './AutoComplete';

export const CustomArrayAutoComplete = ({ input, ...rest }) => {
  const valueList = handleArrayFilterValue(input.value);
  const value = rest.options.filter((option) => valueList.some((value) => value === option.value));
  return (
    <AutoComplete
      disableCloseOnSelect
      multiple
      size='small'
      value={value}
      getOptionSelected={(option, value) => option.value === value.value}
      onChange={(_event, newValue) => {
        input.onChange(newValue.map((item) => item.value));
      }}
      {...rest}
    />
  );
};
