import React from 'react';
import { Switch } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';

const SwitchField = ({ input, field, label, onChange, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={input.checked}
          onChange={(_event, newValue) => {
            input.onChange(newValue);
            onChange && onChange(newValue);
          }}
          color='primary'
          {...props}
        />
      }
      label={label}
    />
  );
};

export default SwitchField;
