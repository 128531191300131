import { useEffect, useState } from 'react';
import { useAbility } from '../users/AbilityContext';
import {
  integrationsConfigRepository,
  integrationsRepository,
} from './IntegrationsConfigRepository';

interface Integration {
  id: string;
  identifier: string;
  config: IntegrationConfig;
}

interface IntegrationConfig {
  active: boolean;
  configData: any;
}

export default function useIntegration(identifier: string) {
  const ability = useAbility();
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [integration, setIntegration] = useState<Integration | null>(null);

  useEffect(() => {
    async function loadIntegrations() {
      const response = await integrationsRepository.list({
        paginate: {
          limit: -1,
        },
      });
      setIntegrations(response.data);
    }
    if (ability.can('read', 'integrations')) {
      loadIntegrations();
    }
  }, [ability]);

  useEffect(() => {
    async function loadIntegrationConfig() {
      const integration = integrations.find((integration) => integration.identifier === identifier);
      if (integration) {
        const response = await integrationsConfigRepository.list({
          query: {
            integrationId: integration.id,
          },
          paginate: {
            limit: -1,
          },
        });
        setIntegration({
          ...integration,
          config: response.data[0],
        });
      }
    }
    loadIntegrationConfig();
  }, [identifier, integrations]);

  return integration;
}
