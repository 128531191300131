import React, { useEffect } from 'react';

const useScript = ({ url }) => {
  useEffect(() => {
    function initFreshChat() {
      if (window?.fcWidget?.init) {
        window.fcWidget.init({
          token: '6ea351b3-024c-4d6d-bd0f-645b02115510',
          host: 'https://wchat.freshchat.com',
          config: {
            headerProperty: {
              direction: 'ltr',
            },
          },
        });
        const user = JSON.parse(localStorage.getItem('user'));
        window.fcWidget.user.setFirstName(user?.name);
        window.fcWidget.user.setEmail(user?.email);
        window.fcWidget.user.setProperties({
          userId: user?.id,
          tenantId: user?.tenantId,
          tenantName: user?.tenantName,
          cargo: user?.roleName,
          roleId: user?.roleId,
          teamName: user?.teamName,
          teamId: user?.teamId,
        });
      }
    }

    var scriptElement;
    function initialize(htmlDocument, elementId) {
      // eslint-disable-next-line no-unused-expressions
      htmlDocument.getElementById(elementId)
        ? initFreshChat()
        : (((scriptElement = htmlDocument.createElement('script')).id = elementId),
          (scriptElement.async = !0),
          (scriptElement.src = url),
          (scriptElement.onload = initFreshChat),
          htmlDocument.head.appendChild(scriptElement));
    }
    function initiateCall() {
      initialize(document, 'Freshdesk Messaging-js-sdk');
    }

    window.addEventListener
      ? window.addEventListener('load', initiateCall, !1)
      : window.attachEvent('load', initiateCall, !1);

    return () => {
      document.head.removeChild(scriptElement);
    };
  }, [url]);
};

export default useScript;
