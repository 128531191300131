import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Tooltip from './Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface Props {
  tooltipTitle: NonNullable<React.ReactNode>;
  children: React.ReactNode;
}

const InfoIcon = ({ tooltipTitle, children }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Grid container xs={12} alignItems="center" spacing={1} wrap="nowrap">
      <Grid item xs="auto">
        {children}
      </Grid>
      <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip open={open} title={tooltipTitle} arrow>
          <InfoOutlinedIcon
            htmlColor="#676767"
            style={{ cursor: 'pointer', scale: '0.7' }}
            onClick={() => setOpen(!open)}
            onMouseLeave={() => setOpen(false)}
            onMouseEnter={() => setOpen(true)}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default InfoIcon;
