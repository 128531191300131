import { createBasicRepository as createRepository } from 'elentari';
import { paginate } from '../../lib/feathers';
import { restApi } from '../../services/api';
import {
  pretensaoRoletas as enumsPretensoesRoletas,
  tiposRoletas as enumsTiposRoletas,
} from './enumsRoleta';
import { PretensaoInteresse } from '../../models/customer';

const props = {
  path: 'roletas',
  restApi,
  queryTransform: {
    ...paginate,
  },
};

export const roletasSemPaginacaoRepository = createRepository({
  ...props,
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['data', 'total'],
  },
});

const roletasRepository = createRepository(props);

roletasRepository.listTiposRoletasELancamentosAtivos = async (pretensao) => {
  const result = await roletasRepository.list({ query: { situacao: 'ATIVA' } });
  if (!result.ok) {
    return {
      tipos: [],
      empreendimentos: [],
      tags: [],
    };
  }

  const lancamentos = [];
  const tags = [];
  const tiposRoletas = [];
  const pretensoesRoletas = [];
  const roletas = result.data;

  result.data.forEach((roleta) => {
    roleta.lancamentos &&
      validarPretensao(roleta, pretensao) &&
      roleta.lancamentos.forEach((lancamento) => {
        if (lancamentos.find((item) => item.id === lancamento.id) == null) {
          lancamentos.includes(lancamento) || lancamentos.push(lancamento);
        }
      });

    roleta.tags &&
      validarPretensao(roleta, pretensao) &&
      roleta.tags.forEach((tag) => {
        if (tags.find((item) => item.id === tag.id) == null) {
          tags.includes(tag) || tags.push(tag);
        }
      });

    if (!tiposRoletas.includes(roleta.tipo)) {
      tiposRoletas.push(roleta.tipo);
    }

    if (!pretensoesRoletas.includes(roleta.pretensao)) {
      pretensoesRoletas.push(roleta.pretensao);
    }
  });

  const convertToTipoRoletaEnum = (tipo) =>
    enumsTiposRoletas.find((enumTipo) => enumTipo.value === tipo);

  const convertToPretensaoRoletaEnum = (tipo) =>
    enumsPretensoesRoletas.find((enumTipo) => enumTipo.value === tipo);

  return {
    tipos: tiposRoletas.map(convertToTipoRoletaEnum),
    pretensoes: pretensoesRoletas.map(convertToPretensaoRoletaEnum),
    empreendimentos: lancamentos.map((lancamento) => ({
      ...lancamento,
      label: lancamento.nome,
      value: lancamento.id,
    })),
    tags: tags.map((tag) => ({
      ...tag,
      label: tag.name,
      value: tag.id,
    })),
    roletas: roletas.map((roleta) => ({
      ...roleta,
      label: roleta.nome,
      value: roleta.id,
    })),
  };
};

export const validarPretensao = (roleta, pretensao) =>
  pretensao
    ? roleta.pretensao === PretensaoInteresse.AMBOS
      ? [
          PretensaoInteresse.LOCACAO,
          PretensaoInteresse.AMBOS,
          PretensaoInteresse.VENDA,
        ].includes(pretensao)
      : roleta.pretensao === PretensaoInteresse.LOCACAO
        ? [PretensaoInteresse.AMBOS, PretensaoInteresse.LOCACAO].includes(
            pretensao,
          )
        : [PretensaoInteresse.AMBOS, PretensaoInteresse.VENDA].includes(
            pretensao,
          )
    : true;

export default roletasRepository;
