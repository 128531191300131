import ButtonElentari from '@elentari/components-eve/components/Button';
import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import TextField from '@elentari/components-eve/final-form/TextField';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import * as yup from 'yup';
import FileUploadField from '../../../Components/FileUploadField';
import { SnackBarContext } from '../../../lib/SnackBarContext';
import yupValidation from '../../../lib/yupValidation';
import { validateDate } from '../../../utils/utils';
import { OpcoesStatusComissaoFiancaDigital } from '../comissoes/comissoesRepository';
import { IComissoesDataArray } from '../comissoes/types';
import DadosDaConta from './CadastroPagamentoDadosConta';
import Detalhes from './CadastroPagamentoDetalhes';
import pagamentoComissaoFiancaDigitalRepository from './pagamentoComissaoFiancaDigitalRepository';

interface CadastroPagamentoDialogProps {
  open: boolean;
  handleClose: () => void;
  comissoes?: IComissoesDataArray[];
  onSubmit?: () => void;
}

interface ContentProps {
  handleClose: () => void;
  comissoes?: IComissoesDataArray[];
  onSubmit?: () => void;
}

const schema = yup.object().shape({
  dataPagamento: yup.date().nullable().required('Data obrigatória'),
  comprovantePagamento: yup
    .string()
    .nullable()
    .required('Comprovante obrigatório'),
});

const Content = ({ handleClose, comissoes = [], onSubmit }: ContentProps) => {
  const setSnackbar = useContext(SnackBarContext);

  const possuiComissoesPaga = comissoes?.some((comissao) =>
    [OpcoesStatusComissaoFiancaDigital.PAGO].includes(comissao.status),
  );
  const possuiComissoesCanceladas = comissoes?.some((comissao) =>
    [OpcoesStatusComissaoFiancaDigital.CANCELADO].includes(comissao.status),
  );
  const possuiCorretorDiferente = comissoes?.some(
    (comissao) => comissao.usuario.id !== comissoes[0].usuario.id,
  );
  const possuiCorretorSemDadosPagamento = comissoes?.some(
    (comissao) => !comissao.usuario?.codigoPix,
  );
  const valorTotal = comissoes?.reduce(
    (soma: number, comissao) => soma + comissao.valorComissao,
    0,
  );

  const handleSubmit = async (values: {
    dataPagamento: Date;
    comprovante: string;
    observacao: string;
  }) => {
    const response = await pagamentoComissaoFiancaDigitalRepository.create({
      ...values,
      comissoes,
    });

    if (response.ok) {
      setSnackbar({
        open: true,
        state: {
          message: 'Pagamento cadastrado com sucesso',
        },
      });
      onSubmit && onSubmit();
    } else {
      setSnackbar({
        open: true,
        state: {
          message: 'Erro ao cadastrar pagamento',
          severity: 'error',
        },
      });
    }
  };

  const dialogMessage = () => {
    switch (true) {
      case possuiComissoesPaga && possuiCorretorDiferente:
        return 'As comissões selecionadas não são do mesmo corretor e algumas delas já estão pagas!';
      case possuiComissoesPaga:
        return 'Existem comissões selecionadas que já estão pagas!';
      case possuiCorretorDiferente:
        return 'As comissões selecionadas não são do mesmo corretor!';
      case possuiCorretorSemDadosPagamento:
        return 'Esse corretor não tem dados para pagamento de comissão configurado.';
      default:
        return 'Existem comissões selecionadas que estão canceladas!';
    }
  };

  if (
    possuiComissoesPaga ||
    possuiCorretorDiferente ||
    possuiComissoesCanceladas ||
    possuiCorretorSemDadosPagamento
  ) {
    return (
      <DialogContent style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <DialogContentText>
          <Typography>{dialogMessage()}</Typography>
        </DialogContentText>
        <DialogActions>
          <ButtonElentari
            onClick={handleClose}
            color="primary"
            variant="contained"
            style={{ height: 'auto', borderRadius: '5px' }}
          >
            OK
          </ButtonElentari>
        </DialogActions>
      </DialogContent>
    );
  } else {
    return (
      <Form
        validate={yupValidation(schema)}
        onSubmit={handleSubmit}
        initialValues={{ dataPagamento: new Date() }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle style={{ textAlign: 'center', padding: '10px 24px' }}>
              Detalhes do pagamento
            </DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Detalhes
                    imobiliaria={comissoes[0]?.imobiliaria}
                    quantidadeComissoes={comissoes?.length}
                    usuario={comissoes[0]?.usuario.name}
                    valorTotal={valorTotal}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DadosDaConta usuario={comissoes[0]?.usuario} />
                </Grid>
                <Grid item container xs={12} spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      name="dataPagamento"
                      label="Data do pagamento"
                      format="DD/MM/yyyy"
                      validate={validateDate}
                      fullWidth
                      disablePast
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FileUploadField
                      name="comprovantePagamento"
                      label="Anexar comprovante"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Observação"
                      name="observacao"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonElentari onClick={handleClose} color="secondary">
                Fechar
              </ButtonElentari>
              <ButtonElentari
                onClick={() => form.submit()}
                color="primary"
                autoFocus
              >
                Pagar
              </ButtonElentari>
            </DialogActions>
          </form>
        )}
      />
    );
  }
};

const CadastroPagamentoDialog = ({
  comissoes,
  open,
  handleClose,
  onSubmit,
}: CadastroPagamentoDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Content
        handleClose={handleClose}
        comissoes={comissoes}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
};

export default CadastroPagamentoDialog;
