import { useContext, useEffect, useState } from 'react';
import { SnackBarContext } from '../../lib/SnackBarContext';
import { restApi } from '../../services/api';

const initialState = {
  authorized: true,
  url: '',
};

const VerifyAuthorization = () => {
  const [state, setState] = useState(initialState);
  const setSnackbar = useContext(SnackBarContext);

  useEffect(() => {
    restApi.addMonitor(async (response) => {
      if (response.status !== 403 || response.config.method.toUpperCase() !== 'GET') {
        return;
      }

      if (response.config.url.split('/')[0] === 'customers' && response.data.message !== 'Error') {
        setState({
          authorized: false,
          url: response.config.url,
          message: response.data.message,
        });
        return;
      }

      setState({
        authorized: false,
        url: response.config.url,
      });
    });
  }, []);

  useEffect(() => {
    if (state.authorized) {
      return;
    }
    setSnackbar({
      open: true,
      state: {
        message: state.message || `Usuário não tem permissão de acesso a '${state.url}'.`,
        severity: 'warning',
      },
    });

    setState(initialState);
  }, [state, setSnackbar]);

  return null;
};

export default VerifyAuthorization;
