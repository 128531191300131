import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import EveButton from '@elentari/components-eve/components/Button';

const useStyles = makeStyles((theme) => ({
  buttonActions: {
    textTransform: 'none',
    height: theme.spacing(4),
  },
}));

const FilterActions = ({
  labels = { find: 'Buscar', clear: 'Limpar' },
  fetching,
  onClear = () => {},
}) => {
  const classes = useStyles();
  return (
    <Grid container item justify='flex-end' alignItems='center' spacing={1}>
      <Grid item xs={6}>
        <EveButton className={classes.buttonActions} fullWidth variant='outlined' onClick={onClear}>
          {labels && labels.clear}
        </EveButton>
      </Grid>
      <Grid item xs={6}>
        <EveButton
          fullWidth
          className={classes.buttonActions}
          type='submit'
          color='primary'
          variant='outlined'
          disabled={fetching}
        >
          {fetching && <CircularProgress color='inherit' size={20} />}
          {labels && labels.find}
        </EveButton>
      </Grid>
    </Grid>
  );
};

export default FilterActions;
