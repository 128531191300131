import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '24px',
    height: '24px',
  },
  svg: {
    display: 'block',
    margin: 'auto',
  },
}));

export const DashIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 13 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.svg}
      >
        <rect width='13' height='13' rx='1.5' fill='#9E9E9E' />
        <path
          d='M2.88867 8.30545L5.41645 5.41656L7.58312 7.22211L9.74978 4.69434'
          stroke='#263238'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export const DashVendasIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 13 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.svg}
      >
        <rect width='13' height='13' rx='1.5' fill='#9E9E9E' />
        <path
          d='M2.88867 6.61111L5.41645 3.72222L7.58312 5.52778L9.74978 3'
          stroke='#263238'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.992 11L8.02 8.2H8.524L9.268 10.444L10.016 8.2H10.516L9.544 11H8.992Z'
          fill='#263238'
        />
      </svg>
    </div>
  );
};

export const DashLocacaoIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 13 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.svg}
      >
        <rect width='13' height='13' rx='1.5' fill='#9E9E9E' />
        <path
          d='M10.104 10L9.908 9.436H8.716L8.52 10H8.02L9.036 7.2H9.588L10.6 10H10.104ZM8.868 9.004H9.756L9.312 7.74L8.868 9.004Z'
          fill='#263238'
        />
        <path
          d='M2.88867 6.61111L5.41645 3.72222L7.58312 5.52778L9.74978 3'
          stroke='#263238'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export const DashRoletaIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 13 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.svg}
      >
        <rect width='13' height='13' rx='1.5' fill='#9E9E9E' />
        <path
          d='M2.88867 6.61111L5.41645 3.72222L7.58312 5.52778L9.74978 3'
          stroke='#263238'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export const RelatorioImoveisIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.svg}
      >
        <rect width='14' height='14' rx='1.5' fill='#9E9E9E' />
        <path
          d='M5.25909 4.3501L2 6.7401V12.0001H4.27273V8.0001H6.09091V12.0001H8.36364V6.6251L5.25909 4.3501Z'
          fill='#263238'
        />
        <path
          d='M6.09082 3V3.755L6.99991 4.42L7.78627 5H8.36355V5.425L9.27264 6.095V7H10.1817V8H9.27264V9H10.1817V10H9.27264V12H11.9999V3H6.09082ZM10.1817 6H9.27264V5H10.1817V6Z'
          fill='#263238'
        />
      </svg>
    </div>
  );
};

export const RelatorioCondominiosIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.svg}
      >
        <rect width='14' height='14' rx='1.5' fill='#9E9E9E' />
        <path
          d='M9.77778 6.88889V2H4.22222V4.44444H2V13H6.44444V10.5556H7.55556V13H12V6.88889H9.77778ZM4.22222 11.7778H3.11111V10.5556H4.22222V11.7778ZM4.22222 9.33333H3.11111V8.11111H4.22222V9.33333ZM4.22222 6.88889H3.11111V5.66667H4.22222V6.88889ZM6.44444 9.33333H5.33333V8.11111H6.44444V9.33333ZM6.44444 6.88889H5.33333V5.66667H6.44444V6.88889ZM6.44444 4.44444H5.33333V3.22222H6.44444V4.44444ZM8.66667 9.33333H7.55556V8.11111H8.66667V9.33333ZM8.66667 6.88889H7.55556V5.66667H8.66667V6.88889ZM8.66667 4.44444H7.55556V3.22222H8.66667V4.44444ZM10.8889 11.7778H9.77778V10.5556H10.8889V11.7778ZM10.8889 9.33333H9.77778V8.11111H10.8889V9.33333Z'
          fill='#263238'
        />
      </svg>
    </div>
  );
};

export const RelatorioVisitasIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M7 11C4.79 11 3 9.21 3 7C3 4.79 4.79 3 7 3C9.21 3 11 4.79 11 7C11 9.21 9.21 11 7 11ZM6.5 4.5H7.25V7.125L9.5 8.46L9.125 9.075L6.5 7.5V4.5Z'
          fill='#9E9E9E'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M0 1.5C0 0.671573 0.671573 0 1.5 0H12.5C13.3284 0 14 0.671573 14 1.5V12.5C14 13.3284 13.3284 14 12.5 14H1.5C0.671573 14 0 13.3284 0 12.5V1.5ZM6.995 2C4.235 2 2 4.24 2 7C2 9.76 4.235 12 6.995 12C9.76 12 12 9.76 12 7C12 4.24 9.76 2 6.995 2Z'
          fill='#9E9E9E'
        />
      </svg>
    </div>
  );
};

export const RelatorioFechamentosIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.svg}
      >
        <rect width='14' height='14' rx='1.5' fill='#9E9E9E' />
        <path
          d='M11 4.68421H9.5V3.84211C9.5 3.37474 9.055 3 8.5 3H5.5C4.945 3 4.5 3.37474 4.5 3.84211V4.68421H3C2.445 4.68421 2 5.05895 2 5.52632V10.1579C2 10.6253 2.445 11 3 11H11C11.555 11 12 10.6253 12 10.1579V5.52632C12 5.05895 11.555 4.68421 11 4.68421ZM5.5 3.84211H8.5V4.68421H5.5V3.84211ZM11 10.1579H3V9.31579H11V10.1579ZM11 8.05263H3V5.52632H4.5V6.36842H5.5V5.52632H8.5V6.36842H9.5V5.52632H11V8.05263Z'
          fill='#263238'
        />
      </svg>
    </div>
  );
};

export const RelatorioChavesIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.svg}
      >
        <rect width='14' height='14' rx='1.5' fill='#9E9E9E' />
        <path
          d='M7.29545 6C6.92273 4.835 5.91364 4 4.72727 4C3.22273 4 2 5.345 2 7C2 8.655 3.22273 10 4.72727 10C5.91364 10 6.92273 9.165 7.29545 8H9.27273V10H11.0909V8H12V6H7.29545ZM4.72727 8C4.22727 8 3.81818 7.55 3.81818 7C3.81818 6.45 4.22727 6 4.72727 6C5.22727 6 5.63636 6.45 5.63636 7C5.63636 7.55 5.22727 8 4.72727 8Z'
          fill='#263238'
        />
      </svg>
    </div>
  );
};

export const RelatorioIntegracoesIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classes.svg}
      >
        <rect width='14' height='14' rx='1.5' fill='#9E9E9E' />
        <path
          d='M3 5H5V3H3V5ZM6 11H8V9H6V11ZM3 11H5V9H3V11ZM3 8H5V6H3V8ZM6 8H8V6H6V8ZM9 3V5H11V3H9ZM6 5H8V3H6V5ZM9 8H11V6H9V8ZM9 11H11V9H9V11Z'
          fill='#263238'
        />
      </svg>
    </div>
  );
};

export const CadastroRoletasIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        enable-background='new 0 0 24 24'
        height='24px'
        viewBox='0 0 24 24'
        width='24px'
        fill='#9E9E9E'
        className={classes.svg}
      >
        <g>
          <rect fill='none' height='24' width='24' />
          <path d='M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M13,17H8c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1h5c0.55,0,1,0.45,1,1C14,16.55,13.55,17,13,17z M16,13H8c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1h8 c0.55,0,1,0.45,1,1C17,12.55,16.55,13,16,13z M16,9H8C7.45,9,7,8.55,7,8c0-0.55,0.45-1,1-1h8c0.55,0,1,0.45,1,1 C17,8.55,16.55,9,16,9z' />
        </g>
      </svg>
    </div>
  );
};
