import {
  IBairro,
  ICidade,
  IEndereco,
  IEstado,
  IPais,
} from '../models/adresses';
import IEmpreendimento from '../models/empreendimento';
import { restApi } from '../services/api';

interface EnderecoByPaisResponse {
  pais: IPais;
  estados?: IEstado[];
}

interface EnderecoByEstadoResponse {
  estado: IEstado;
  cidades?: ICidade[];
}

interface EnderecoByCidadeResponse {
  cidade: ICidade;
  bairros?: IBairro[];
}

export interface EnderecoByCepResponse {
  cep: string;
  pais?: IPais;
  estado?: IEstado;
  cidade?: ICidade;
  bairro?: IBairro;
}

interface QueryOptions {
  limit?: number;
}

export const formatEmpreendimento = (
  endereco: IEndereco,
  condominio: IEmpreendimento,
) => {
  if (!endereco) {
    return '';
  }

  return `${endereco.streetDescription}, ${endereco.streetNumber}${
    condominio && condominio.status_comercial === 'CONDOMINIO'
      ? `. ${condominio.nome}`
      : ''
  }${endereco?.complement ? `. ${endereco.complement}` : ''} - ${endereco.neighborhood} - ${
    endereco.city
  }/${endereco.state}`;
};

export class EnderecoFetcher {
  async getPaisListByTerm(
    term?: string,
    options?: QueryOptions,
  ): Promise<IPais[]> {
    const response = await restApi.get<IPais[]>('/busca-endereco', {
      ...(term ? { term } : {}),
      ...(options?.limit ? { $limit: options.limit } : {}),
    });

    if (response.ok && response.data) {
      return response.data;
    }

    return [];
  }

  async getEstadoListByPaisId(
    paisId: number,
    term?: string,
    options?: QueryOptions,
  ): Promise<IEstado[]> {
    const response = await restApi.get<EnderecoByPaisResponse>(
      '/busca-endereco',
      {
        paisId,
        ...(term ? { term } : {}),
        ...(options?.limit ? { $limit: options.limit } : {}),
      },
    );

    if (response.ok && response.data?.estados) {
      return response.data.estados;
    }

    return [];
  }

  async getCidadeListByEstadoId(
    estadoId: number,
    term?: string,
    options?: QueryOptions,
  ): Promise<ICidade[]> {
    const response = await restApi.get<EnderecoByEstadoResponse>(
      '/busca-endereco',
      {
        estadoId,
        ...(term ? { term } : {}),
        ...(options?.limit ? { $limit: options.limit } : {}),
      },
    );

    if (response.ok && response.data?.cidades) {
      return response.data.cidades;
    }

    return [];
  }

  async getBairroListByCidadeId(
    cidadeId: number,
    term?: string,
    options?: QueryOptions,
  ): Promise<IBairro[]> {
    const response = await restApi.get<EnderecoByCidadeResponse>(
      '/busca-endereco',
      {
        cidadeId,
        ...(term ? { term } : {}),
        ...(options?.limit ? { $limit: options.limit } : {}),
      },
    );

    if (response.ok && response.data?.bairros) {
      return response.data.bairros;
    }

    return [];
  }

  async getEnderecoByCep(cep: string): Promise<EnderecoByCepResponse> {
    const response = await restApi.get<EnderecoByCepResponse>(
      '/busca-endereco',
      {
        cep,
      },
    );
    return response.data || { cep };
  }

  async getPaisById(paisId: number): Promise<IPais | undefined> {
    const response = await restApi.get<EnderecoByPaisResponse>(
      '/busca-endereco',
      {
        paisId,
      },
    );

    if (response.ok && response.data) {
      return response.data.pais;
    }
  }

  async getEstadoById(estadoId: number): Promise<IEstado | undefined> {
    const response = await restApi.get<EnderecoByEstadoResponse>(
      '/busca-endereco',
      {
        estadoId,
      },
    );

    if (response.ok && response.data?.cidades) {
      return response.data.estado;
    }
  }

  async getCidadeById(cidadeId: number): Promise<ICidade | undefined> {
    const response = await restApi.get<EnderecoByCidadeResponse>(
      '/busca-endereco',
      {
        cidadeId,
      },
    );

    if (response.ok && response.data) {
      return response.data.cidade;
    }
  }

  async getBairroById(bairroId: number): Promise<IBairro | undefined> {
    const response = await restApi.get<IBairro>('/busca-endereco', {
      bairroId,
    });

    if (response.ok && response.data) {
      return response.data;
    }
  }
}
