import { createBasicRepository as createRepository } from 'elentari';
import { paginate, softDeleteStrategy, query } from '../../lib/feathers';
import { restApi } from '../../services/api';

export const integrationsConfigRepository = createRepository({
  path: 'integrations-config',
  restApi,
  softDeleteStrategy,
  queryTransform: {
    ...paginate,
  },
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['data', 'total'],
  },
});

export const integrationsRepository = createRepository({
  path: 'integrations',
  restApi,
  softDeleteStrategy,
  queryTransform: {
    name: query.like('name'),
    ...paginate,
  },
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['data', 'total'],
  },
});

export const integrationsAutocompleteRepository = createRepository({
  path: 'integrations',
  restApi,
  softDeleteStrategy,
  queryTransform: {
    name: query.like('name'),
    ...paginate,
  },
});

export const batchRepository = createRepository({
  path: 'batch',
  restApi,
  softDeleteStrategy,
});
