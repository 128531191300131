import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import AutoCompleteAdapter from '../../Components/AutoCompleteAdapter';
import Field from '../../Components/Field';
import { Chat2DeskUser } from './chat2DeskRepository';
import { useChat2DeskRepository } from './useChat2DeskRepository';

interface Chat2DeskUserFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
  apiToken?: string;
}

export default function Chat2DeskUserField(props: Chat2DeskUserFieldProps) {
  const chat2DeskRepository = useChat2DeskRepository(props.apiToken);
  const [chat2DeskUsers, setChat2DeskUsers] = useState<Chat2DeskUser[]>([]);

  useEffect(() => {
    if (chat2DeskRepository) {
      chat2DeskRepository
        .getUsers()
        .then(setChat2DeskUsers)
        .catch((error) => {
          console.error(error);
        });
    }
  }, [chat2DeskRepository]);

  return (
    <Field
      {...props}
      component={AutoCompleteAdapter}
      options={chat2DeskUsers}
      filterOptions={filterByNameAndEmail}
      parseOption={(option: Chat2DeskUser | null) => option?.id ?? null}
      getOptionLabel={(option: Chat2DeskUser) => `${option.name} (${option.email})`}
      getOptionSelected={(option: Chat2DeskUser, value: Chat2DeskUser) => option.id === value.id}
    />
  );
}

function filterByNameAndEmail(options: Chat2DeskUser[], { inputValue: term = '' }) {
  return matchSorter(options, term, { keys: ['name', 'email'] });
}
