import {
  Card,
  CardActions,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import moment from 'moment';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { default as React, forwardRef, useCallback, useEffect, useState } from 'react';
import CountdownLinearProgress from '../../../../Components/CountdownLinearProgressProps';
import { INotificacoes } from '../../../../models/notifications';
import CombinacaoRoletaSnackbar from './CombinacaoRoletaSnackbar';
import TransferenciaClienteSnackbar from './TransferenciaClienteSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    '@media (min-width:600px)': {
      width: theme.spacing(50),
    },
  },
  card: {
    width: '100%',
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    color: '#000',
    transition: 'all .2s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    backgroundColor: '#fff',
    padding: 16,
    paddingBottom: 0,
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

interface NovoClienteSnackbarProps extends CustomContentProps {
  notificacao: INotificacoes;
  atualizaNotificacaoLida: (notificacao: INotificacoes) => void;
  expandedDelay: number;
}

const NovoClienteSnackbar = forwardRef<HTMLDivElement, NovoClienteSnackbarProps>(
  ({ id, notificacao, atualizaNotificacaoLida, expandedDelay, ...props }, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState({ isExpanded: true, userExpanded: false });
    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => ({ isExpanded: !oldExpanded.isExpanded, userExpanded: true }));
    }, []);
    const tempoLimiteAtendimentoMs = moment
      .duration(notificacao.conteudo?.tempoLimiteAtendimentoMinutos, 'minutes')
      .asMilliseconds();

    useEffect(() => {
      let timeoutId: NodeJS.Timeout;

      if (expandedDelay && expanded.isExpanded && !expanded.userExpanded) {
        timeoutId = setTimeout(() => {
          setExpanded({ isExpanded: false, userExpanded: false });
        }, expandedDelay);
      }

      return () => {
        clearTimeout(timeoutId);
      };
    }, [expanded]);

    useEffect(() => {
      let timeoutId: NodeJS.Timeout;

      if (props.autoHideDuration)
        timeoutId = setTimeout(() => {
          closeSnackbar(id);
        }, props.autoHideDuration);
      return () => {
        clearTimeout(timeoutId);
      };
    }, []);

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
      atualizaNotificacaoLida(notificacao);
    }, [id, closeSnackbar]);

    const modelRenderMap: any = {
      TRANSFERENCIA: (
        <TransferenciaClienteSnackbar notificacao={notificacao} onClose={handleDismiss} />
      ),
      COMBINACAO: <CombinacaoRoletaSnackbar notificacao={notificacao} onClose={handleDismiss} />,
    };

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Card className={classes.card} style={{ backgroundColor: '#FFF' }} elevation={3}>
          <CardActions classes={{ root: classes.actionRoot }}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs='auto'>
                  <PersonIcon htmlColor='#0000008a' />
                </Grid>
                <Grid item xs>
                  <Typography variant='body1' className={classes.typography}>
                    {props.message}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.icons}>
              <IconButton
                size='small'
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded.isExpanded,
                })}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
              <IconButton size='small' className={classes.expand} onClick={handleDismiss}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </Grid>
          </CardActions>
          <Collapse in={expanded.isExpanded} timeout='auto'>
            <Paper className={classes.paper}>
              <Grid container direction='column' spacing={1}>
                <Grid item xs={12}>
                  {notificacao ? modelRenderMap[notificacao?.conteudo?.tipo] : null}
                </Grid>
                <Grid item xs={12}>
                  {props.autoHideDuration && (
                    <CountdownLinearProgress
                      valorInicial={props.autoHideDuration}
                      valorTotal={tempoLimiteAtendimentoMs}
                      showTimer
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Collapse>
        </Card>
      </SnackbarContent>
    );
  }
);

NovoClienteSnackbar.displayName = 'NovoCliente';

export default NovoClienteSnackbar;
