import React, { useState } from 'react';
import AsyncAutoComplete from './AsyncAutoComplete';

export const StringArrayAsyncAutoComplete = ({
  input,
  converter = (value) => value,
  parserOption = (value) => value,
  onChange,
  ...rest
}) => {
  const value = handleArrayFilterValue(input.value).map(converter);
  const [parsedValue, setParsedValue] = useState();

  const handleAutocompleteChange = (_, newValue) => {
    const newParsedValue = newValue.map(parserOption);
    setParsedValue(newParsedValue);
    input.onChange(newParsedValue);
  };

  const handleBlur = () => {
    if (parsedValue !== undefined && onChange) {
      onChange(parsedValue);
    }
  };

  return (
    <AsyncAutoComplete
      disableCloseOnSelect
      multiple
      value={value}
      onChange={handleAutocompleteChange}
      onBlur={handleBlur}
      name={input.name}
      {...rest}
    />
  );
};

export const handleArrayFilterValue = (value) => {
  if (!value) {
    return [];
  }
  return typeof value === 'string' ? value.split(',') : value;
};
