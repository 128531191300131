import { TextField } from 'elentari';
import React from 'react';
import Field from './Field';
import { formatOnlyNumbers } from '../utils/utils';

const Telephone = ({ initialTelefone = '', ...props }) => {
  return (
    <Field
      label='Telefone'
      name='telephone'
      fullWidth
      parse={(value) => formatOnlyNumbers(value, '(99) 9999-9999')}
      format={(value) => formatOnlyNumbers(value, '(99) 9999-9999')}
      placeholder='(__) ______-____'
      component={TextField}
      variant='outlined'
      initialValue={initialTelefone}
      inputProps={{ inputMode: 'tel' }}
      {...props}
    />
  );
};
export default Telephone;
