export const getContratoStatusLabel = (status: string) => {
  switch (status) {
    case 'ATIVO':
      return 'Ativo';
    case 'EM_RESCISAO':
      return 'Em rescisão';
    case 'RESCINDIDO_IMOBILIARIA':
      return 'Rescindido pela imobiliária';
    case 'RESCINDIDO_FLIP':
      return 'Rescindido';
    case 'CANCELADO_IMOBILIARIA':
      return 'Cancelado pela imobiliária';
    case 'VENCIDO':
      return 'Vencido';
    default:
      return '';
  }
};

export const getContratoStatusColor = (status: string) => {
  switch (status) {
    case 'ATIVO':
      return '#50DFB3';
    case 'EM_RESCISAO':
      return '#FF9900';
    case 'RESCINDIDO_FLIP':
      return '#A249F5';
    case 'RESCINDIDO_IMOBILIARIA':
      return '#F5494C';
    case 'VENCIDO':
      return '#666666';
    case 'CANCELADO_IMOBILIARIA':
      return '#990000';
    default:
      return '';
  }
};

export const STATUS_CONTRATO_FIANCA_DIGITAL = [
  { label: 'Ativo', value: 'ATIVO' },
  { label: 'Em rescisão', value: 'EM_RESCISAO' },
  { label: 'Rescindido', value: 'RESCINDIDO_FLIP' },
  { label: 'Rescindido pela imobiliária', value: 'RESCINDIDO_IMOBILIARIA' },
  { label: 'Cancelado pela imobiliária', value: 'CANCELADO_IMOBILIARIA' },
  { label: 'Vencido', value: 'VENCIDO' },
];

export enum statusContratoEnum {
  ATIVO = 'ATIVO',
  EM_RESCISAO = 'EM_RESCISAO',
  RESCINDIDO_IMOBILIARIA = 'RESCINDIDO_IMOBILIARIA',
  RESCINDIDO_FLIP = 'RESCINDIDO_FLIP',
  CANCELADO_IMOBILIARIA = 'CANCELADO_IMOBILIARIA',
  VENCIDO = 'VENCIDO',
}

export enum TipoArquivoContrato {
  CONTRATO = 'CONTRATO',
  VISTORIA = 'VISTORIA',
  SEGURO_OBRIGATORIO = 'SEGURO_OBRIGATORIO',
}

export const tipoArquivoContratoLabel = {
  CONTRATO: 'Contrato de locação',
  VISTORIA: 'Vistoria',
  SEGURO_OBRIGATORIO: 'Seguro incêndio',
};

export const TIPO_ARQUIVO_CONTRATO = [
  { label: 'Contrato de locação', tipo: TipoArquivoContrato.CONTRATO },
  { label: 'Vistoria', tipo: TipoArquivoContrato.VISTORIA },
  { label: 'Seguro incêndio', tipo: TipoArquivoContrato.SEGURO_OBRIGATORIO },
];
