import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { resourceAccessAuditRepository } from '../modules/resource-access-audit/resourceAccessAudit';
import { formatTabs } from '../modules/resource-access-audit/utils';

export const ResourceAcessAuditContext = createContext({});

const initialState: ResourceAcessDocument = {
  paginaOrigem: '',
  parametrosUrl: '',
  acessadoPor: {
    id: undefined,
    tenantId: undefined,
    nome: '',
  },
};

const isImovelPage = (state: any) => {
  return state.paginaOrigem.includes('imoveis');
};

const shouldSubmitProprietarios = (state: any) => {
  return (
    (isImovelPage(state) &&
      state.paginaOrigem.includes('view') &&
      state.parametrosUrl.includes('?tab=6')) ||
    (isImovelPage(state) && !state.paginaOrigem.includes('view'))
  );
};

export interface ResourceAcessDocument {
  paginaOrigem: string;
  parametrosUrl: string;
  aba?: string;
  acessadoPor: {
    id?: number;
    nome: string;
    tenantId?: number;
  };
  dadosUsuario?: {
    id?: number;
    nome: string;
    telefone?: string;
  };
  dadosCliente?: {
    id?: number;
    nome?: string;
    telefones?: string[];
    isProprietario: boolean;
  };
  dadosImovel?: {
    id?: number;
    codigoImovel?: string;
    empreendimentoId?: number;
    nomeEmpreendimento?: string;
  };
  dadosEmpreendimento?: {
    id?: number;
    nome?: string;
  };
  dataAcesso?: Date;
}

enum ActionsTypes {
  ACESSADO_POR = 'acessadoPor',
  DADOS_CLIENTES = 'dadosCliente',
  DADOS_IMOVEL = 'dadosImovel',
  DADOS_EMPREENDIMENTO = 'dadosEmpreendimento',
  ATUALIZAR_LOCATION = 'atualizarLocation',
  PATCH_ID = 'patchId',
}

export const Actions = {
  ATUALIZAR_LOCATION: 'atualizarLocation',
  ATUALIZAR_ACESSADO_POR: 'acessadoPor',
  ATUALIZAR_DADOS_USUARIO: 'dadosUsuario',
  ATUALIZAR_CLIENTE: 'dadosCliente',
  ATUALIZAR_IMOVEL: 'dadosImovel',
  ATUALIZAR_EMPREENDIMENTO: 'dadosEmpreendimento',
  PATCH_ID: 'patchId',
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.ATUALIZAR_LOCATION: {
      const { paginaOrigem, parametrosUrl, aba } = formatTabs(
        action.payload.paginaOrigem,
        action.payload.parametrosUrl
      );
      return {
        ...state,
        paginaOrigem,
        parametrosUrl,
        aba: action.payload.paginaOrigem === state.paginaOrigem ? aba : null,
        _id: action.payload.paginaOrigem === state.paginaOrigem ? state._id : null,
        dadosUsuario:
          action.payload.paginaOrigem === state.paginaOrigem ? state.dadosUsuario : null,
        dadosCliente:
          action.payload.paginaOrigem === state.paginaOrigem ? state.dadosCliente : null,
        dadosImovel: action.payload.paginaOrigem === state.paginaOrigem ? state.dadosImovel : null,
        dadosEmpreendimento:
          action.payload.paginaOrigem === state.paginaOrigem ? state.dadosEmpreendimento : null,
      };
    }
    case Actions.ATUALIZAR_ACESSADO_POR: {
      return {
        ...state,
        acessadoPor: action.payload,
      };
    }
    case Actions.ATUALIZAR_DADOS_USUARIO: {
      return {
        ...state,
        dadosUsuario: action.payload,
      };
    }
    case Actions.ATUALIZAR_CLIENTE: {
      return {
        ...state,
        dadosCliente: action.payload,
      };
    }
    case Actions.ATUALIZAR_IMOVEL: {
      const { proprietario, ...imovel } = action.payload;
      return {
        ...state,
        dadosImovel: imovel,
        dadosCliente: proprietario,
      };
    }
    case Actions.ATUALIZAR_EMPREENDIMENTO: {
      return {
        ...state,
        dadosEmpreendimento: action.payload,
      };
    }
    case Actions.PATCH_ID: {
      return {
        ...state,
        _id: action.payload,
      };
    }
    default:
      return state;
  }
};

export const ResourceAcessAuditProvider = ({
  location,
  children,
}: {
  location: Location;
  children: any;
}) => {
  const [state, reducerDispatch] = useReducer(reducer, { ...initialState });
  const user = localStorage.getItem('user');

  const dispatch = ({ type, payload }: { type: ActionsTypes; payload: any }) => {
    reducerDispatch({ type, payload });
  };

  useEffect(() => {
    if (user) {
      const parsedUser = JSON.parse(user);
      dispatch({
        type: ActionsTypes.ACESSADO_POR,
        payload: parsedUser,
      });
    }
  }, [user]);

  useEffect(() => {
    dispatch({
      type: ActionsTypes.ATUALIZAR_LOCATION,
      payload: { paginaOrigem: location?.pathname, parametrosUrl: location?.search },
    });
  }, [location]);

  const submit = async () => {
    const newState = isImovelPage(state)
      ? {
          ...state,
          dadosCliente: shouldSubmitProprietarios(state) ? state.dadosCliente : null,
        }
      : state;

    await resourceAccessAuditRepository.create(newState).then((data: any) =>
      dispatch({
        type: ActionsTypes.PATCH_ID,
        payload: data.data._id,
      })
    );
  };

  useEffect(() => {
    if (state.paginaOrigem && state.acessadoPor && state.paginaOrigem !== '/') {
      submit();
    }
  }, [state.paginaOrigem, state.parametrosUrl]);

  useEffect(() => {
    if (
      state?._id &&
      (state?.dadosCliente?.id ||
        state?.dadosImovel?.id ||
        state?.dadosEmpreendimento?.id ||
        state?.dadosUsuario?.id)
    ) {
      const newState = isImovelPage(state)
        ? {
            ...state,
            dadosCliente: shouldSubmitProprietarios(state) ? state.dadosCliente : null,
          }
        : state;

      resourceAccessAuditRepository.update({ id: state?._id, ...newState });
    }
  }, [state.dadosCliente, state.dadosImovel, state.dadosEmpreendimento, state.dadosUsuario]);

  return (
    <ResourceAcessAuditContext.Provider value={[state, dispatch]}>
      {children}
    </ResourceAcessAuditContext.Provider>
  );
};

export const useResourceAcessAudit = () => useContext(ResourceAcessAuditContext);
