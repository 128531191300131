import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { isFeatureEnabled } from '../modules/features/features';
import Loading from '../Components/Loading';
import Route from './Route';
import { injectKeyWildCards } from '../modules/users/ability';

const SecureRoute = ({
  ability,
  name,
  method = 'find',
  feature,
  isAdminRoute = false,
  isSuperAdmin = false,
  user,
  ...props
}) => {
  const [featureEnabled, setFeatureEnabled] = useState(!feature);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const subject = user ? injectKeyWildCards(user, { __type: name }) : name;

  if (isAdminRoute && !isSuperAdmin) {
    history.push('/');
  }

  useEffect(() => {
    if (feature) {
      isFeatureEnabled(feature)
        .then((enabled) => {
          setFeatureEnabled(enabled);
        })
        .catch(() => {
          setFeatureEnabled(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [feature]);

  if (feature && loading) return <Loading />;

  if (
    (!ability || ability.can(method, subject)) &&
    (!feature || featureEnabled)
  ) {
    return (
      <Suspense fallback={<Loading />}>
        <Route {...props} />
      </Suspense>
    );
  }
  return <Redirect to="/" />;
};

export default SecureRoute;
