import React, { useContext, useEffect, useState } from 'react';
import { SnackBarContext } from '../../../lib/SnackBarContext';
import { useFeature } from '../../features/features';
import { Contrato } from '../contratos/contratosRepository';
import CadastroComissaoDialog from './CadastroComissaoDialog';
import comissoesRepository, {
  ComissaoFiancaDigital,
} from './comissoesRepository';
import { getValorComissao, getDataPrevisaoPagamento } from './utils';

interface ComissaoDialogProps {
  open: boolean;
  cadastroManual: boolean;
  showContratosField: boolean;
  contratoValues?: Contrato;
  submitContrato?: (values?: Contrato) => Promise<void>;
  setComissao?: React.Dispatch<
    React.SetStateAction<Partial<ComissaoFiancaDigital> | undefined>
  >;
  handleClose: () => void;
  onSubmit?: () => void;
}

const ComissaoDialog = ({
  contratoValues,
  cadastroManual,
  showContratosField,
  open,
  onSubmit,
  submitContrato,
  setComissao,
  handleClose,
}: ComissaoDialogProps) => {
  const [contrato, setContrato] = useState<Contrato | undefined>(
    contratoValues,
  );
  const [openCadastroComissaoDialog, setOpenCadastroComissaoDialog] =
    useState<boolean>(false);
  const featureTenant = useFeature(
    'Análise de crédito',
    contrato?.tenantId,
    handleClose,
  );
  const setSnackbar = useContext(SnackBarContext);

  const resetStates = () => {
    setContrato(contratoValues);
    handleClose();
  };

  const handleCloseComissaoDialog = () => {
    resetStates();
    setOpenCadastroComissaoDialog(false);
  };

  const handleSelectContrato = async (contrato: Contrato) => {
    setContrato(contrato);
  };

  const handleSubmitCadastroManual = async ({
    dataPrevisaoPagamento,
    contratoFiancaDigitalId,
  }: {
    dataPrevisaoPagamento: Date;
    contratoFiancaDigitalId: number;
  }) => {
    const comissaoData = {
      valorComissao: contrato?.comissao?.valorComissao,
      imovelId: contrato?.proposta?.imovel?.id,
      usuarioId: contrato?.analise?.usuarioCriador?.id,
      tenantId: contrato?.tenant.id,
      dataPrevisaoPagamento,
      contratoFiancaDigitalId: contratoFiancaDigitalId || contrato?.id,
    };
    const response = await comissoesRepository.create(comissaoData);
    if (response.ok) {
      setComissao && setComissao(comissaoData);
      setSnackbar({
        open: true,
        state: {
          message: 'Comissão cadastrada com sucesso.',
        },
      });
      handleCloseComissaoDialog();
      onSubmit && onSubmit();
    } else {
      setSnackbar({
        open: true,
        state: {
          message: 'Houve um erro ao cadastrar comissão.',
          severity: 'error',
        },
      });
    }
  };

  const handleSubmitCadastroAutomatico = async ({
    dataPrevisaoPagamento,
  }: {
    dataPrevisaoPagamento: Date;
  }) => {
    if (!submitContrato) return;

    const contratoData = {
      ...contrato,
      comissao: {
        valorComissao: contrato?.comissao?.valorComissao,
        dataPrevisaoPagamento,
      },
    } as Contrato;
    await submitContrato(contratoData);
    handleCloseComissaoDialog();
  };

  const atualizaValoresComissao = (valorComissao: number) =>
    setContrato(
      (prev) =>
        prev && {
          ...prev,
          comissao: {
            ...prev.comissao,
            valorComissao,
            dataPrevisaoPagamento: getDataPrevisaoPagamento(
              featureTenant?.configurations,
            ),
          },
        },
    );

  useEffect(() => {
    if (contratoValues && (!contrato || cadastroManual)) {
      setContrato(contratoValues);
    }
  }, [contratoValues, cadastroManual]);

  useEffect(() => {
    if (featureTenant && open && contrato) {
      const valorComissao = getValorComissao(
        contrato.planoFiancaDigital.percentualComissao,
        contrato.valorSetup,
      );
      atualizaValoresComissao(valorComissao);
      setOpenCadastroComissaoDialog(true);
    }
    if (open && !featureTenant && cadastroManual) {
      setOpenCadastroComissaoDialog(true);
    }
  }, [featureTenant, open]);

  return (
    <CadastroComissaoDialog
      onSubmit={
        cadastroManual
          ? handleSubmitCadastroManual
          : handleSubmitCadastroAutomatico
      }
      open={openCadastroComissaoDialog}
      handleClose={handleCloseComissaoDialog}
      showContratosField={showContratosField}
      contratoValues={contrato}
      handleSelectContrato={handleSelectContrato}
    />
  );
};

export default ComissaoDialog;
