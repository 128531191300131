import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '8px',
    background: '#fff',
    fontFamily: 'Gilroy, Roboto, Helvetica, Arial, sans-serif',

    '& th:last-child, & td:last-child': {
      textAlign: 'center',
    },

    '& td:last-child button:disabled svg': {
      color: '#DDD',
    },
  },
  headerProps: {
    padding: 0,
  },
  filter: {
    width: '100%',
    margin: 0,
    padding: '16px',
    background: '#fff',
    zIndex: 1,
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    // gridTemplateColumns: 'repeat(auto-fit, minmax(225px, 1fr))',
    gap: '16px',
    width: '100%',
    marginBottom: '24px',
  },
  cardsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Gilroy, Roboto, Helvetica, Arial, sans-serif',

    '& p': {
      fontSize: '15px',
      margin: '6px 0 0',
      color: '#00AE1A',
    },
  },
  cardPrice: {
    fontSize: '24px',
    marginTop: '8px',

    '& small': {
      fontSize: '15px',
      fontWeight: 'normal',
      marginRight: '6px',
    },
  },
  historico: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    marginTop: '8px',

    '& div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '& span': {
        fontSize: '12px',
      },

      '& strong': {
        fontSize: '18px',
      },
    },
  },
  fab: {
    display: 'flex',
    gap: '10px',
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  titleHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 'large',
    background: 'white',
  },
  lastRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  title: {
    letterSpacing: 0.225,
    color: theme.palette.primary.main,
    fontWeight: 'bolder',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
