import { useState } from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ repository }) => {
  const [loading, setLoading] = useState(false);

  const handleAddDocumentos = async (files) => {
    setLoading(true);
    const uploadDocuments = files
      .map((file) => ({
        nomeArquivo: file.name,
        file,
      }))
      .map(repository.create);

    const responses = await Promise.all(uploadDocuments);
    setLoading(false);
    return responses;
  };

  return {
    onAdd: handleAddDocumentos,
    loading,
  };
};
