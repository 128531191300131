import React from 'react';
import StatusIcon from '../../StatusIcon';
import { useStyles } from './style';

type ComponentProps =
  | {
      value: string;
      type: 'old';
      options: { [key: string]: string }; // STATUS_NAME: STATUS_COLOR HEX
    }
  | {
      value: string;
      type?: 'new';
      options: { [key: string]: 'success' | 'error' | 'warning' | 'default' };
    };

export function LabelStatus({ value, type, options }: ComponentProps) {
  const classes = useStyles();

  const statusColor = options[value];

  if (type === 'old') {
    return <StatusIcon color={statusColor} label={value} />;
  }

  return (
    <span className={classes.container} data-status={statusColor}>
      {value}
    </span>
  );
}
