import Section from '@elentari/components-eve/components/Section';
import TextField from '@elentari/components-eve/components/TextField';
import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { AuthContext } from 'elentari';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import ConfirmModal from '../../../../Components/ConfirmDialog';
import Field from '../../../../Components/Field';
import Loading from '../../../../Components/Loading';
import MoneyTextField from '../../../../Components/MoneyTextField';
import { LabelStatus } from '../../../../Components/ui/LabelStatus';
import { SnackBarContext } from '../../../../lib/SnackBarContext';
import { restApi } from '../../../../services/api';
import { dateFormatter, priceFormatter } from '../../../../utils/formater';
import { IComissoesData } from '../types';
import { MapperComissao, statusComissoesColor } from '../utils';
import { useStyles } from './style';

interface DialogProps {
  comissaoId: number;
  isOpen: boolean;
  onClose: () => void;
  handleClickCancelar: () => void;
  onSubmit: () => void;
}

interface ValoresParaAlteracao {
  dataPrevisaoPagamento: string | Date;
  valorComissao: number;
}

export function DetalhesDialog({
  comissaoId,
  isOpen,
  onClose,
  handleClickCancelar,
  onSubmit,
}: DialogProps) {
  const classes = useStyles();
  const snackbar = useContext(SnackBarContext);
  const context: any = useContext(AuthContext);
  const isSuperAdmin = context.user?.roles === 'SUPER_ADMIN';
  const [data, setData] = useState<IComissoesData>();
  const [dialogAlerta, setDialogAlerta] = useState<string | null>();
  const [updateValues, setUpdateValues] = useState<ValoresParaAlteracao>(
    {} as ValoresParaAlteracao,
  );

  useEffect(() => {
    if (!comissaoId) {
      setData(undefined);
      return;
    }

    (async () => {
      const response = await restApi.get<IComissoesData>(
        `/comissao-fianca-digital/${comissaoId}`,
      );

      if (response.ok && response.data) {
        const parsedComissoes = MapperComissao(response.data);
        setData({ ...parsedComissoes });
        return;
      }

      snackbar({
        open: true,
        state: {
          message: 'Não foi possível localizar esta comissão, tente novamente.',
          severity: 'error',
        },
      });

      onClose();
    })();
  }, [comissaoId, snackbar, onClose]);

  function handleDownload(url: string) {
    const ancora = document.createElement('a');
    ancora.setAttribute('href', url);
    ancora.setAttribute('target', '_blank');
    ancora.click();
    ancora.remove();
  }

  async function handleUpdate() {
    setDialogAlerta(null);

    const { dataPrevisaoPagamento, valorComissao } = updateValues;
    await restApi.patch(`/comissao-fianca-digital/${comissaoId}`, {
      dataPrevisaoPagamento,
      valorComissao,
    });

    snackbar({
      open: true,
      state: {
        message: 'Comissão atualizada com sucesso!',
      },
    });

    onClose();
    onSubmit();
  }

  function handleSubmit(formData: IComissoesData) {
    const { dataPrevisaoPagamento, valorComissao } = formData;

    if (!data) {
      return;
    }

    const mensagensAlerta = [];
    if (dataPrevisaoPagamento !== data.dataPrevisaoPagamento) {
      const dataAnterior = dateFormatter.format(
        new Date(data.dataPrevisaoPagamento),
      );
      const novaData = dateFormatter.format(new Date(dataPrevisaoPagamento));

      mensagensAlerta.push(
        `a data de previsão para pagamento de '${dataAnterior}' para '${novaData}'`,
      );
    }

    if (valorComissao !== data.valorComissao) {
      const valorAnterior = priceFormatter.format(data.valorComissao);
      const novoValor = priceFormatter.format(valorComissao);

      mensagensAlerta.push(
        `o valor da comissao de '${valorAnterior}' para '${novoValor}'`,
      );
    }

    if (mensagensAlerta.length) {
      setDialogAlerta(
        `Você esta alterando ${mensagensAlerta.join(' e ')}. Deseja continuar mesmo assim?`,
      );

      setUpdateValues({ dataPrevisaoPagamento, valorComissao });
    }
  }

  return (
    <>
      <ConfirmModal
        open={!!dialogAlerta}
        title="Atenção!!!"
        content={dialogAlerta}
        maxWidth="xs"
        onAccept={handleUpdate}
        onClose={() => setDialogAlerta(null)}
      />

      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography align="center" variant="h6">
            Detalhes da Comissão #{`0000${comissaoId}`.substring(-5)}
          </Typography>
        </DialogTitle>

        {!data && <Loading className={classes.loading} />}

        {!!data && (
          <Form
            onSubmit={handleSubmit}
            initialValues={{ ...data }}
            render={({ handleSubmit, dirty }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <Section label="Dados da comissão" disableGutters>
                    <Grid container spacing={2}>
                      <Grid container item sm={10} spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            label="Data de Geração"
                            fullWidth
                            size="small"
                            disabled
                            value={data.dataGeracao}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <DatePicker
                            fullWidth
                            size="small"
                            format="DD/MM/yyyy"
                            label="Previsão de Pagamento"
                            name="dataPrevisaoPagamento"
                            disabled={
                              !isSuperAdmin || data.status.status !== 'PENDENTE'
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            name="valorComissao"
                            label="Valor da Comissão"
                            fullWidth
                            size="small"
                            required
                            disabled={
                              !isSuperAdmin || data.status.status !== 'PENDENTE'
                            }
                            component={MoneyTextField}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Imóvel"
                            fullWidth
                            size="small"
                            disabled
                            value={data.codigoImovel}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            label="Imobiliária"
                            fullWidth
                            size="small"
                            disabled
                            value={data.imobiliaria}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        item
                        sm={2}
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography align="center" variant="body2">
                          Status
                        </Typography>
                        <div>
                          <LabelStatus
                            type="old"
                            options={statusComissoesColor}
                            value={data.status.status}
                          />
                        </div>
                      </Grid>

                      <Grid container item sm={12} spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            label="Corretor"
                            fullWidth
                            size="small"
                            disabled
                            value={data.usuario.name}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Cliente"
                            size="small"
                            fullWidth
                            disabled
                            value={data.cliente}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Section>

                  <div className={classes.spacing} />
                  {!!data.pagamento && (
                    <Section label="Dados do Pagamento" disableGutters>
                      <Grid container item sm={12} spacing={2}>
                        <Grid item xs={2}>
                          <TextField
                            label="ID Pagamento"
                            fullWidth
                            size="small"
                            disabled
                            value={data.pagamento.id}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Data de Pagamento"
                            fullWidth
                            size="small"
                            disabled
                            value={data.pagamento.dataPagamento}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Usuário do Pagamento"
                            fullWidth
                            size="small"
                            disabled
                            value={data.pagamento.usuarioPagamento}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Valor Pago"
                            fullWidth
                            size="small"
                            disabled
                            value={data.pagamento.valorTotalPago}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            label="Observação"
                            fullWidth
                            size="small"
                            disabled
                            value={data.pagamento.observacao}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={4}
                          justify="center"
                          alignItems="center"
                        >
                          <Button
                            onClick={() =>
                              handleDownload(
                                data.pagamento?.comprovante as string,
                              )
                            }
                            disabled={!data.pagamento?.comprovante}
                            className={classes.button}
                            size="small"
                            type="button"
                          >
                            <CloudDownloadIcon /> Comprovante
                          </Button>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            label="Comissões neste pagamento"
                            fullWidth
                            disabled
                            size="small"
                            value={data.pagamento.idsComissoes.join(' ')}
                          />
                        </Grid>
                      </Grid>
                    </Section>
                  )}

                  {data.status.status === 'CANCELADO' && (
                    <Section label="Dados do Cancelamento" disableGutters>
                      <Grid container item sm={12} spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            label="Data de Cancelamento"
                            fullWidth
                            disabled
                            size="small"
                            value={data.status.dataCancelamento}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            label="Responsável"
                            fullWidth
                            size="small"
                            disabled
                            value={data.status.usuario}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Motivo de Cancelamento"
                            fullWidth
                            size="small"
                            disabled
                            value={data.status.observacao}
                          />
                        </Grid>
                      </Grid>
                    </Section>
                  )}
                </DialogContent>

                <DialogActions className={classes.footer}>
                  <Grid item>
                    {data.status.status === 'PENDENTE' && isSuperAdmin && (
                      <Button
                        onClick={() => {
                          handleClickCancelar();
                          onClose();
                        }}
                        color="secondary"
                        type="button"
                        size="small"
                      >
                        Cancelar comissão
                      </Button>
                    )}
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={() => onClose()}
                      color={!dirty ? 'default' : 'secondary'}
                      type="button"
                    >
                      {!dirty ? 'Fechar' : 'Cancelar'}
                    </Button>

                    {dirty && (
                      <Button color="primary" type="submit">
                        Salvar
                      </Button>
                    )}
                  </Grid>
                </DialogActions>
              </form>
            )}
          />
        )}
      </Dialog>
    </>
  );
}
