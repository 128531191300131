import List from '@elentari/components-eve/components/List';
import { useListWithFilter } from '@elentari/core';
import { Grid, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { AuthContext, useFilter } from 'elentari';
import moment from 'moment';
import React, { MouseEvent, useContext, useState } from 'react';
import { Field, Form, useForm } from 'react-final-form';
import {
  FetcherResponseApi,
  OptionsFetcher,
} from '../../../@types/ListFetcher';
import EmptyComponent from '../../../Components/EmptyList';
import ValueWithTooltip from '../../../Components/ValueWithTooltip';
import { CheckboxInputFinalForm } from '../../../Components/ui/CheckboxInput';
import { LabelStatus } from '../../../Components/ui/LabelStatus';
import { paginate } from '../../../lib/feathers';
import { restApi } from '../../../services/api';
import { priceFormatter } from '../../../utils/formater';
import CadastroPagamentoFab from '../pagamentos/CadastroPagamentoFab';
import CadastroComissaoFab from './CadastroComissaoFab';
import CancelamentoComissaoDialog from './CancelamentoComissaoDialog';
import DadosPagamentoDialog from './DadosPagamentoDialog';
import { DetalhesDialog } from './DetalhesDialog';
import { OpcoesStatusComissaoFiancaDigital } from './comissoesRepository';
import { FormFilterComissoes } from './formFilterComissoes';
import { useStyles } from './styles';
import { IComissoesDataArray, IUsuarioComissoes } from './types';
import { statusComissoesColor } from './utils';

type FetcherResponse = FetcherResponseApi<IComissoesDataArray>;

interface ListaComissoesProps {
  onComissaoChange: () => void;
}

export function ListaComissoes({ onComissaoChange }: ListaComissoesProps) {
  const classes = useStyles();
  const filter = useFilter({});
  const context: any = useContext(AuthContext);
  const isSuperAdmin = context.user?.roles === 'SUPER_ADMIN';
  const [comissao, setComissao] = useState<IComissoesDataArray | null>();
  const [cancelamentoDialog, setCancelamentoDialog] = useState<{
    open: boolean;
    comissao?: IComissoesDataArray;
  }>({ open: false });

  const [dadosPagamentoDialog, setDadosPagamentoDialog] = useState<{
    open: boolean;
    usuario?: IUsuarioComissoes;
  }>({ open: false });

  const handleCloseCancelamentoDialog = () => {
    setCancelamentoDialog({ open: false });
  };

  const handleSubmitCancelamento = () => {
    setCancelamentoDialog({ open: false });
    onComissaoChange();
    onSearch();
  };

  const handleCloseDadosPagamentoDialog = () => {
    setDadosPagamentoDialog({ open: false });
  };

  const onSubmit = () => {
    onComissaoChange();
    onSearch();
  };

  async function fetcher(
    _endpoint: string,
    { term, page, order, orderBy }: OptionsFetcher,
  ) {
    const count = 15;

    const params = new URLSearchParams([
      paginate.limit(count),
      paginate.skip(page * count),
      paginate.sort(`${orderBy} ${order}`),
      ...Object.entries(term),
    ]);

    const response = await restApi.get<FetcherResponse>(
      `${_endpoint}?${params}`,
    );

    if (response.ok && response.data) {
      return { tag: 'success', ...response.data };
    }

    return { tag: 'error', error: 'Erro ao carregar a lista' };
  }

  async function formSubmit(data: any) {
    if (data.$term) {
      data.$term = data.$term
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim();
    }

    delete data.selectALL;
    delete data.comissoes;

    filter.onSubmit(data);
  }

  const { state, onSearch, ...rest } = useListWithFilter<IComissoesDataArray>(
    'comissao-fianca-digital',
    fetcher,
    { defaultSort: 'id', order: 'desc' },
  );

  function colunaPagamento(
    dataPagamento: string | Date | null,
    status: string,
  ) {
    if (!dataPagamento) {
      return (
        <LabelStatus type="old" options={statusComissoesColor} value={status} />
      );
    }

    return (
      <ValueWithTooltip
        value={moment(dataPagamento).format('DD/MM/YYYY')}
        additionalInfo={status}
        separator="-"
      />
    );
  }

  function AllCheckbox() {
    const { change, getState } = useForm();

    const handleSelection = () => {
      const isSelected = getState().values.selectALL;

      if (isSelected) {
        change('comissoes', []);
        return;
      }

      change('comissoes', state.list);
    };

    return (
      <label onClick={handleSelection}>
        <Field
          type="checkbox"
          name="selectALL"
          component={CheckboxInputFinalForm}
        />
      </label>
    );
  }

  function Buttons({ row }: { row: IComissoesDataArray }) {
    const isPendente = [OpcoesStatusComissaoFiancaDigital.PENDENTE].includes(
      row.status as OpcoesStatusComissaoFiancaDigital,
    );

    const handleClickPagar = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setDadosPagamentoDialog({ open: true, usuario: row?.usuario });
    };

    const handleClickCancelar = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setCancelamentoDialog({ open: true, comissao: row });
    };

    return (
      <Grid container direction="column">
        <IconButton onClick={handleClickPagar} size="small">
          <MonetizationOnIcon fontSize="small" htmlColor="#6e6e6e" />
        </IconButton>
        {isPendente && (
          <IconButton onClick={handleClickCancelar} size="small">
            <CancelIcon fontSize="small" htmlColor="#6e6e6e" />
          </IconButton>
        )}
      </Grid>
    );
  }

  const formatToList = (records: IComissoesDataArray[]): any => {
    if (!records) return;

    return records.map((row) => ({
      select: (
        <Field
          type="checkbox"
          name="comissoes"
          value={row}
          component={CheckboxInputFinalForm}
          disablePropagation
        />
      ),
      id: row.id,
      cliente: row.cliente,
      usuario: row.usuario.name,
      imobiliaria: row.imobiliaria,
      dataGeracao: moment(row.dataGeracao).format('DD/MM/YYYY'),
      valorComissao: priceFormatter.format(row.valorComissao),
      dataPrevisaoPagamento: moment(row.dataPrevisaoPagamento).format(
        'DD/MM/YYYY',
      ),
      status: row.status,
      dataPagamento: colunaPagamento(row.dataPagamento, row.status),
      codigoImovel: row.codigoImovel,
      buttons: <Buttons row={row} />,
    }));
  };

  const configAdmin = {
    select: {
      label: '',
      disableOrder: true,
      component: AllCheckbox,
    },
    cliente: {
      label: 'Cliente',
    },
    usuario: {
      label: 'Pago para',
    },
    imobiliaria: {
      label: 'Imóbiliaria',
    },
    dataGeracao: {
      label: 'Geração',
      disableOrder: true,
    },
    valorComissao: {
      label: 'Comissão',
      disableOrder: true,
    },
    dataPrevisaoPagamento: {
      label: 'Previsão de Pagamento',
    },
    dataPagamento: {
      label: 'Data de Pagamento',
    },
    codigoImovel: {
      label: 'Imóvel',
    },
    buttons: {
      label: '',
      disableOrder: true,
    },
  };

  const configUser = {
    id: {
      label: 'Id',
    },
    dataGeracao: {
      label: 'Geração',
      disableOrder: true,
    },
    valorComissao: {
      label: 'Comissão',
      disableOrder: true,
    },
    dataPrevisaoPagamento: {
      label: 'Previsão de Pagamento',
    },
    dataPagamento: {
      label: 'Data de Pagamento',
    },
    cliente: {
      label: 'Cliente',
    },
    codigoImovel: {
      label: 'Imóvel',
    },
  };

  return (
    <Grid container>
      <Form
        onSubmit={formSubmit}
        initialValues={{
          selectALL: false,
          comissoes: [],
          ...filter.initialValues,
        }}
        render={({ handleSubmit, form }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.container}>
              <FormFilterComissoes isSuperAdmin={isSuperAdmin} />
              <List
                title=""
                disableFilter
                EmptyComponent={EmptyComponent}
                state={{ ...state, list: formatToList(state.list) }}
                config={isSuperAdmin ? configAdmin : configUser}
                headerProps={{ spacing: 0, className: classes.headerProps }}
                onClick={(_, record) => setComissao(record.select.props.value)}
                height={
                  isSuperAdmin ? 'calc(100vh - 420px)' : 'calc(100vh - 260px)'
                }
                {...rest}
              />
              <div className={classes.fab}>
                <CadastroPagamentoFab
                  comissoes={form.getFieldState('comissoes')?.value}
                  onSubmit={onSubmit}
                />
                <CadastroComissaoFab />
              </div>
            </form>
          );
        }}
      />
      {isSuperAdmin && (
        <CadastroComissaoFab className={classes.fab} onSubmit={onSubmit} />
      )}
      <DetalhesDialog
        comissaoId={comissao?.id as number}
        isOpen={!!comissao}
        onClose={() => setComissao(null)}
        onSubmit={onSubmit}
        handleClickCancelar={() =>
          setCancelamentoDialog({
            open: true,
            comissao: comissao as IComissoesDataArray,
          })
        }
      />
      <CancelamentoComissaoDialog
        onClose={handleCloseCancelamentoDialog}
        onSubmit={handleSubmitCancelamento}
        open={cancelamentoDialog.open}
        comissao={cancelamentoDialog?.comissao}
      />
      <DadosPagamentoDialog
        onClose={handleCloseDadosPagamentoDialog}
        open={dadosPagamentoDialog.open}
        usuario={dadosPagamentoDialog?.usuario}
      />
    </Grid>
  );
}
