import { DependencyList, useEffect, useState } from 'react';
import { FeatureTenants } from './featureTenantsRepository';
import { featuraValidationRepository } from './featureValidationRepository';

export type availableFeatures =
  | 'ChatGPT'
  | 'Blog'
  | 'Análise de crédito'
  | 'Snackbar'
  | 'Endereço alternativo';

export interface ComissaoFiancaDigitalConfiguration {
  diaInicioPrimeiroPeriodo: number;
  diaFimPrimeiroPeriodo: number;
  diaPrevisaoPagamentoPrimeiroPeriodo: number;
  comissao: number;
  diaInicioSegundoPeriodo?: number;
  diaFimSegundoPeriodo?: number;
  diaPrevisaoPagamentoSegundoPeriodo?: number;
}

export const getFeature = async (feature: availableFeatures, tenantId?: number) => {
  if (!tenantId) return;

  const response = await featuraValidationRepository.create({ feature, tenantId });

  if (response?.ok && response?.data) {
    return { id: response.data.id, configurations: response.data?.configurations };
  }
};

export const isFeatureEnabled = async (feature: availableFeatures) => {
  const response = await featuraValidationRepository.create({ feature });

  if (response?.ok && response?.data) {
    const isAtivo = response.data.enabled;
    return isAtivo;
  }
};

export function useFeatureValidation(featureName: availableFeatures) {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    isFeatureEnabled(featureName).then(setEnabled);
  }, [featureName]);

  return enabled;
}

export function useFeature(
  featureName: availableFeatures,
  tenantId?: number,
  additionalRenderCondition?: any
) {
  const [feature, setFeature] = useState<Partial<FeatureTenants>>();

  useEffect(() => {
    getFeature(featureName, tenantId).then(setFeature);
  }, [featureName, tenantId, additionalRenderCondition]);

  return feature;
}
