import { Button, CircularProgress, FormHelperText, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { InputHTMLAttributes, useRef, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import useFileListManager from '../modules/fileListManager/useFileListManager';
import { arquivosUploaderRepository } from '../modules/novidades-sistema/arquivosUploaderRepository';

export interface ArquivosUploaderData {
  url: string;
  nomeArquivo: string;
}

interface FileUploadButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  onUpload?: ({ url, nomeArquivo }: ArquivosUploaderData) => void;
  label?: string;
}

const FileUploadField = ({
  onUpload,
  children,
  name = 'arquivo',
  label = 'Enviar',
}: FileUploadButtonProps) => {
  const form = useForm();
  const [nomeArquivo, setNomeArquivo] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const listManager = useFileListManager({
    repository: arquivosUploaderRepository,
  });

  const handleClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setNomeArquivo(undefined);
    const files = event.target.files;
    if (!files) return;

    const response = await listManager.onAdd(Object.values(files));
    if (response[0].ok) {
      form.change(name, response[0].data.url);
      setNomeArquivo(response[0].data.nomeArquivo);
      onUpload && onUpload(response[0].data);
    }
  };

  return (
    <Field name={name}>
      {({ meta }) => {
        const hasError = meta?.touched && meta?.error;
        return (
          <>
            <input
              ref={fileInputRef}
              style={{ display: 'none' }}
              type='file'
              onChange={handleFileChange}
            />
            <Button
              fullWidth
              variant='outlined'
              color={hasError ? 'secondary' : 'primary'}
              startIcon={
                listManager.loading ? <CircularProgress size='20px' /> : <CloudUploadIcon />
              }
              style={{
                borderRadius: '5px',
                textTransform: nomeArquivo ? 'none' : 'uppercase',
              }}
              onClick={handleClick}
            >
              <Typography
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {listManager.loading
                  ? ''
                  : nomeArquivo
                  ? nomeArquivo
                  : children || label || 'Enviar'}
              </Typography>
            </Button>
            {hasError ? <FormHelperText error>{meta.error}</FormHelperText> : null}
          </>
        );
      }}
    </Field>
  );
};

export default FileUploadField;
