export function hasMinimumLength(password: string): boolean {
  return password.length >= 8;
}

export function hasNumber(password: string): boolean {
  return /\d/.test(password);
}

export function hasUpperCaseLetter(password: string): boolean {
  return /[A-Z]/.test(password);
}

export function hasLowerCaseLetter(password: string): boolean {
  return /[a-z]/.test(password);
}

export function hasSpecialCharacter(password: string): boolean {
  return /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password);
}

export function isValidPassword(password: string): boolean {
  return (
    hasMinimumLength(password) &&
    hasNumber(password) &&
    hasUpperCaseLetter(password) &&
    hasLowerCaseLetter(password) &&
    hasSpecialCharacter(password)
  );
}
