import CalendarIcon from '@material-ui/icons/CalendarToday';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import React from 'react';
import { InfoBox } from '../../../Components/InfoBox';
import { priceFormatter } from '../../../utils/formater';
import { useStyles } from './styles';
import { ICardsComissoes } from './types';

interface CardComissoesProps {
  cardsData?: ICardsComissoes;
}

export function CardsComissoes({ cardsData }: CardComissoesProps) {
  const classes = useStyles();

  const valorComissoesPendentes = cardsData
    ? String(
        priceFormatter.format(cardsData.valorTotalComissoesPendentes),
      ).split(',')
    : ['00', '00'];

  return (
    <div className={classes.cardsContainer}>
      <InfoBox icon={MonetizationOnIcon}>
        <div className={classes.cardsContent}>
          <strong>Comissões a pagar</strong>
          <strong className={classes.cardPrice}>
            <small>R$</small>
            {valorComissoesPendentes[0].replace('R$ ', '')}
            <small>,{valorComissoesPendentes[1]}</small>
          </strong>
          {cardsData && (
            <p>Total de {cardsData.quantidadeComissoesPendentes} comissões</p>
          )}
        </div>
      </InfoBox>
      <InfoBox icon={CalendarIcon}>
        <div className={classes.cardsContent}>
          <strong>Histórico de Pagamentos</strong>
          {(!cardsData || !cardsData.historicoPagamentos.length) && (
            <p>Nenhum dado encontrado...</p>
          )}

          {cardsData && cardsData.historicoPagamentos.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {cardsData.historicoPagamentos.map((row) => (
                <div key={row.key} className={classes.historico}>
                  <div>
                    <strong>{row.quantidadeComissoesPagas}</strong>
                    <span>{row.key}</span>
                    <p>{row.valorTotalComissaoPaga}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </InfoBox>
    </div>
  );
}
