import React, { useState } from 'react';
import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import { validateDate } from '../utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import { OnChange } from 'react-final-form-listeners';

const useStyles = makeStyles(() => ({
  label: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 'calc(100% - 58px)',
    '&.Mui-focused': {
      width: '100%',
    },
  },
}));

const CreatedAt = (props) => {
  const classes = useStyles();
  const [hideOnOverflow, setHideOnOverflow] = useState(true);

  return (
    <>
      <DatePicker
        format='DD/MM/yyyy'
        fullWidth
        name='createdAt'
        label='Data de Cadastro'
        validate={validateDate}
        InputLabelProps={{
          ...(hideOnOverflow && {
            className: classes.label,
          }),
        }}
        {...props}
      />
      <OnChange name='createdAt'>
        {(createdAt) => {
          if (!createdAt) {
            setHideOnOverflow(true);
          } else {
            setHideOnOverflow(false);
          }
        }}
      </OnChange>
    </>
  );
};

export default CreatedAt;
