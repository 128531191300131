import Section from '@elentari/components-eve/components/Section';
import TextField from '@elentari/components-eve/final-form/TextField';
import { Grid } from '@material-ui/core';
import React from 'react';
import { formatCnpjCpf } from '../../../utils/utils';
import { IUsuarioComissoes } from '../comissoes/types';

const DadosDaConta = ({ usuario }: { usuario: IUsuarioComissoes }) => {
  return (
    <Section label='Dados da conta' disableGutters>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            label='Tipo'
            name='tipo'
            fullWidth
            variant='outlined'
            size='small'
            disabled
            value='PIX'
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            label='Chave'
            name='chave'
            fullWidth
            variant='outlined'
            size='small'
            disabled
            value={usuario?.codigoPix || 'Não informado'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='CPF/CNPJ'
            name='cpfCnpj'
            fullWidth
            variant='outlined'
            size='small'
            disabled
            value={usuario?.cpfCnpjPix ? formatCnpjCpf(usuario?.cpfCnpjPix) : 'Não informado'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Beneficiário'
            name='beneficiario'
            fullWidth
            variant='outlined'
            size='small'
            disabled
            value={usuario?.beneficiarioPix || 'Não informado'}
          />
        </Grid>
      </Grid>
    </Section>
  );
};

export default DadosDaConta;
