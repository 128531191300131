import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  titleHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 'large',
    background: 'white',
  },
  lastRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));
