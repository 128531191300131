import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { formatCnpjCpf } from '../../../utils/utils';
import { useStyles } from './styles';
import { IUsuarioComissoes } from './types';

interface DadosPagamentoDialogProps {
  usuario?: IUsuarioComissoes;
  open: boolean;
  onClose: () => void;
}

const DadosPagamentoDialog = ({ usuario, open, onClose }: DadosPagamentoDialogProps) => {
  const classes = useStyles();
  const possuiDadosPagamento = usuario?.beneficiarioPix && usuario?.codigoPix && usuario.cpfCnpjPix;

  return (
    <Dialog open={open} onClose={onClose} transitionDuration={{ enter: 100, exit: 100 }}>
      <DialogTitle>
        <Typography align='center' variant='h6'>
          {possuiDadosPagamento ? 'Dados para pagamento' : 'Falta de dados'}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        {possuiDadosPagamento ? (
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.titleHeader}>Tipo</TableCell>
                  <TableCell className={classes.titleHeader}>Chave</TableCell>
                  <TableCell className={classes.titleHeader}>CPF/CNPJ</TableCell>
                  <TableCell className={classes.titleHeader}>Beneficiário</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.lastRow}>
                  <TableCell>{usuario?.codigoPix && 'PIX'}</TableCell>
                  <TableCell>{usuario?.codigoPix}</TableCell>
                  <TableCell>{usuario?.cpfCnpjPix && formatCnpjCpf(usuario?.cpfCnpjPix)}</TableCell>
                  <TableCell>{usuario?.beneficiarioPix}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <DialogContentText>
            Este usuário ainda não tem dados para pagamento informado.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DadosPagamentoDialog;
