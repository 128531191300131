import React from 'react';
import TextField from '@elentari/components-eve/final-form/TextField';

const Email = (props) => {
  return (
    <TextField
      name='email'
      label='E-mail'
      fullWidth
      endAdornment='validation'
      inputProps={{
        'aria-autocomplete': 'none',
      }}
      InputProps={{
        autoComplete: 'new-email',
      }}
      {...props}
    />
  );
};

export default Email;
