import React from 'react';

const EmptyComponent = () => (
  <div
    style={{
      zIndex: 10,
      width: 150,
      height: 125,
      backgroundImage: `url(${require('../images/empty.svg')})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      marginLeft: -75,
      padding: 24,
      position: 'absolute',
      left: '50%',
    }}
  />
);

export default EmptyComponent;
