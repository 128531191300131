export const defaultAddressTemplate = {
  name: 'default',
  fields: [
    {
      name: 'zipCode',
      type: 'TEXT',
      group: 'Endereço',
      label: 'CEP',
      required: true,
    },
    {
      name: 'streetDescription',
      type: 'TEXT',
      group: 'Endereço',
      label: 'Logradouro',
      required: true,
    },
    {
      name: 'streetNumber',
      type: 'TEXT',
      group: 'Endereço',
      label: 'Número',
      required: true,
    },
    {
      name: 'neighborhood',
      type: 'TEXT',
      group: 'Endereço',
      label: 'Bairro',
      required: true,
    },
    {
      name: 'city',
      type: 'TEXT',
      group: 'Endereço',
      label: 'Cidade',
      required: true,
    },
    {
      name: 'state',
      type: 'TEXT',
      group: 'Endereço',
      label: 'Estado',
      required: true,
    },
    {
      name: 'country',
      type: 'TEXT',
      group: 'Endereço',
      label: 'País',
      required: true,
    },
    {
      name: 'reference',
      type: 'TEXT',
      group: 'Endereço',
      label: 'Ponto de referência',
    },
    {
      name: 'complement',
      type: 'TEXT',
      group: 'Endereço',
      label: 'Complemento',
      required: true,
    },
  ],
};
