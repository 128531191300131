import {
  CssTextField,
  SelectEndAdornment,
} from '@elentari/components-eve/components/TextField';
import CheckboxSimple from '@elentari/components-eve/final-form/CheckboxSimple';
import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import MaskedField from '@elentari/components-eve/final-form/MaskedField';
import Select from '@elentari/components-eve/final-form/Select';
import TextField from '@elentari/components-eve/final-form/TextField';
import { Grid, Slider, makeStyles } from '@material-ui/core';
import { ImagePicker } from 'elentari';
import React from 'react';
import CheckboxGroup from '../../Components/CheckboxGroup';
import Field from '../../Components/Field';
import UserAutocomplete from '../../Components/FormFields/UserAutocomplete';
import NumberFormatCustom from '../../Components/NumberFormatCustom';
import PorcentagemTextField from '../../Components/PorcentagemTextField';
import { StringArrayAsyncAutoComplete } from '../../Components/StringArrayAutoComplete';
import SwitchField from '../../Components/SwitchField';
import TimePicker from '../../Components/TimePicker';
import Tooltip from '../../Components/Tooltip';
import { CNPJMask, CPFMask, validateDate } from '../../utils/utils';
import { LabelRoleList } from '../users/useRoles';
import MoneyTextField from '../../Components/MoneyTextField';

const formatLabel = (field) => {
  return [true, 'true'].includes(field.required)
    ? field.label + ' *'
    : field.label;
};

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.primary.main,
  },
  sliderField: {
    display: 'flex',
  },
  slider: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
}));

const makeField = (field, ignoreHelperText = true) => {
  const label = formatLabel(field);
  const isMobile = window.innerWidth <= 600;
  switch (field.type) {
    case 'TEXT':
      switch (field.name) {
        case 'configuration.emailResponsavelProposta':
          return (
            <TextField
              endAdornment="validation"
              fullWidth
              name={field.name}
              label={label}
              InputLabelProps={isMobile && { style: { marginTop: '-6px' } }}
            />
          );
        default:
          return (
            <TextField
              endAdornment="validation"
              fullWidth
              name={field.name}
              label={label}
              disabled={field?.disabled}
            />
          );
      }
    case 'CPF/CNPJ':
      return (
        <MaskedField
          endAdornment="validation"
          name={field.name}
          label={field.label}
          fullWidth
          mask={(value) =>
            value?.replace(/\D/g, '').length <= 11 ? CPFMask : CNPJMask
          }
        />
      );
    case 'NUMBER':
      switch (field.name) {
        case 'configuration.opacidadeMarcaDagua':
          return <SliderField field={field} label={label} />;
        case 'detalhes.dormitorios':
          return (
            <Field name={field.name} disabled={field?.disabled}>
              {({ input, meta, ...props }) => {
                return (
                  <CssTextField
                    {...props}
                    {...input}
                    error={meta.touched && !!meta.error}
                    fullWidth
                    label={label}
                    variant="outlined"
                    type="number"
                    onChange={(e) => {
                      const value = e.target.value;
                      input.onChange(value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <SelectEndAdornment
                          endAdornment="validation"
                          value={input.value}
                          error={meta.touched && !!meta.error}
                        />
                      ),
                    }}
                    helperText={
                      !ignoreHelperText
                        ? 'Soma de todos os dormitórios do imóvel incluindo as suítes'
                        : null
                    }
                  />
                );
              }}
            </Field>
          );
        case 'detalhes.suites':
          return (
            <Field name={field.name} disabled={field?.disabled}>
              {({ input, meta, ...props }) => {
                return (
                  <CssTextField
                    {...props}
                    {...input}
                    error={meta.touched && !!meta.error}
                    fullWidth
                    label={label}
                    variant="outlined"
                    type="number"
                    onChange={(e) => {
                      const value = e.target.value;
                      input.onChange(value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <SelectEndAdornment
                          endAdornment="validation"
                          value={input.value}
                          error={meta.touched && !!meta.error}
                        />
                      ),
                    }}
                    helperText={
                      !ignoreHelperText
                        ? 'Do total de dormitórios, informe a quantidade de suítes'
                        : null
                    }
                  />
                );
              }}
            </Field>
          );
        default:
          return (
            <Field name={field.name} disabled={field?.disabled}>
              {({ input, meta, ...props }) => {
                return (
                  <CssTextField
                    {...props}
                    {...input}
                    error={meta.touched && !!meta.error}
                    fullWidth
                    label={label}
                    variant="outlined"
                    onChange={(e) => {
                      const value = e.target.value;
                      input.onChange(value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <SelectEndAdornment
                          endAdornment="validation"
                          value={input.value}
                          error={meta.touched && !!meta.error}
                        />
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                    helperText={meta.touched && meta.error}
                  />
                );
              }}
            </Field>
          );
      }
    case 'INTEGER':
      switch (field.name) {
        case 'configuration.tempoMinimoEntreAtendimentosCorretorRoleta':
        case 'configuration.expiracaoPropostaLocacaoDocumentacaoDias':
          return (
            <TextField
              endAdornment="validation"
              fullWidth
              type="number"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
              name={field.name}
              label={label}
              InputLabelProps={
                isMobile && { style: { marginTop: '-6px', fontSize: '14px' } }
              }
            />
          );
        default:
          return (
            <IntegerField
              name={field.name}
              disabled={field.disabled}
              tooltip={field.tooltip}
              label={label}
            />
          );
      }
    case 'PORCENTAGEM':
      return (
        <Field
          name={field.name}
          label={field.label}
          fullWidth
          component={PorcentagemTextField}
        />
      );
    case 'SELECT':
      return (
        <Select
          fullWidth
          name={field.name}
          label={label}
          items={field.options || []}
          defaultValue={field.defaultValue}
        />
      );

    case 'DATE':
      return (
        <DatePicker
          format="DD/MM/yyyy"
          fullWidth
          label={label}
          name={field.name}
          validate={validateDate}
        />
      );
    case 'TIME':
      return (
        <Field
          label={label}
          name={field.name}
          fullWidth
          component={TimePicker}
        />
      );
    case 'CHECKBOX':
      return (
        <CheckboxSimple
          fullWidth
          label={label}
          name={field.name}
          defaultValue={false}
        />
      );
    case 'CHECKBOX_GROUP':
      return <CheckboxGroup fullWidth label={label} options={field.options} />;

    case 'FILE':
      return <ImageField field={field} />;

    case 'USER_AUTOCOMPLETE':
      return (
        <Tooltip title={field.tooltip ?? ''} placement="top">
          <span>
            <UserAutocomplete name={field.name} label={label} size="medium" />
          </span>
        </Tooltip>
      );

    case 'ROLES_SELECT':
      return (
        <Field
          label={label}
          name={field.name}
          component={StringArrayAsyncAutoComplete}
          loadOptions={async () => LabelRoleList}
          getOptionLabel={(item) => item.label}
          getOptionSelected={(option, value) => option.label === value.label}
          parserOption={(item) => item.value}
          converter={(value) =>
            typeof value !== 'object'
              ? LabelRoleList.find((r) => r.value === value)
              : value
          }
          size="medium"
          initialValue={field?.initialValue || []}
        />
      );
    case 'DIRETORES_AUTOCOMPLETE':
      return (
        <Tooltip title={field.tooltip ?? ''} placement="top">
          <span>
            <UserAutocomplete
              diretoresOnly
              name={field.name}
              label={label}
              size="medium"
            />
          </span>
        </Tooltip>
      );
    case 'HORA_MINUTO':
      return (
        <Field
          name={field.name}
          label={label}
          inputVariant="outlined"
          component={TimePicker}
          size="medium"
          fullWidth
        />
      );
    case 'SWITCH':
      return (
        <Field
          name={field.name}
          label={label}
          component={SwitchField}
          type="checkbox"
        />
      );
    case 'MONETARY':
      return (
        <Field
          name={field.name}
          disabled={field.disabled}
          label={label}
          component={MoneyTextField}
          fullWidth
        />
      );

    default:
      return <></>;
  }
};

export const IntegerField = ({
  name,
  disabled = null,
  tooltip = '',
  label,
}) => {
  return (
    <Field name={name} disabled={disabled}>
      {({ input, meta, ...props }) => {
        return (
          <Tooltip title={tooltip ?? ''}>
            <CssTextField
              {...props}
              {...input}
              error={meta.touched && !!meta.error}
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
              onChange={(e) => {
                const value = +e.target.value;
                input.onChange(value);
                if (value < 0) {
                  input.onChange(0);
                }
              }}
              variant="outlined"
              label={label}
              InputProps={{
                endAdornment: (
                  <SelectEndAdornment
                    endAdornment="validation"
                    value={input.value}
                    error={meta.touched && !!meta.error}
                  />
                ),
                inputComponent: NumberFormatCustom,
              }}
              helperText={meta.touched && meta.error}
            />
          </Tooltip>
        );
      }}
    </Field>
  );
};

const SliderField = ({ field, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.sliderField}>
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={6} xs={12}>
          <Field name={field.name}>
            {({ input, meta, ...props }) => {
              return (
                <div className={classes.slider}>
                  <Slider
                    {...props}
                    {...input}
                    fullWidth
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={(e, v) => {
                      input.onChange(v);
                    }}
                  />
                </div>
              );
            }}
          </Field>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Field name={field.name}>
            {({ input, meta, ...props }) => {
              return (
                <CssTextField
                  {...props}
                  {...input}
                  error={meta.touched && !!meta.error}
                  fullWidth
                  label={label}
                  variant="outlined"
                  type="number"
                  onChange={(e) => {
                    input.onChange(e.target.value);
                    if (e.target.value < 0) {
                      input.onChange(0);
                    } else if (e.target.value > 1) {
                      input.onChange(1);
                    }
                  }}
                  inputProps={{
                    step: 0.1,
                    min: 0,
                    max: 1,
                  }}
                  InputProps={{
                    endAdornment: (
                      <SelectEndAdornment
                        endAdornment="validation"
                        value={input.value}
                        error={meta.touched && !!meta.error}
                      />
                    ),
                  }}
                  helperText={meta.touched && meta.error}
                />
              );
            }}
          </Field>
        </Grid>
      </Grid>
    </div>
  );
};

const ImageField = ({ field }) => {
  const classesImagePicker = useStyles();

  const opacity = makeStyles({
    normal: {
      opacity: field.opacity,
    },
  });

  const opacityClass = opacity();

  return (
    <Field
      size={250}
      name={field.name}
      defaultValue={require('../../images/empty-image.png')}
      component={ImagePicker}
      classes={{
        normal: opacityClass.normal,
        iconButton: classesImagePicker.iconButton,
      }}
    />
  );
};

export default makeField;
