import Section from '@elentari/components-eve/components/Section';
import TextField from '@elentari/components-eve/final-form/TextField';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { FieldRenderProps, useForm } from 'react-final-form';
import AsyncPropBasedAutoComplete from '../../../Components/AsyncPropBasedAutocomplete';
import Field from '../../../Components/Field';
import RegisterFields from '../../../Components/FormFields/RegisterField';
import { IBairro, IBairroWithTenant } from '../../../models/adresses';
import { EnderecoFetcher } from '../../../utils/address-utils';
import { useAddress } from '../../addresses/useAddress';

const BairroDestinoFields = () => {
  const enderecoFetcher = new EnderecoFetcher();
  const form = useForm();
  const { initialOptions } = useAddress(enderecoFetcher, 'bairroDestino');
  const [tenant, setTenant] = useState('');

  return (
    <Section label="Bairro destino">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size="small"
            name="bairroDestino.pais"
            label="País"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size="small"
            name="bairroDestino.estado"
            label="Estado"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            name="bairroDestino.cidade"
            label="Cidade"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="idBairroOrigem"
            render={({
              input: { value: idBairroOrigem },
            }: FieldRenderProps<number>) => (
              <>
                <Field
                  fullWidth
                  label="Bairro destino"
                  name="idBairroDestino"
                  component={AsyncPropBasedAutoComplete}
                  loadOptions={(input: string) => {
                    const cidadeId = form.getFieldState(
                      'bairroOrigem.cidadeId',
                    )?.value;
                    return enderecoFetcher.getBairroListByCidadeId(
                      cidadeId,
                      input,
                    );
                  }}
                  onChange={(bairro: IBairroWithTenant) => {
                    if (bairro && bairro.tenant) {
                      setTenant(bairro.tenant.name);
                    } else {
                      setTenant('');
                    }
                    form.change('tenantIdDestino', bairro?.tenantId);
                  }}
                  getOptionDisabled={(bairro: IBairro) =>
                    bairro.id === idBairroOrigem
                  }
                  propLabel="nomeBairro"
                  initialOptions={initialOptions?.bairro}
                  disabled={!idBairroOrigem}
                />
              </>
            )}
          />
          <RegisterFields names={['tenantIdDestino']} />
        </Grid>
        {tenant !== '' && (
          <Grid item xs={12}>
            <TextField
              size="small"
              value={tenant}
              fullWidth
              name={'imobiliaria'}
              label={'Imobiliária'}
              disabled
            />
          </Grid>
        )}
      </Grid>
    </Section>
  );
};

export default BairroDestinoFields;
